import { useState } from "react";

const MeetingsAmountList = ({ leaderboardAmount = [] }) => {
  const [visible, setVisible] = useState(null);
  const toggleItem = (itemIndex) => {
    if (visible === itemIndex) {
      setVisible(null);
      return;
    }
    setVisible(itemIndex);
  };
  return (
    <ul className="meeetings-amount-list">
      <li className="meetings-amount-list-header">
        <span>Meetings</span>
        <span>Amount</span>
        <span>Held by</span>
      </li>
      {!leaderboardAmount?.length ? (
        <li className="meeting-amount-list-empty">No data</li>
      ) : (
        leaderboardAmount?.map((leaderboard, index) => (
          <li key={`meeting-amount-list-${leaderboard.id}`}>
            <div
              className="meeting-amount-list-visible"
              onClick={() => toggleItem(index)}
            >
              <div className="meetings-amount-list-title meetings-amount-list-row-item">
                {leaderboard?.title}
              </div>
              <p className="meetings-amount-list-row-item">
                {leaderboard?.amount}$
              </p>
              <p className="meetings-amount-list-row-item">
                {leaderboard?.organizer}
              </p>
              <div
                className={`meetings-amount-list-expand meetings-amount-list-row-item ${
                  index === visible ? "open" : ""
                }`}
              ></div>
            </div>
            <div
              className={`meetings-amount-list-toggle ${
                index === visible ? "open" : ""
              }`}
            >
              <div className="meetings-amount-list-toggle-item">
                <p>Type</p>
                <span>{leaderboard?.type}</span>
              </div>
              <div className="meetings-amount-list-toggle-item">
                <p>Amount</p>
                <span>{leaderboard?.amount}$</span>
              </div>
              <div className="meetings-amount-list-toggle-item">
                <p>Participant number</p>
                <span>{leaderboard?.participiantNumber}</span>
              </div>
              <div className="meetings-amount-list-toggle-item">
                <p>Time</p>
                <span>{leaderboard?.duration} min</span>
              </div>
              {leaderboard?.tags?.length ? (
                <div className="meetings-amount-list-toggle-item">
                  <p>Talking points</p>
                  <span>{leaderboard?.tags?.map((tag) => tag?.name)}</span>
                </div>
              ) : (
                <div className="meetings-amount-list-toggle-item"></div>
              )}
            </div>
          </li>
        ))
      )}
    </ul>
  );
};

export default MeetingsAmountList;
