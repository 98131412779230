import { configureStore } from "@reduxjs/toolkit";
import { positionsReducer } from "./positions/positionsSlice";
import { tasksReducer } from "./tasks/tasksSlice";
import { usersReducer } from "./users/usersSlice";
import { employeesReducer } from "./employees/employeesSlice";
import { matricesReducer } from "./matrices/matricesSlice";
import { feedbackReducer } from "./feedback/feedbackSlice";
import { goalsReducer } from "./goals/goalsSlice";
import { highFivesReducer } from "./highFives/highFivesSlice";
import { pageViewsReducer } from "./pageViews/pageViewsSlice";
import { projectsReducer } from "./projects/projectsSlice";
import { teamsReducer } from "./teams/teamsSlice";
import { achievementsReducer } from "./achievements/achievementsSlice";
import { recognitionsReducer } from "./recognitions/recognitionsSlice";
import { supportCasesReducer } from "./supportCases/supportCasesSlice";
import { rolesReducer } from "./roles/rolesSlice";
import { permissionsReducer } from "./permissions/permissionsSlice";
import { meetingsReducer } from "./meetings/meetingsSlice";
import { toastMessagesReducer } from "./toastMessages/toastMessagesSlice";
import { checkinsReducer } from "./checkins/checkinsSlice";
import { surveysReducer } from "./surveys/surveysSlice";
import { careerReducer } from "./career/careerSlice";
import { formReducer } from "./form/formSlice";
import { modalReducer } from "./modal/modalSlice";
import { dailypulseReducer } from "./dailypulse/dailypulseSlice";
import { reportsReducer } from "./reports/reportsSlice";
import { tagsReducer } from "./tags/tagsSlice";
import { performanceReducer } from "./performance/performanceSlice";
import { responsibilitiesReducer } from "./responsibilities/responsibilitiesSlice";
import { skillsReducer } from "./skills/skillsSlice";
import { behavioursReducer } from "./behaviours/behavioursSlice";
import { interestsReducer } from "./interests/interestsSlice";
import { meetingTypesReducer } from "./meetingTypes/meetingTypesSlice";
import { workshopSeminarReducer } from "./workshopSeminar/workshopSeminarSlice";
import { conferenceAttendanceReducer } from "./conferenceAttendance/conferenceAttendanceSlice";
import { publicSpeakingReducer } from "./publicSpeaking/publicSpeakingSlice";
import { panelDiscussionReducer } from "./panelDiscussion/panelDiscussionSlice";
import { professionalOrganizationReducer } from "./professionalOrganization/professionalOrganizationSlice";
import { committeeInvolvementReducer } from "./committeeInvolvement/committeeInvolvementSlice";
import { advancedDegreeReducer } from "./advancedDegree/advancedDegreeSlice";
import { certificationProgramReducer } from "./certificationProgram/certificationProgramSlice";

export const store = configureStore({
  reducer: {
    positions: positionsReducer,
    tasks: tasksReducer,
    users: usersReducer,
    employees: employeesReducer,
    matrices: matricesReducer,
    feedback: feedbackReducer,
    goals: goalsReducer,
    highFives: highFivesReducer,
    pageViews: pageViewsReducer,
    projects: projectsReducer,
    teams: teamsReducer,
    achievements: achievementsReducer,
    recognitions: recognitionsReducer,
    supportCases: supportCasesReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    meetings: meetingsReducer,
    toastMessages: toastMessagesReducer,
    checkins: checkinsReducer,
    surveys: surveysReducer,
    career: careerReducer,
    form: formReducer,
    modal: modalReducer,
    dailyPulse: dailypulseReducer,
    reports: reportsReducer,
    tags: tagsReducer,
    performance: performanceReducer,
    responsibilities: responsibilitiesReducer,
    skills: skillsReducer,
    behaviours: behavioursReducer,
    interests: interestsReducer,
    meetingTypes: meetingTypesReducer,
    workshopSeminar: workshopSeminarReducer,
    conferenceAttendance: conferenceAttendanceReducer,
    publicSpeaking: publicSpeakingReducer,
    panelDiscussion: panelDiscussionReducer,
    professionalOrganization: professionalOrganizationReducer,
    committeeInvolvement: committeeInvolvementReducer,
    advancedDegree: advancedDegreeReducer,
    certificationProgram: certificationProgramReducer,
  },
});

export * from "./positions/positionsThunks";
export * from "./tasks/tasksThunks";
export * from "./users/usersThunks";
export * from "./employees/employeesThunks";
export * from "./matrices/matricesThunks";
export * from "./feedback/feedbackThunks";
export * from "./goals/goalsThunks";
export * from "./highFives/highFivesThunks";
export * from "./projects/projectsThunks";
export * from "./teams/teamsThunks";
export * from "./achievements/achievementsThunks";
export * from "./recognitions/recognitionsThunks";
export * from "./supportCases/supportCasesThunks";
export * from "./roles/rolesThunks";
export * from "./permissions/permissionsThunks";
export * from "./meetings/meetingsThunks";
export * from "./checkins/checkinsThunk";
export * from "./surveys/surveysThunks";
export * from "./career/careerThunks";
export * from "./dailypulse/dailypulseThunks";
export * from "./reports/reportsThunks";
export * from "./tags/tagsThunks";
export * from "./performance/performanceThunks";
export * from "./responsibilities/responsibilitiesThunks";
export * from "./skills/skillsThunks";
export * from "./behaviours/behavioursThunks";
export * from "./interests/interestsThunks";
export * from "./meetingTypes/meetingTypesThunks";
export * from "./workshopSeminar/workshopSeminarThunks";
export * from "./conferenceAttendance/conferenceAttendanceThunks";
export * from "./publicSpeaking/publicSpeakingThunks";
export * from "./panelDiscussion/panelDiscussionThunks";
export * from "./professionalOrganization/professionalOrganizationThunks";
export * from "./committeeInvolvement/committeeInvolvementThunks";
export * from "./advancedDegree/advancedDegreeThunks";
export * from "./certificationProgram/certificationProgramThunks";
