import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMeetingTypes,
  createMeetingType,
  updateMeetingType,
  deleteMeetingType,
} from "./meetingTypesThunks";

const meetingTypesSlice = createSlice({
  name: "meetingTypes",
  initialState: {
    meetingTypes: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetMeetingTypesMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchMeetingTypes.fulfilled, (state, action) => {
      state.meetingTypes = action.payload;
    });
    builder.addCase(createMeetingType.fulfilled, (state, action) => {
      state.meetingTypes = [...state.meetingTypes, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Meeting type successfully created",
      };
    });
    builder.addCase(updateMeetingType.fulfilled, (state, action) => {
      const updatedState = state.meetingTypes.map((type) => {
        return type.id === action.payload.id ? action.payload : type;
      });
      state.meetingTypes = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Meeting successfully updated",
      };
    });
    builder.addCase(deleteMeetingType.fulfilled, (state, action) => {
      const filteredMeetingTypes = state.meetingTypes.filter(
        (type) => type.id !== action.meta.arg
      );
      state.meetingTypes = filteredMeetingTypes;
      state.message = {
        type: "success",
        title: "Success",
        message: "Meeting successfully deleted",
      };
    });
  },
});

export const { resetMeetingTypesMessage } = meetingTypesSlice.actions;

export const meetingTypesReducer = meetingTypesSlice.reducer;
