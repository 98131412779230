import React, { useState, useEffect } from "react";
import { Button, Input } from "../../../../ui";
import { AddIcon, DeleteIcon, DotsIcon, EditIcon } from "../../../../../icons";

const SuggestionColumn = ({
  title = "",
  dataItems = [],
  color = "",
  addInputValue = "",
  haveAddButton = true,
  showAddInput = false,
  disableEdit = false,
  onAddInputChange = () => {},
  onAddInputCancel = () => {},
  onInputSave = () => {},
  onItemEdit = () => {},
  onItemDelete = () => {},
}) => {
  const [isSettingsShown, setIsSettingsShown] = useState(false);
  const [isAddInputShown, setIsAddInputShown] = useState(false);
  const [editedItem, setEditedItem] = useState(null);
  const [inputValue, setInputValue] = useState(addInputValue);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (dataItems?.length <= 0) {
      setIsSettingsShown(true);
    } else {
      setIsSettingsShown(false);
      setSuggestions(dataItems);
    }
  }, [dataItems]);

  useEffect(() => {
    setInputValue(addInputValue);
  }, [addInputValue]);

  const handleSettingsClick = () => {
    if (dataItems.length > 0 && !isAddInputShown) {
      setIsSettingsShown(!isSettingsShown);
    }
  };

  const handleAddItem = () => {
    setIsSettingsShown(false);
    setIsAddInputShown(true);
  };

  const handleItemCancel = () => {
    onAddInputCancel();
    setIsAddInputShown(false);
    if (dataItems?.length <= 0) {
      setIsSettingsShown(true);
    }
  };

  const handleSaveClick = () => {
    onInputSave();
    setIsAddInputShown(false);
  };

  const handleItemEditClick = (index, value) => {
    setEditedItem(index);
    setInputValue(value);
  };

  const handleCancelEdit = () => {
    setEditedItem(null);
    setInputValue("");
  };

  const handleSaveEdit = (index) => {
    onItemEdit(index);
    setInputValue("");
    setEditedItem(null);
  };

  return (
    <div className="column">
      <div className="suggestion-title-line">
        <div className="flex items-center gap-8">
          <span className={`dot ${color}`} />
          <h4 className="suggestion-title">{title}</h4>
        </div>
        {haveAddButton && !disableEdit && (
          <Button variant="control" handleClick={handleSettingsClick}>
            <DotsIcon />
          </Button>
        )}
      </div>
      <ul>
        {isSettingsShown && haveAddButton && (
          <li style={{ padding: "0" }}>
            <Button
              variant="control"
              className="add-btn"
              handleClick={handleAddItem}
            >
              <AddIcon />
              {`${title}?`}
            </Button>
          </li>
        )}
        {(isAddInputShown || showAddInput) && !disableEdit ? (
          <li style={{ padding: "0", display: "block" }}>
            <Input
              type="textarea"
              value={inputValue}
              placeholder={`Type ${title.toLowerCase()}`}
              autoFocus={true}
              handleChange={onAddInputChange}
            />
            <div className="link-actions flex">
              <Button variant="link" handleClick={handleSaveClick}>
                Save
              </Button>
              {haveAddButton && (
                <Button variant="link" handleClick={handleItemCancel}>
                  Cancel
                </Button>
              )}
            </div>
          </li>
        ) : (
          ""
        )}
        {dataItems.length > 0 &&
          suggestions?.map((item, index) => {
            return editedItem !== index ? (
              <li key={index} className={color}>
                {item.answer}
                <div className="actions">
                  <Button
                    variant="control"
                    className={`action-btn ${!!item.id ? "hidden" : ""}`}
                    handleClick={() => handleItemEditClick(index, item?.answer)}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    variant="control"
                    className={`action-btn ${!!item.id ? "hidden" : ""} ${
                      haveAddButton ? "" : "hidden"
                    }`}
                    handleClick={() => onItemDelete(index)}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              </li>
            ) : (
              <li key={index} style={{ padding: "0", display: "block" }}>
                <Input
                  type="textarea"
                  value={inputValue}
                  placeholder={`Type ${title.toLowerCase()}`}
                  autoFocus={true}
                  handleChange={onAddInputChange}
                />
                <div className="link-actions flex">
                  <Button
                    variant="link"
                    handleClick={() => handleSaveEdit(index)}
                  >
                    Save
                  </Button>
                  <Button variant="link" handleClick={handleCancelEdit}>
                    Cancel
                  </Button>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SuggestionColumn;
