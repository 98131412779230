import { Handle, Position } from "reactflow";
import { MultiUsersIcon } from "../../icons";
import "./styles.scss";
import { UserIcon } from "../ui";
import { shortenText } from "../../utils/textFormat";

function CustomNode({ data, isConnectable, yPos }) {
  return (
    <div className="text-updater-node">
      <Handle
        type="target"
        position={Position.Top}
        id="a"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
      <div className="custom-block">
        <div className="custom-block-top">
          <h3 title={data?.name}>{shortenText(data?.name, 15)}</h3>
          <UserIcon
            userImage={data?.image || data.photoUrl}
            userName={data?.name}
            iconStyle={{
              width: "50px",
              height: "50px",
              fontSize: "30px",
              display: "inline-flex",
            }}
          />
        </div>
        <p className="custom-block-position">
          {data?.position ?? "Position not available"}
        </p>
        <div className="custom-block-bottom">
          <MultiUsersIcon />
          <p
            className="custom-block-expand"
            onClick={() => data?.handleToggleExpand(data?.id, yPos)}
          >
            {data?.count}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CustomNode;
