import { useEffect, useState } from "react";
import LineChartCheckins from "../../helperComponents/LineChartCheckins";
import DoughnutChartCheckins from "../../helperComponents/DoughnutChartCheckins";
import {
  fetchCompanyMoodStatsLine,
  fetchCompanyMoodStatsDoughnut,
} from "../../../../../../store";
import { useThunk } from "../../../../../../hooks/useThunk";
import { useSelector } from "react-redux";
import moment from "moment";
import { shortMonths } from "../../../../../../data/filters";
import { getWeekNumbersInMonth } from "../../../../../../utils/textFormat";

const YourCheckIn = ({ dateFilterOptions }) => {
  const [doFetchCompanyMoodStatsLine] = useThunk(fetchCompanyMoodStatsLine);
  const [doFetchCompanyMoodStatsDoughnut] = useThunk(
    fetchCompanyMoodStatsDoughnut
  );

  const [companyStatsLine, setCompanyStatsLine] = useState([]);
  const [lineChartFilter, setLineChartFilter] = useState(1);
  const [doughnutFilter, setDoughnutFilter] = useState();
  const [doughnutChartFilter, setDoughnutChartFilter] = useState();
  const [doughnutChartData, setDoughnutChartData] = useState([]);
  const [doughnutAvgData, setDoughnutAvgData] = useState([]);

  const daysCollection = [1, 2, 3, 4, 5, 6, 7];

  const companyStatsLineState = useSelector(
    (state) => state?.checkins?.moodStatistics
  )?.companyStatsLine;

  const companyStatsDoughnutState = useSelector(
    (state) => state?.checkins?.moodStatistics
  )?.companyStatsDoughnut;

  const convertArrayToString = (collection) => {
    return JSON.stringify(collection);
  };

  const lineChartPayload = [
    {
      value: 1,
      data: {
        range: "year",
        years: convertArrayToString([new Date().getFullYear()]),
      },
    },
    {
      value: 2,
      data: {
        range: "month",
        months: convertArrayToString([new Date().getMonth()]),
      },
    },
    {
      value: 3,
      data: {
        range: "week",
        weeks: convertArrayToString([moment().week()]),
      },
    },
  ];

  useEffect(() => {
    doFetchCompanyMoodStatsLine({
      range: "year",
      years: convertArrayToString([new Date().getFullYear()]),
    });
  }, []);

  useEffect(() => {
    if (lineChartFilter === 1) {
      setCompanyStatsLine(avgMonthlyArrays);
      return;
    }
    if (lineChartFilter === 2) {
      setCompanyStatsLine(avgWeeklyArrays);
      return;
    }
    if (lineChartFilter === 3) {
      setCompanyStatsLine(avgDailyArrays);
    }
  }, [companyStatsLineState]);

  useEffect(() => {
    let params = null;
    switch (doughnutFilter) {
      case 2:
        params = {
          range: "month",
          months: convertArrayToString(
            Array.from({ length: 12 }, (_, i) => i + 1)
          ),
        };
        break;
      case 3:
        params = {
          range: "week",
          weeks: convertArrayToString(
            getWeekNumbersInMonth(
              new Date().getFullYear(),
              new Date().getMonth()
            )
          ),
        };
        break;
      default:
        params = {
          range: "year",
          years: convertArrayToString(
            Array.from(
              { length: 12 },
              (_, index) => new Date().getFullYear() - index
            )
          ),
        };
        break;
    }
    if (!!params) {
      doFetchCompanyMoodStatsDoughnut(params);
    }
  }, [doughnutFilter]);

  useEffect(() => {
    if (!!companyStatsDoughnutState && !!doughnutChartFilter) {
      const periods = Object.keys(companyStatsDoughnutState);

      setDoughnutChartData(
        companyStatsDoughnutState[
          getPeriod(doughnutFilter, doughnutChartFilter, "string-to-number")
        ]?.companyStats?.[0]?.grades?.map((grade) => grade?.count) ||
          companyStatsDoughnutState[
            getPeriod(doughnutFilter, doughnutChartFilter, "string-to-number")
          ]?.companyStats?.checkinsStats?.[0]?.grades?.map(
            (grade) => grade?.count
          ) ||
          Array.from({ length: 5 }, () => 0)
      );

      const avgData = periods.map((period) => {
        const data = {
          period: getPeriod(doughnutFilter, period, "number-to-string"),
          avg: getAvg(
            doughnutFilter,
            companyStatsDoughnutState[period].companyStats
          ),
          most: getMax(companyStatsDoughnutState[period]?.companyStats),
        };
        return data;
      });
      setDoughnutAvgData(avgData);
    }
  }, [companyStatsDoughnutState, doughnutChartFilter, doughnutFilter]);

  const getPeriod = (filter, period, conversion) => {
    switch (filter) {
      case 2:
        if (conversion === "string-to-number") {
          return (
            moment.months().findIndex((x) => x === period) + 1
          ).toString();
        } else {
          return moment.months()[parseInt(period) - 1];
        }
      case 3:
        if (conversion === "string-to-number") {
          return period.replace("Week ", "");
        } else {
          return `Week ${period}`;
        }
      default:
        return period;
    }
  };

  const getAvg = (doughnutFilter, companyStats) => {
    switch (doughnutFilter) {
      case 1:
        if (!!companyStats.length) {
          return Math.round(companyStats[0]?.avgYearly);
        } else {
          return null;
        }
      case 2:
        if (!!companyStats.length) {
          return Math.round(companyStats[0]?.avgMonthly);
        } else {
          return null;
        }
      case 3:
        if (!!companyStats?.checkinsStats?.length) {
          return Math.round(companyStats?.checkinsStats[0]?.avgWeekly);
        } else {
          return null;
        }
      default:
        break;
    }
  };

  const getMax = (companyStats) => {
    let grades = [];
    if (Array.isArray(companyStats)) {
      grades = companyStats[0]?.grades;
    } else {
      grades = companyStats?.checkinsStats[0]?.grades;
    }

    if (!!grades?.length) {
      const maxValue = Math.max(...grades.map((grade) => grade.count));
      if (!!maxValue) {
        const foundGrade = grades
          .slice()
          .reverse()
          ?.find((grade) => grade.count === maxValue)?.grade;
        return foundGrade;
      }
    }
    return null;
  };

  const avgMonthlyArrays = Object.entries(companyStatsLineState)
    .map(([year, yearData]) => {
      if (!yearData?.companyStats?.length) {
        return { period: year, data: moment.months().map(() => NaN) };
      }

      return yearData?.companyStats?.flatMap((companyStat) => {
        return {
          period: year,
          data: moment.months().map((month, index) => {
            const foundMonth = companyStat?.months?.find((stats) => {
              return stats?.month === month;
            });

            return foundMonth ? Math.round(foundMonth?.avgMonthly) : NaN;
          }),
        };
      });
    })
    .flat();

  const avgWeeklyArrays = Object.entries(companyStatsLineState)
    .map(([month, monthData]) => {
      const weeks = getWeekNumbersInMonth(
        new Date().getFullYear(),
        month - 1
      )?.map((item) => `${new Date().getFullYear()}-${item}`);

      if (!monthData?.companyStats?.length) {
        return {
          period: getPeriod(lineChartFilter, month, "number-to-string")?.slice(
            0,
            3
          ),
          data: weeks.map(() => NaN),
        };
      }

      return monthData?.companyStats?.flatMap((companyStat) => {
        return {
          period: getPeriod(lineChartFilter, month, "number-to-string")?.slice(
            0,
            3
          ),
          data: weeks.map((week) => {
            const foundWeek = companyStat?.weeks[week];
            return foundWeek ? Math.round(foundWeek.avgWeekly) : NaN;
          }),
        };
      });
    })
    .flat();

  const avgDailyArrays = Object.entries(companyStatsLineState)
    .map(([week, weekData]) => {
      if (!weekData?.companyStats?.checkinsStats?.length) {
        return {
          period: getPeriod(lineChartFilter, week, "number-to-string"),
          data: daysCollection.map(() => NaN),
        };
      }

      return weekData.companyStats?.checkinsStats.flatMap((companyStat) => {
        return {
          period: getPeriod(lineChartFilter, week, "number-to-string"),
          data: daysCollection.map((day) => {
            const foundDay = companyStat?.days?.find((d) => d.day === day);
            return foundDay ? Math.round(foundDay.avgDaily) : NaN;
          }),
        };
      });
    })
    .flat();

  const handleLineFilterChange = (selectedFilter) => {
    setLineChartFilter(selectedFilter);
    const payload = lineChartPayload?.find(
      (item) => item?.value === selectedFilter
    );
    doFetchCompanyMoodStatsLine(payload.data);
  };

  const handleChartChange = (segment) => {
    let payload = {
      range: "year",
      years: convertArrayToString(segment.map((item) => parseInt(item))),
    };
    if (lineChartFilter === 2) {
      payload = {
        range: "month",
        months: convertArrayToString(
          segment
            .map((item) => {
              const monthObj = shortMonths.find(
                (month) => month.label === item
              );
              return monthObj ? monthObj.value : null;
            })
            .filter((value) => value !== null)
        ),
      };
    } else if (lineChartFilter === 3) {
      payload = {
        range: "week",
        weeks: convertArrayToString(
          segment.map((item) => parseInt(item.replace("Week ", "")))
        ),
      };
    }
    doFetchCompanyMoodStatsLine(payload);
  };

  const handleDoughnutFilterChange = (filter) => {
    setDoughnutFilter(filter);
  };

  const handleDoughnutChartFilterChange = (chartFilter) => {
    setDoughnutChartFilter(chartFilter);
  };

  return (
    <div className="chart">
      <LineChartCheckins
        name="usersStatsLine"
        title="Company Checkins"
        data={companyStatsLine}
        filterOptions={dateFilterOptions}
        onFilterChange={(e) => handleLineFilterChange(e)}
        handleChartChange={handleChartChange}
      />
      <DoughnutChartCheckins
        data={[doughnutChartData]}
        filterOptions={dateFilterOptions}
        avgData={doughnutAvgData}
        onFilterChange={handleDoughnutFilterChange}
        onChartFilterChange={handleDoughnutChartFilterChange}
      />
    </div>
  );
};

export default YourCheckIn;
