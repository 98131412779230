import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchMeetings = createAsyncThunk(
  "meetings/fetch",
  async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/meetings/${userId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleMeeting = createAsyncThunk(
  "meetings/fetch/single",
  async (meetingId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/single-meeting/${meetingId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createMeeting = createAsyncThunk(
  "meetings/create",
  async (payload) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/meetings`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateMeeting = createAsyncThunk(
  "meetings/update",
  async ({ id, payload }) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/meetings/${id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteMeeting = createAsyncThunk("meetings/delete", async (id) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/meetings/delete/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchMeetingsStatistics = createAsyncThunk(
  "meetings/statistics/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/meetings-statistics`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMeetingsPriority = createAsyncThunk(
  "meetings/priority/fetch",
  async (month = "all") => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/meetings-statistics/priority?month=${month}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTalkingPoints = createAsyncThunk(
  "meetings/talkingPoints/fetch",
  async (month = "all") => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/meetings-statistics/tags?month=${month}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMeetingsTypeStatistics = createAsyncThunk(
  "meetings/types/fetch",
  async (month = "all") => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/meetings-statistics/type?month=${month}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLeaderboardAmount = createAsyncThunk(
  "meetings/amount/fetch",
  async (month = "all") => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/meetings-statistics/leaderboard-amount?month=${month}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
