import React, { useState, useEffect } from "react";
import "./Sidebar.scss";
import {
  ChatIcon,
  ReportsIcon,
  AdministrationIcon,
  DoubleArrow,
  MeetingsIcon,
  SupportIcon,
  SettingsIcon,
  CloseIcon,
  CareerPathIcon,
  AdminIcon,
  CareerIcon,
} from "../../icons";

import { NavLink, useLocation } from "react-router-dom";
import {
  careerPathViews,
  careerPathClientViews,
  administrationViews,
  projectsViews,
  surveyViews,
  reportsViews,
  personalPerformanceViews,
  meetingsViews,
} from "../../data/views";

import useFirstPathSegment from "../../hooks/useFirstPathSegment";

function Sidebar({ screenWidth, handleResponsiveMenuClose }) {
  const logo = "/logo/logo.png";
  const logoText = "/logo/logo-text.png";

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showChildren, setShowChildren] = useState(false);
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const location = useLocation();
  const firstSegment = useFirstPathSegment();

  const canSeeReports = localUserData?.permissions?.find(
    (permission) => permission.value === "can_read_reports"
  );
  const canSeeSettings = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_settings"
  );
  const canSeeProjects = localUserData?.permissions?.find(
    (permission) => permission.value === "can_view_projects"
  );
  const canSeeGoals = localUserData?.permissions?.find(
    (permission) => permission.value === "can_view_goals"
  );
  const canSeeTeams = localUserData?.permissions?.find(
    (permission) => permission.value === "can_view_teams"
  );
  const canSeeUsers = localUserData?.permissions?.find(
    (permission) => permission.value === "can_view_users"
  );
  const canSeeAchievements = localUserData?.permissions?.find(
    (permission) => permission.value === "can_view_achievements"
  );
  const canSeeRecognitions = localUserData?.permissions?.find(
    (permission) => permission.value === "can_view_recognitions"
  );
  const canSeeCases = localUserData?.permissions?.find(
    (permission) => permission.value === "can_view_support_cases"
  );
  const canSeeRoles = localUserData?.permissions?.find(
    (permission) => permission.value === "can_view_roles"
  );
  const canManageProjects = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_projects"
  );
  const canManageGoals = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_goals"
  );
  const canManageTeams = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_teams"
  );
  const canManageUsers = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_users"
  );
  const canManageAchievements = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_achievements"
  );
  const canManageRecognitions = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_recognitions"
  );
  const canManageCases = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_support_cases"
  );
  const canManageRoles = localUserData?.permissions?.find(
    (permission) => permission.value === "can_manage_roles"
  );

  const canSeeAdmin =
    !!canSeeProjects ||
    !!canSeeGoals ||
    !!canSeeTeams ||
    !!canSeeUsers ||
    !!canSeeAchievements ||
    !!canSeeRecognitions ||
    !!canSeeCases ||
    !!canSeeRoles ||
    !!canManageProjects ||
    !!canManageGoals ||
    !!canManageTeams ||
    !!canManageUsers ||
    !!canManageAchievements ||
    !!canManageRecognitions ||
    !!canManageCases ||
    !!canManageRoles;

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleToggleDropdown = () => {
    setShowChildren(!showChildren);
  };

  useEffect(() => {
    if (!location.pathname.includes("administration")) {
      setShowChildren(false);
    }
  }, [location]);

  return (
    <div className={sidebarOpen ? "sidebar open" : "sidebar closed"}>
      <div className="sidebar-header">
        <div className="sidebar-info">
          <div className="logo-wrapper">
            <img src={logo} alt="logo" />
            {sidebarOpen && <img src={logoText} alt="logo-text" />}
            {screenWidth > 840 && (
              <div className="sidebar-toggler">
                <span
                  onClick={handleSidebarToggle}
                  className={sidebarOpen ? "open" : "closed"}
                >
                  <DoubleArrow />
                </span>
              </div>
            )}
            {screenWidth <= 840 && (
              <div
                className="modal-header-close"
                onClick={handleResponsiveMenuClose}
              >
                <CloseIcon />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sidebar-content">
        {canSeeAdmin ? (
          <div>
            <NavLink
              to="career-path/position/table"
              className={`sidebarOption ${
                firstSegment === "career-path" ? "active" : ""
              }`}
            >
              <AdminIcon />
              <h5>Career Path - Admin</h5>
            </NavLink>
            <ul
              className={`subnav ${
                location.pathname.includes("career-path") ? "show" : ""
              }`}
            >
              {careerPathViews.map((view, index) => (
                <li key={`career-item-${view?.id}`}>
                  <NavLink
                    to={`career-path/${view.slug}`}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    {view.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}

        <div>
          <NavLink
            to="your-career/home"
            className={`sidebarOption ${
              firstSegment === "your-career" ? "active" : ""
            }`}
          >
            <CareerPathIcon />
            <h5>Your Career</h5>
          </NavLink>
          <ul
            className={`subnav ${
              location.pathname.includes("your-career") ? "show" : ""
            }`}
          >
            {careerPathClientViews.map((view) => (
              <li key={`career-client-item-${view?.id}`}>
                <NavLink
                  to={`your-career/${view.slug}`}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {view.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        <NavLink
          to="chat"
          className={`sidebarOption ${firstSegment === "chat" ? "active" : ""}`}
        >
          <ChatIcon />
          <h5>Chat</h5>
        </NavLink>

        <NavLink
          to="career"
          className={`sidebarOption ${
            firstSegment === "career" ? "active" : ""
          }`}
        >
          <CareerIcon />
          <h5>Career</h5>
        </NavLink>

        {canSeeReports ? (
          <div>
            <NavLink
              to="reports/chat"
              className={`sidebarOption ${
                firstSegment === "reports" ? "active" : ""
              }`}
            >
              <ReportsIcon />
              <h5>Reports</h5>
            </NavLink>
            <ul
              className={`subnav ${
                location.pathname.includes("reports") ? "show" : ""
              }`}
            >
              {reportsViews.map((view) => (
                <li key={`reports-item-${view?.id}`}>
                  <NavLink
                    to={`reports/${view.slug}`}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    {view.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}

        {canSeeAdmin ? (
          <div>
            <NavLink
              to="administration/teams"
              className={`sidebarOption ${
                firstSegment === "administration" ? "active" : ""
              }`}
            >
              <AdministrationIcon />
              <h5>Administration</h5>
            </NavLink>
            <ul
              className={`subnav ${
                location.pathname.includes("administration") ? "show" : ""
              }`}
            >
              {administrationViews.map((view) =>
                !view?.children ? (
                  <li key={`administration-item-${view?.id}`}>
                    <NavLink
                      to={`administration/${view.slug}`}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      {view.label}
                    </NavLink>
                  </li>
                ) : (
                  <li
                    key={`administration-item-${view?.id}`}
                    className={`subnav-children ${showChildren ? "show" : ""}`}
                  >
                    <p onClick={handleToggleDropdown}>{view.label}</p>

                    <ul className="children-list">
                      {view?.children.map((child) => (
                        <li key={`administration-item-${child?.id}`}>
                          <NavLink
                            to={`administration/${child.slug}`}
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            {child.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              )}
            </ul>
          </div>
        ) : (
          ""
        )}

        <NavLink
          to="meetings"
          className={`sidebarOption ${
            firstSegment === "meetings" ? "active" : ""
          }`}
        >
          <MeetingsIcon />
          <h5>Meetings</h5>
        </NavLink>

        <ul
          className={`subnav ${
            location.pathname.includes("meetings") ? "show" : ""
          }`}
        >
          {meetingsViews.map((view) => (
            <li key={`meetings-item-${view?.id}`}>
              <NavLink
                to={`meetings/${view.slug}`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                {view.label}
              </NavLink>
            </li>
          ))}
        </ul>

        <NavLink
          to="support"
          className={`sidebarOption ${
            firstSegment === "support" ? "active" : ""
          }`}
        >
          <SupportIcon />
          <h5>Support</h5>
        </NavLink>

        {canSeeSettings ? (
          <NavLink
            to="settings"
            className={`sidebarOption ${
              firstSegment === "settings" ? "active" : ""
            }`}
          >
            <SettingsIcon />
            <h5>Settings</h5>
          </NavLink>
        ) : (
          ""
        )}

        <div>
          <NavLink
            to="projects/my-tasks-overview"
            className={`sidebarOption ${
              firstSegment === "projects" ? "active" : ""
            }`}
          >
            <CareerPathIcon />
            <h5>Projects</h5>
          </NavLink>
          <ul
            className={`subnav ${
              location.pathname.includes("projects") ? "show" : ""
            }`}
          >
            {projectsViews.map((view) => (
              <li key={`projects-item-${view?.id}`}>
                <NavLink
                  to={`projects/${view.slug}`}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {view.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        {canSeeAdmin ? (
          <div>
            <NavLink
              to="survey/ongoing"
              className={`sidebarOption ${
                firstSegment === "survey" ? "active" : ""
              }`}
            >
              <AdministrationIcon />
              <h5>Survey</h5>
            </NavLink>
            <ul
              className={`subnav ${
                location.pathname.includes("survey") ? "show" : ""
              }`}
            >
              {surveyViews.map((view) => (
                <li key={`survey-item-${view?.id}`}>
                  <NavLink
                    to={`survey/${view.slug}`}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    {view.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <NavLink
            to="/survey/user-surveys"
            className={`sidebarOption ${
              firstSegment === "survey" ? "active" : ""
            }`}
          >
            <AdministrationIcon />
            <h5>Survey</h5>
          </NavLink>
        )}

        <NavLink
          to="personal-performance"
          className={`sidebarOption ${
            firstSegment === "personal-performance" ? "active" : ""
          }`}
        >
          <AdministrationIcon />
          <h5>Personal Performance</h5>
        </NavLink>

        {canSeeAdmin ? (
          <ul
            className={`subnav ${
              location.pathname.includes("personal-performance") ? "show" : ""
            }`}
          >
            {personalPerformanceViews.map((view) => (
              <li key={`personal-performance-item-${view?.id}`}>
                <NavLink
                  to={`personal-performance/${view.slug}`}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {view.label}
                </NavLink>
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Sidebar;
