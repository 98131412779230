import { useSelector } from "react-redux";

import { ProgressBar } from "../../../ui";
import MiniMatrix from "../../../CareerPath/client/MiniMatrix";
import "./styles.scss";
import { dummyPosition } from "./dummyData";

const InitiativeReview = ({ selectedUser }) => {
  const matrixState = useSelector((state) => state.matrices)?.singleMatrix;

  const careerPath = dummyPosition;

  const renderList = (type) => {
    return (
      <ul>
        <li>
          <div className="head column">
            {type === "current" ? "Current path" : "Career path history"}
          </div>
          <div className="head column">Goal Position</div>
          <div className="head column">Start date</div>
          <div className="head column">
            {type === "current" ? "Deadline" : "Achieved"}
          </div>
          <div className="head column">Progress</div>
        </li>
        {type === "current" ? (
          <li>
            <div className="column">
              <p className="name">{careerPath?.name}</p>
              <p>{careerPath?.position}</p>
            </div>
            <div className="column">{careerPath?.goalPosition?.position}</div>
            <div className="column">{careerPath?.goalPosition?.startDate}</div>
            <div className="column">{careerPath?.goalPosition?.endDate}</div>
            <div className="column">
              <ProgressBar progress={careerPath?.goalPosition?.progress} />
            </div>
          </li>
        ) : (
          careerPath?.history.map((path, index) => (
            <li key={`history-${index}`}>
              <div className="column">
                <p className="name">{careerPath?.name}</p>
                <p>{path?.position}</p>
              </div>
              <div className="column">{path?.goalPosition?.position}</div>
              <div className="column">{path?.startDate}</div>
              <div className="column">{path?.achieved}</div>
              <div className="column">
                <ProgressBar progress={path?.progress} />
              </div>
            </li>
          ))
        )}
      </ul>
    );
  };

  return (
    <div className="initiative-review">
      <div className="initiative">
        <div className="title-line">
          <h2 className="title single-view-header-filters">
            Career path initiative
          </h2>
        </div>
        <div className="current-path-list">{renderList("current")}</div>
        <div className="career-history-list">{renderList("history")}</div>
      </div>
      <div className="graph">
        {!!matrixState ? (
          <MiniMatrix
            user={!!selectedUser ? selectedUser?.id : careerPath.userId}
            matrix={matrixState}
          />
        ) : (
          <p className="no-matrix">There is no matching career path</p>
        )}
      </div>
    </div>
  );
};

export default InitiativeReview;
