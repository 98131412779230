import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAchievements,
  createAchievement,
  updateAchievement,
  deleteAchievement,
} from "./achievementsThunks";

const achievementsSlice = createSlice({
  name: "achievements",
  initialState: {
    achievements: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetAchievementsMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAchievements.fulfilled, (state, action) => {
      state.achievements = action.payload;
    });
    builder.addCase(createAchievement.fulfilled, (state, action) => {
      state.achievements.achievementData = [
        ...state.achievements.achievementData,
        action.payload,
      ];
      state.message = {
        type: "success",
        title: "Success",
        message: "Achievement successfully created",
      };
    });
    builder.addCase(updateAchievement.fulfilled, (state, action) => {
      const updatedState = state?.achievements?.achievementData?.map(
        (achievement) => {
          return achievement.id === action.payload.data.id
            ? action.payload.data
            : achievement;
        }
      );
      state.achievements.achievementData = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Achievement successfully updated",
      };
    });
    builder.addCase(deleteAchievement.fulfilled, (state, action) => {
      const filteredAchievements = state?.achievements?.achievementData?.filter(
        (achievement) => achievement.id !== action.meta.arg
      );
      state.achievements.achievementData = filteredAchievements;
      state.message = {
        type: "success",
        title: "Success",
        message: "Achievement successfully deleted",
      };
    });
  },
});

export const { resetAchievementsMessage } = achievementsSlice.actions;

export const achievementsReducer = achievementsSlice.reducer;
