import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchWorkshopSeminars = createAsyncThunk(
  "workshopSeminar/fetch",
  async ({ user_id }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/workshop-seminars`, {
        params: {
          user_id,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchWorkshopSeminar = createAsyncThunk(
  "workshopSeminar/fetchById",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/workshop-seminar/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createWorkshopSeminar = createAsyncThunk(
  "workshopSeminar/create",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/workshop-seminar`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateWorkshopSeminar = createAsyncThunk(
  "workshopSeminar/update",
  async ({ id, data }) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/workshop-seminar/${id}`,
        {
          ...data,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteWorkshopSeminar = createAsyncThunk(
  "workshopSeminar/delete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/workshop-seminar/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
