export const careerPathViews = [
  { id: 1, label: "Organisation Hierarchy", slug: "organisation" },
  { id: 2, label: "Position", slug: "position/table" },
  { id: 3, label: "Employees", slug: "employees" },
  { id: 4, label: "Feedbacks", slug: "feedbacks" },
];

export const careerPathClientViews = [
  { id: 1, label: "Home", slug: "home" },
  { id: 2, label: "High Fives", slug: "high-fives" },
  { id: 3, label: "Check-ins", slug: "check-ins" },
];

export const administrationViews = [
  { id: 1, label: "Teams", slug: "teams" },
  { id: 2, label: "Users", slug: "users" },
  { id: 3, label: "Achievements", slug: "achievements" },
  { id: 4, label: "Roles", slug: "roles" },
  {
    id: 5,
    label: "Personal Performance",
    slug: "performance",
    children: [
      { id: 51, label: "Skills", slug: "skills" },
      { id: 52, label: "Responsibilities", slug: "responsibilities" },
      { id: 53, label: "Behaviours", slug: "behaviours" },
      { id: 54, label: "Interests", slug: "interests" },
    ],
  },

  { id: 6, label: "Meeting Types", slug: "meeting-types" },
];

export const projectsViews = [
  { id: 1, label: "My Tasks Overview", slug: "my-tasks-overview" },
  { id: 2, label: "Projects", slug: "projects-overview" },
];

export const surveyViews = [
  { id: 1, label: "Ongoing surveys", slug: "ongoing" },
  { id: 2, label: "Completed", slug: "completed" },
  { id: 3, label: "Drafts", slug: "drafts" },
  { id: 4, label: "Archived", slug: "archived" },
  { id: 5, label: "My surveys", slug: "user-surveys" },
];

export const reportsViews = [
  { id: 1, label: "Chat", slug: "chat" },
  { id: 2, label: "Communication", slug: "communication" },
];

export const personalPerformanceViews = [
  { id: 1, label: "Admin", slug: "dashboard" },
  { id: 2, label: "Drafts", slug: "drafts" },
];

export const meetingsViews = [{ id: 1, label: "Dashboard", slug: "dashboard" }];

export const conferenceModalViews = [
  {
    id: 1,
    label: "Conference Attendance",
    slug: "conference-attendance",
    dataKey: "conferenceAttendances",
  },
  {
    id: 2,
    label: "Workshop & Seminars",
    slug: "workshop-seminars",
    dataKey: "workshopSeminars",
  },
  {
    id: 3,
    label: "Public Speaking",
    slug: "public-speaking",
    dataKey: "publicSpeakings",
  },
  {
    id: 4,
    label: "Panel Discussions",
    slug: "panel-discussions",
    dataKey: "panelDiscussions",
  },
  {
    id: 5,
    label: "Professional Organizations",
    slug: "professional-organizations",
    dataKey: "professionalOrganizations",
  },
  {
    id: 6,
    label: "Committee Involvement",
    slug: "committee-involvement",
    dataKey: "committeeInvolvements",
  },
  {
    id: 7,
    label: "Advanced Degrees",
    slug: "advanced-degrees",
    dataKey: "advancedDegrees",
  },
  {
    id: 8,
    label: "Certification Programs",
    slug: "certification-programs",
    dataKey: "certificationPrograms",
  },
];
