import { ProgressBar, Status, UserIcon } from "../../../ui";
import { CalendarLightIcon } from "../../../../icons";

import { useDispatch, useSelector } from "react-redux";

import { taskStatuses } from "../../../../data/statuses";
import { useState } from "react";
import TasksUpdateModal from "../tasksOverview/TasksUpdateModal";
import { shortenText } from "../../../../utils/textFormat";

const SingleProject = () => {
  const singleProjectState = useSelector(
    (state) => state.projects
  )?.singleProject;
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setSelectedTask(null);
    setShowModal(false);
  };

  const handleOpenUpdateModal = (task) => {
    setSelectedTask({
      id: task?.id,
      user: task?.user,
      userId: task?.user_id,
      title: task?.title,
      description: task?.description,
      notes: task?.notes,
      privacy: task?.privacy,
      type: task?.type,
      assets:
        typeof task?.assets === "string"
          ? JSON.parse(task?.assets)
          : task?.assets,
      results:
        typeof task?.results === "string"
          ? JSON.parse(task?.results)
          : task?.results,
      resources: task?.resources,
      progress: task?.progress,
      startDate: task?.start_date,
      status: task?.status,
      settings: {
        startDate: task?.start_date,
        endDate: task?.end_date,
      },
      project: task?.project,
    });
    setShowModal(true);
  };

  return (
    <div className="projects-wrapper">
      <div className="projects-header header-action">
        <h2>New Project Assigned</h2>
      </div>

      <div className="single-project-content">
        <div className="single-project-content-top">
          <div className="single-project-top-wrapper">
            <h3>{singleProjectState?.name}</h3>
            <div className="single-project-top-right items-center">
              <span
                className={`status ${
                  taskStatuses.find(
                    (status) => status.id === singleProjectState?.status
                  )?.color
                }`}
              >
                {
                  taskStatuses.find(
                    (status) => status.id === singleProjectState?.status
                  )?.label
                }
              </span>
              <div>
                <ProgressBar progress={singleProjectState?.progress} />
              </div>
            </div>
          </div>
          <p>{singleProjectState?.description}</p>
        </div>
        <div className="single-project-content-users">
          <h4>Invited Users ({singleProjectState?.users?.length})</h4>
          <div className="single-project-users grid grid-cols-3 gap-16">
            {singleProjectState?.users?.map((user, index) => (
              <div
                className="single-project-user flex items-center"
                key={index}
              >
                <UserIcon
                  userImage={user?.image}
                  userName={
                    user?.name || `${user?.first_name} ${user?.last_name} || `
                  }
                />
                <span>
                  {user?.name || `${user?.first_name} ${user?.last_name}`}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="single-project-content-tasks">
          <h4>Tasks</h4>
          <div className="single-project-tasks-wrapper flex flex-col">
            {!singleProjectState?.tasks?.length ? (
              <p>No Tasks</p>
            ) : (
              singleProjectState?.tasks?.map((task, index) => (
                <div
                  className="single-project-task flex justify-between items-center"
                  key={index}
                  onClick={() => handleOpenUpdateModal(task)}
                >
                  <div
                    className="flex single-project-task-title"
                    title={task?.title}
                  >
                    <p className="text-sm">{shortenText(task?.title, 30)}</p>
                  </div>
                  <div className="single-project-task-date flex items-center">
                    <CalendarLightIcon />
                    <span className="text-sm">
                      {task?.start_date} - {task?.end_date}
                    </span>
                  </div>
                  <Status
                    status={taskStatuses.find(
                      (status) => status.id === task?.status
                    )}
                  />
                  <ProgressBar progress={task?.progress} />
                  <div className="text-sm">Target 100%</div>
                  <UserIcon
                    userName={
                      singleProjectState?.users.find(
                        (user) => user?.id === task?.user_id
                      )?.first_name
                    }
                    userImage={
                      singleProjectState?.users.find(
                        (user) => user?.id === task?.user_id
                      )?.image
                    }
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <TasksUpdateModal
          selectedTask={selectedTask}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default SingleProject;
