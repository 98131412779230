const RedCircleArrowIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 22C4.92487 22 3.31784e-07 17.0751 -4.64873e-07 11C-1.26153e-06 4.92487 4.92487 2.23913e-06 11 1.44248e-06C17.0751 6.45818e-07 22 4.92487 22 11C22 17.0751 17.0751 22 11 22Z"
      fill="#F07171"
    />
    <path
      d="M16 12.9726L12.6667 12.9726L12.6667 3.95361L9.33333 3.95361L9.33333 12.9726L6 12.9726L11.05 18.384L16 12.9726Z"
      fill="white"
    />
  </svg>
);
export default RedCircleArrowIcon;
