import { useState, useEffect } from "react";
import { SearchIcon, CloseIcon } from "../../../../icons";
import { Button, Input, ProgressBar, Table } from "../../../ui";

import { tableColumns } from "./tableData";

import { fetchProjectsTasks } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";
import { setSearchTerm } from "../../../../store/pageViews/pageViewsSlice";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment/moment";

import "./styles.scss";
import TasksUpdateModal from "./TasksUpdateModal";

const TasksOverview = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sort, setSort] = useState("asc");

  const [doFetchProjectsTasks] = useThunk(fetchProjectsTasks);
  const projectsTasksState = useSelector((state) => state.tasks)?.projectsTasks;
  const projectsTasksMetaState = useSelector(
    (state) => state.tasks
  )?.projectsTasksMeta;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;

  const handleSearch = (event) => {
    dispatch(setSearchTerm(event.target.value));
  };

  const handleStatusFilter = (status) => {
    setStatus(status);
  };

  useEffect(() => {
    doFetchProjectsTasks({
      searchTerm,
      status,
      sort,
      startDate: startDate ? moment(startDate).format("DD.MM.yyyy") : startDate,
      endDate: endDate ? moment(endDate).format("DD.MM.yyyy") : endDate,
    });
  }, [searchTerm, status, sort, startDate, endDate]);

  const handleOpenUpdateModal = (task) => {
    setSelectedTask({
      id: task?.id,
      user: task?.user,
      userId: task?.user?.id,
      title: task?.title,
      description: task?.description,
      notes: task?.notes,
      privacy: task?.privacy,
      type: task?.type,
      assets: task?.assets,
      results: task?.results,
      resources: task?.resources,
      progress: task?.progress,
      startDate: task?.settings?.startDate,
      status: task?.status,
      settings: task?.settings,
      project: task?.project,
    });
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const tableRows = () => {
    const updatedTasks = projectsTasksState?.map((task) => {
      return {
        ...task,
        progressComponent: <ProgressBar progress={task?.progress} />,
        projectTitle: task?.project?.title,
        dueDate: task?.settings?.endDate,
      };
    });
    return updatedTasks || [];
  };

  const countByStatus = (status) => {
    return projectsTasksMetaState?.totalPerStatus[status];
  };

  const handleDateChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleResetDate = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleSort = () => {
    if (sort === "asc") {
      setSort("desc");
      return;
    }
    setSort("asc");
  };

  const taskFilterStatuses = [
    {
      id: 0,
      value: 0,
      label: "Total Tasks",
      // count: projectsTasksMetaState?.total,
      count: projectsTasksState?.length,
    },
    {
      id: 1,
      value: 1,
      label: "On Track",
      color: "green",
      count: countByStatus(1),
    },
    {
      id: 2,
      value: 2,
      label: "Locked",
      color: "purple",
      count: countByStatus(2),
    },
    {
      id: 3,
      value: 3,
      label: "Feature Creep",
      color: "blue",
      count: countByStatus(3),
    },
    {
      id: 4,
      value: 4,
      label: "No status",
      color: "grey",
      count: countByStatus(4),
    },
    {
      id: 5,
      value: 100,
      label: "Completed",
      color: "green",
      count: countByStatus(100),
    },
  ];

  const tableActions = [
    {
      id: 1,
      element: "Update Now",
      action: (task) => handleOpenUpdateModal(task),
    },
  ];

  return (
    <div className="projects-wrapper">
      <div className="projects-header">
        <h2>Good morning, {localUserData?.first_name}</h2>
      </div>
      <div className="projects-content">
        <div className="projects-content-top">
          <h3>My Tasks ({projectsTasksState?.length})</h3>
        </div>
        <div className="projects-status-filters">
          {taskFilterStatuses.map((taskStatus, index) => (
            <div
              className={`projects-filter ${
                taskStatus.id === status ? "active" : ""
              }`}
              key={`project-filter-${index}`}
              onClick={() => handleStatusFilter(taskStatus.id)}
            >
              <p>{taskStatus.label}</p>
              <span>{taskStatus.count}</span>
            </div>
          ))}
        </div>
        <div className="project-table-filters">
          <Input
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon2"
            handleChange={handleSearch}
            variant="light"
            value={searchTerm}
            icon={<SearchIcon />}
          />

          <div className="date-filter date-picker-holder">
            <DatePicker
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Filter by date"
              dateFormat="dd.MM.yyyy"
              dateFormatCalendar={"MMM yyyy"}
              selectsRange
              showMonthDropdown
              showYearDropdown
            />
            {(!!startDate || !!endDate) && (
              <Button
                variant="reset-filter control"
                handleClick={handleResetDate}
              >
                <CloseIcon />
              </Button>
            )}
          </div>
          <div className="sort-filter">
            <p onClick={handleSort}>
              Sort by {sort === "asc" ? <span>A-Z</span> : <span>Z-A</span>}
            </p>
          </div>
        </div>
        <Table
          columns={tableColumns}
          rows={tableRows()}
          customActions={tableActions}
        />
      </div>
      <TasksUpdateModal
        selectedTask={selectedTask}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default TasksOverview;
