import { createSlice } from "@reduxjs/toolkit";
import {
  fetchInterests,
  createInterest,
  updateInterest,
  deleteInterest,
} from "./interestsThunks";

export const interestsSlice = createSlice({
  name: "interests",
  initialState: {
    interests: [],
    loading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetInterestMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInterests.fulfilled, (state, action) => {
      state.interests = action.payload;
    });
    builder.addCase(createInterest.fulfilled, (state, action) => {
      state.interests.push(action.payload);
    });
    builder.addCase(updateInterest.fulfilled, (state, action) => {
      const index = state.interests.findIndex(
        (interest) => interest.id === action.payload.id
      );
      state.interests[index] = action.payload;
    });
    builder.addCase(deleteInterest.fulfilled, (state, action) => {
      state.interests = state.interests.filter(
        (interest) => interest.id !== action.meta.arg
      );
    });
  },
});

export const { resetInterestMessage } = interestsSlice.actions;

export const interestsReducer = interestsSlice.reducer;
