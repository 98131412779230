import { useState, useEffect } from "react";
import { SearchIcon, CloseIcon, AddIcon } from "../../../../icons";
import {
  Button,
  ProgressBar,
  Table,
  PopupActions,
  Pagination,
  Input,
} from "../../../ui";

import { tableColumns } from "./tableData";
import UsersImagesRow from "../../../UsersImagesRow";

import {
  fetchProjects,
  fetchSingleProject,
  followProject,
  unfollowProject,
  createProject,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";
import { setSearchTerm } from "../../../../store/pageViews/pageViewsSlice";

import moment from "moment/moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import "./styles.scss";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetProjectMessage } from "../../../../store/projects/projectsSlice";
import { useNavigate, useLocation } from "react-router-dom";
import SingleProject from "./SingleProject";
import ListTasksModal from "../tasksOverview/ListTasksModal";
import UpdateProjectStatusModal from "../tasksOverview/UpdateProjectStatusModal";

const ProjectsOverview = () => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  const [followedBy, setFollowedBy] = useState(null);
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const [doFetchProjects] = useThunk(fetchProjects);
  const [doFectchSingleProject] = useThunk(fetchSingleProject);
  const [doFollowProject] = useThunk(followProject);
  const [doUnfollowProject] = useThunk(unfollowProject);
  const [doCreateProject, isLoading] = useThunk(createProject);
  const [doShowToastMessage] = useToastMessage(resetProjectMessage);

  const projectsState = useSelector((state) => state.projects)?.projects;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state?.projects)?.message;

  const handleSearch = (event) => {
    dispatch(setSearchTerm(event.target.value));
  };

  useEffect(() => {
    if (!isLoading) {
      doFetchProjects({
        page,
        searchTerm,
        status,
        sort,
        userId,
        followedBy,
        startDate: startDate
          ? moment(startDate).format("DD.MM.yyyy")
          : startDate,
        endDate: endDate ? moment(endDate).format("DD.MM.yyyy") : endDate,
      });
    }
  }, [
    searchTerm,
    status,
    sort,
    userId,
    followedBy,
    startDate,
    endDate,
    page,
    isLoading,
    doFetchProjects,
  ]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const handleSort = () => {
    if (sort === "asc") {
      setSort("desc");
      return;
    }
    setSort("asc");
  };

  const countByStatus = (status) => {
    return projectsState?.pageData?.totalPerStatus[status];
  };

  const navigationItems = [
    {
      id: 1,
      label: "All Projects",
      action: () => setStatus(null),
    },
    {
      id: 2,
      label: "My Projects",
      action: () => setUserId(localUserData?.id),
    },
    {
      id: 3,
      label: "Followed",
    },
  ];

  const projectFilterStatuses = [
    {
      id: 0,
      value: null,
      label: "Total Projects",
      count: projectsState?.pageData?.total,
    },
    {
      id: 1,
      value: 1,
      label: "On Track",
      count: countByStatus(1),
    },
    {
      id: 2,
      value: 2,
      label: "Locked",
      count: countByStatus(2),
    },
    {
      id: 3,
      value: 3,
      label: "Feature Creep",
      count: countByStatus(3),
    },
    {
      id: 4,
      value: 4,
      label: "No status",
      count: countByStatus(4),
    },
    {
      id: 5,
      value: 100,
      label: "Completed",
      count: countByStatus(100),
    },
  ];

  const handleSelectTab = (item) => {
    setActiveTab(item.id);
    setStatus(null);
    setUserId(null);
    setFollowedBy(null);
    if (item.id === 1) {
      return setStatus(null);
    }
    if (item.id === 2) {
      return setUserId(localUserData?.id);
    }
    return setFollowedBy(localUserData?.id);
  };

  const tableRows = () => {
    const updatedProjects = projectsState?.projectData?.map((project) => {
      return {
        ...project,
        follow: project?.followers?.some(
          (follower) => follower?.id === localUserData?.id
        )
          ? "Unfollow"
          : "Follow",
        progressComponent: <ProgressBar progress={project?.progress} />,
        usersComponent: !!project?.users.length ? (
          <UsersImagesRow usersCollection={project?.users} slug="image" />
        ) : (
          "No assignee"
        ),
      };
    });

    return updatedProjects || [];
  };

  const handleFormOpen = () => {
    navigate("/projects/project-form");
  };

  const handleSingleProjectOpen = (row) => {
    doFectchSingleProject(row?.id);
    navigate("/projects/projects-overview/single-project");
  };

  const handleDateChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleResetDate = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStatusFilter = (status) => {
    setStatus(status);
  };

  const handleShowTasks = (id) => {
    const findProject = projectsState?.projectData?.find(
      (project) => project.id === id
    );
    // const tasks = findProject?.tasks || [];

    // setSelectedTasks(tasks);
    // setShowModalUpdate(true);
    doFectchSingleProject(findProject?.id);
    navigate("/projects/projects-overview/single-project");
  };
  const handleShowUpdateStatus = (id) => {
    setSelectedRowId(id);
    const findProject = projectsState?.projectData?.find(
      (project) => project.id === id
    );
    setSelectedProject(findProject);
    setShowUpdateStatus(true);
  };

  const toggleFollow = (row) => {
    const isFollowed = row.followers.some(
      (item) => item.id === localUserData?.id
    );
    const payload = { id: row?.id, userId: localUserData?.id };
    if (!isFollowed) {
      return doFollowProject(payload);
    }
    doUnfollowProject(payload);
  };

  const handleCloseModal = () => {
    if (showModalUpdate) {
      setShowModalUpdate(false);
    } else {
      setShowUpdateStatus(false);
      setSelectedProject(null);
    }

    setSelectedTasks(null);
    setSelectedRowId(null);
  };

  const tableActions = [
    {
      id: 1,
      defaultLabel: "follow",
      action: (row) => toggleFollow(row),
    },
    {
      id: _.uniqueId(""),
      element: (
        <PopupActions
          actions={[
            {
              id: 1,
              label: "View Tasks",
              action: () => handleShowTasks(selectedRowId),
            },
            {
              id: 2,
              label: "Update Statuses",
              action: () => handleShowUpdateStatus(selectedRowId),
            },
          ]}
          key={_.uniqueId("")}
        />
      ),
      action: (row) => setSelectedRowId(row?.id),
    },
  ];

  return (
    <>
      {location.pathname !== "/projects/projects-overview/single-project" ? (
        <div className="projects-wrapper">
          <div className="projects-header header-action">
            <h2>Projects</h2>
            <Button handleClick={handleFormOpen}>
              <AddIcon />
              Create New Project
            </Button>
          </div>
          <div className="projects-content">
            <ul className="projects-top-nav">
              {navigationItems.map((item) => (
                <li
                  className={activeTab === item.id ? "active" : ""}
                  key={item.id}
                  onClick={() => handleSelectTab(item)}
                >
                  {item.label}
                </li>
              ))}
            </ul>
            <div className="projects-content-top">
              <h3>Projects summary ({projectsState?.projectData?.length})</h3>
            </div>
            <div className="projects-status-filters">
              {projectFilterStatuses.map((projectStatus, index) => (
                <div
                  className={`projects-filter ${
                    projectStatus.id === status ? "active" : ""
                  }`}
                  key={`project-filter-${index}`}
                  onClick={() => handleStatusFilter(projectStatus.value)}
                >
                  <p>{projectStatus.label}</p>
                  <span>{projectStatus.count}</span>
                </div>
              ))}
            </div>
            <div className="project-table-filters">
              <Input
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                handleChange={handleSearch}
                variant="light"
                value={searchTerm}
                icon={<SearchIcon />}
              />
              <div className="date-filter date-picker-holder">
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Filter by date"
                  dateFormat="dd.MM.yyyy"
                  dateFormatCalendar={"MMM yyyy"}
                  selectsRange
                  showMonthDropdown
                  showYearDropdown
                />
                {(!!startDate || !!endDate) && (
                  <Button
                    variant="reset-filter control"
                    handleClick={handleResetDate}
                  >
                    <CloseIcon />
                  </Button>
                )}
              </div>
              <div className="sort-filter">
                <p onClick={handleSort}>
                  Sort by {sort === "asc" ? <span>A-Z</span> : <span>Z-A</span>}
                </p>
              </div>
            </div>
            <Table
              columns={tableColumns}
              rows={tableRows()}
              customActions={tableActions}
              handleRowClick={handleSingleProjectOpen}
            />
            <Pagination
              currentPage={page}
              total={projectsState?.pageData?.total}
              onPageChange={setPage}
              limit={projectsState?.pageData?.perPage}
              position="center"
            />
            {showModalUpdate && (
              <ListTasksModal
                show={showModalUpdate}
                close={handleCloseModal}
                tasks={selectedTasks}
                projectId={selectedRowId}
              />
            )}

            {showUpdateStatus && (
              <UpdateProjectStatusModal
                show={showUpdateStatus}
                close={handleCloseModal}
                selectedProject={selectedProject}
              />
            )}
          </div>
        </div>
      ) : (
        <SingleProject />
      )}
    </>
  );
};

export default ProjectsOverview;
