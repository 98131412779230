import React, { useEffect, useRef, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Filter } from "../../../../ui";
import moment from "moment";
import { checkinsFilterColors, checkinsTypes } from "../../../../../data/types";
import {
  getWeekNumber,
  getWeekNumbersInMonth,
} from "../../../../../utils/textFormat";

const DoughnutChartCheckins = ({
  data = [],
  plugins = [],
  filterOptions = [],
  defaultFilter = 2,
  chartType = "your",
  barChartData = { labels: [], data: [] },
  avgData = [], //[{period: 2024, avg: 4, most: 5},...]
  onFilterChange = () => {},
  onChartFilterChange = () => {},
  onChartHover = () => {},
}) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const barchartRef = useRef(null);

  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const [selectedChartFilter, setSelectedChartFilter] = useState();
  const [chartFilters, setChartFilters] = useState([]);
  const [isBarChartShown, setIsBarChartShown] = useState(false);
  const [barChartCoordinates, setBarChartCoordinates] = useState({
    x: null,
    y: null,
  });
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const labels = [];

  const datasets = data?.map((dataObject) => {
    return {
      abel: "My data",
      data: dataObject,
      backgroundColor: checkinsFilterColors,
      borderColor: ["#fff"],
      color: "#fff",
      hoverOffset: 10,
    };
  });

  const options = {
    plugins: {
      datalabels: {
        color: "#fff",
      },
      legend: {
        labels: {
          display: false,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const chartData = {
    labels,
    datasets,
  };

  const barchartHeight = 100 + barChartData?.data?.length * 20;

  const grades = [
    { grade: 1, name: "not-well" },
    { grade: 2, name: "not-ok" },
    { grade: 3, name: "ok" },
    { grade: 4, name: "good" },
    { grade: 5, name: "amazing" },
  ];

  useEffect(() => {
    switch (selectedFilter) {
      case 1:
        setChartFilters(yearsChartFilters());
        break;
      case 2:
        setChartFilters(moment.months().reverse());
        break;
      case 3:
        setChartFilters(
          getWeekNumbersInMonth(new Date().getFullYear(), new Date().getMonth())
            .map((week) => `Week ${week}`)
            ?.reverse()
        );
        break;
      default:
        break;
    }
    onFilterChange(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    switch (selectedFilter) {
      case 1:
        handleChartFilterClick(new Date().getFullYear().toString());
        break;
      case 2:
        handleChartFilterClick(
          moment.months().find((_, index) => index === new Date().getMonth())
        );
        break;
      case 3:
        handleChartFilterClick(`Week ${getWeekNumber(new Date())}`);
        break;
      default:
        break;
    }
  }, [chartFilters, selectedFilter]);

  useEffect(() => {
    onChartFilterChange(selectedChartFilter);
  }, [selectedChartFilter]);

  useEffect(() => {
    onChartHover(hoveredIndex);
  }, [hoveredIndex]);

  const yearsChartFilters = () => {
    const currentYear = moment().year();
    return Array.from({ length: 13 }, (_, index) =>
      (currentYear - 12 + index).toString()
    ).reverse();
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handleResetFilter = () => {
    setSelectedFilter(defaultFilter);
  };

  const handleChartFilterClick = (value) => {
    setSelectedChartFilter(value);
  };

  const currentAvgDescription = () => {
    if (chartType === "team") {
      return "My Team";
    }
    if (chartType === "company") {
      return "Company-wide";
    }
  };

  const isAvgRendered = () => {
    if (chartType === "your" || chartType === "teams") {
      return false;
    } else {
      return true;
    }
  };

  const handleHover = (e, elements) => {
    if (elements.length > 0) {
      const element = elements[0];
      const datasetIndex = element.datasetIndex;
      const index = element.index;
      // const dataset = data.datasets[datasetIndex];
      // const label = data.labels[index];
      // const value = dataset.data[index];

      setIsBarChartShown(true);
      setBarChartCoordinates({ x: e.x, y: e.y });
      setHoveredIndex(index + 1);
    } else {
      setIsBarChartShown(false);
      setBarChartCoordinates({ x: null, y: null });
      setHoveredIndex(null);
    }
  };

  const renderMostly = (chartFillter) => {
    const periodData = avgData?.find((data) => data?.period === chartFillter);
    if (!!periodData) {
      const grade = grades?.find(
        (grade) => grade?.grade === periodData?.most
      )?.name;
      if (!!grade) {
        return <img src={require(`../images/${grade}.png`)} alt={grade} />;
      }
      return null;
    }
    return null;
  };

  const renderAvg = (chartFillter) => {
    const periodData = avgData?.find((data) => data?.period === chartFillter);
    if (!!periodData) {
      const grade = grades?.find(
        (grade) => grade?.grade === periodData?.avg
      )?.name;
      if (!!grade) {
        return (
          <div className="icon-wrapper">
            <img src={require(`../images/${grade}.png`)} alt={grade} />
          </div>
        );
      }
      return null;
    }
    return null;
  };

  return (
    <div className="doughnut-chart">
      <div className="title-line">
        <h4 className="title">Mood statistics</h4>
        <Filter
          filters={filterOptions}
          onRadioChange={(e) => handleFilterChange(parseInt(e?.target?.value))}
          onReset={handleResetFilter}
          checked={selectedFilter}
        />
      </div>
      <div className="chart-wrapper">
        <div className="avg">
          <p className="list-title">Avg. Check-in</p>
          <div className="avg-list">
            {chartFilters?.map((filter, index) => (
              <div
                key={`option-${index}`}
                className={`filter-option ${
                  filter === selectedChartFilter ? "active" : ""
                }`}
                onClick={() => handleChartFilterClick(filter)}
              >
                <div className="flex gap-5 items-center">
                  <div
                    className="dot"
                    style={{ backgroundColor: checkinsFilterColors[index] }}
                  />
                  <div className="filter-line">
                    <p>{filter}</p>
                    <div className="flex gap-5">
                      <p className="details">Mostly</p>
                      {renderMostly(filter)}
                    </div>
                  </div>
                </div>
                {renderAvg(filter)}
              </div>
            ))}
          </div>
        </div>
        <div className="doughnut-wrapper">
          <div className="legend">
            {checkinsTypes?.map((type, index) => (
              <div key={`legend-${index}`} className="legend-item">
                <img
                  src={require(`../images/${type?.imageName}.png`)}
                  alt={type?.imageName}
                />
                <div
                  className="legend-color"
                  style={{ backgroundColor: checkinsFilterColors[index] }}
                />
              </div>
            ))}
          </div>
          <Doughnut
            data={chartData}
            options={
              chartType === "teams"
                ? {
                    ...options,
                    onHover: (e, elements) => handleHover(e, elements),
                  }
                : options
            }
            plugins={plugins}
          />
          <div
            ref={barchartRef}
            className="barchart-wrapper"
            style={{
              top: `${barChartCoordinates.y}px`,
              left: `${barChartCoordinates.x}px`,
              visibility: isBarChartShown ? "visible" : "hidden",
              height: barchartHeight,
            }}
          >
            {!!hoveredIndex && (
              <div className="bar-title">
                <img
                  src={require(`../images/${
                    checkinsTypes?.find((type) => type.value === hoveredIndex)
                      ?.imageName
                  }.png`)}
                  alt="emoji"
                />
              </div>
            )}
            <Bar
              data={{
                labels: barChartData.labels,
                datasets: [
                  {
                    data: barChartData.data,
                  },
                ],
              }}
              options={{
                indexAxis: "y",
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      {isAvgRendered() && (
        <div className="current-avg">
          <div className="block">
            <h4>Current avg.</h4>
            <p>{currentAvgDescription()}</p>
          </div>
          <div className="icon-wrapper">
            <img src={require("../images/amazing.png")} alt="Amazing" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DoughnutChartCheckins;
