import { Routes, Route } from "react-router-dom";
import Chat from "../components/ChatNew/Chat";
import Support from "../components/Support/Support";
import Profile from "../components/Profile/Profile";
import Settings from "../components/Settings/Settings";
import Goals from "../components/Goals/Goals";
import CareerPath from "../components/CareerPath";
import Projects from "../components/Projects";
import Survey from "../components/Survey/Survey";
import Career from "../components/Career";
import Login from "../components/Login/Login";

import AdministrationRoutes from "./AdministrationRoutes";
import CareerPathRoutes from "./CareerPathRoutes";
import YourCareerRoutes from "./YourCareerRoutes";
import ProjectsRoutes from "./ProjectsRoutes";
import SurveyRoutes from "./SurveyRoutes";
import ClientSurveyRoutes from "./ClientSurveyRoutes";
import ReportsRoutes from "./ReportsRoutes";
import PersonalPerformanceRoutes from "./PersonalPerformanceRoutes";
import MeetingsRoutes from "./MeetingsRoutes";
import AdminProtectedRoute from "./AdminProtectedRoute";

const AppRouter = ({ localUserData }) => (
  <Routes>
    <Route path="/" element={<CareerPath />} />
    <Route
      path="/chat/:roomIdParam?/:roomNameParam?/:bidParam?"
      element={<Chat />}
    />
    <Route path="goal" element={<Goals />} />
    <Route path="profile" element={<Profile />} />
    <Route path="meetings/*" element={<MeetingsRoutes />} />

    <Route path="support" element={<Support />} />
    <Route path="settings" element={<Settings />} />
    <Route path="projects" element={<Projects />} />
    <Route path="survey" element={<Survey />} />
    <Route path="career" element={<Career />} />
    <Route path="login" element={<Login />} />
    <Route
      path="personal-performance/*"
      element={<PersonalPerformanceRoutes />}
    />
    <Route
      element={
        <AdminProtectedRoute isAllowed={!!localUserData?.permissions?.length} />
      }
    >
      <Route path="administration/*" element={<AdministrationRoutes />} />
    </Route>
    <Route
      element={
        <AdminProtectedRoute isAllowed={!!localUserData?.permissions?.length} />
      }
    >
      <Route path="career-path/*" element={<CareerPathRoutes />} />
    </Route>

    <Route index path="your-career/*" element={<YourCareerRoutes />} />
    <Route path="projects/*" element={<ProjectsRoutes />} />
    <Route path="survey/*" element={<SurveyRoutes />} />
    <Route path="user-surveys/*" element={<ClientSurveyRoutes />} />
    <Route path="reports/*" element={<ReportsRoutes />} />
    <Route path="*" element={<p>Not Found</p>} />
  </Routes>
);

export default AppRouter;
