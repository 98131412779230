import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchInterests = createAsyncThunk("/interests/fetch", async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/interests`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const createInterest = createAsyncThunk(
  "/interests/create",
  async (interest) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/interest`, interest);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateInterest = createAsyncThunk(
  "/interests/update",
  async (interest) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/interest/${interest.id}`,
        interest
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteInterest = createAsyncThunk(
  "/interests/delete",
  async (interestId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/interest/${interestId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
