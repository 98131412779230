import React, { useState, useEffect } from "react";

const ChartFilterOption = ({
  option = "",
  color = "",
  onClick = () => {},
  multiselect,
  isSelected = false,
}) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const handleClick = () => {
    if (multiselect) {
      setSelected(!selected);
    } else {
      setSelected(isSelected);
    }
    onClick(option);
  };

  return (
    <div
      className={`single-filter ${selected ? "selected" : ""}`}
      style={{
        backgroundColor: selected ? color : "transparent",
      }}
      onClick={handleClick}
    >
      {option}
    </div>
  );
};

export default ChartFilterOption;
