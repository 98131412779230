import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchResponsibilities = createAsyncThunk(
  "/responsibilities/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/responsibilities`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createResponsibility = createAsyncThunk(
  "/responsibilities/create",
  async (responsibility) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/responsibility`,
        responsibility
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateResponsibility = createAsyncThunk(
  "/responsibilities/update",
  async (responsibility) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/responsibility/${responsibility.id}`,
        responsibility
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteResponsibility = createAsyncThunk(
  "/responsibilities/delete",
  async (responsibilityId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/responsibility/${responsibilityId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
