import { CalendarIcon } from "../../../../icons";
import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import { Form, Input, Modal } from "../../../ui";
import { seniorityTypes } from "../../../../data/types";
import DatePicker from "react-datepicker";
import { addMonths, subMonths } from "../../../../utils/textFormat";

const EmployeeForm = ({
  formType,
  employeesData,
  positionsState,
  teamsState,
  handleSave,
  setUserBirthday,
  handleChange,
  showModal,
  handleModalClose,
}) => {
  return (
    <Modal
      show={showModal}
      handleClose={handleModalClose}
      title={formType === "create" ? "Add Employee" : "Edit Employee"}
      handleConfirm={handleSave}
      disabledConfirm={
        !employeesData?.levelOfSeniority ||
        !employeesData?.timeInPositionYears ||
        !employeesData?.experienceYears
      }
      confirmButtonLabel="Save"
      rejectButtonLabel="Close"
    >
      <Form>
        <div className="form-group">
          <Input
            type="input"
            label="First name"
            placeholder="Enter first name"
            value={employeesData?.firstName || ""}
            handleChange={handleChange}
            name="firstName"
          />
        </div>
        <div className="form-group">
          <Input
            type="input"
            label="Last name"
            placeholder="Enter last name"
            value={employeesData?.lastName || ""}
            handleChange={handleChange}
            name="lastName"
          />
        </div>
        <div className="form-group">
          <Input
            type="email"
            label="Email"
            placeholder="Enter email"
            value={employeesData?.email || ""}
            handleChange={handleChange}
            name="email"
          />
        </div>
        {formType === "create" && (
          <div className="form-group">
            <Input
              type="password"
              label="Password"
              placeholder="Enter password"
              handleChange={handleChange}
              name="password"
              value={employeesData?.password || ""}
            />
          </div>
        )}
        {/* <div className="form-group">
                <label>Image</label>
                <div className="custom-input">
                  <Input
                    type="file"
                    placeholder="Select a profile image to upload"
                    name="image"
                    handleChange={changeHandler}
                  />
                  <span>
                    {existingImage?.title ||
                      employeesData?.image ||
                      "Select a profile image to upload"}
                  </span>
                </div>
              </div> */}
        <div className="form-group">
          <Input
            label="Phone"
            placeholder="Enter phone"
            value={employeesData?.phone || ""}
            handleChange={handleChange}
            name="phone"
          />
        </div>
        <div className="date-picker-holder">
          <label>Birthday</label>
          <DatePicker
            onChange={setUserBirthday}
            dateFormat="dd.MM.yyyy"
            dateFormatCalendar={"MMM yyyy"}
            placeholderText="Enter date of birth"
            minDate={subMonths(new Date(), 840)}
            maxDate={addMonths(new Date(), 0)}
            showMonthDropdown
            showYearDropdown
            value={employeesData?.birthday}
          />
          <CalendarIcon />
        </div>
        <div className="form-group" style={{ marginBottom: 12 }}>
          <label>Job position</label>
          <DropdownSelect
            options={positionsState?.map((position) => ({
              value: position?.id,
              label: position?.title,
            }))}
            handleSelect={handleChange}
            type="primary"
            name="positionId"
            background="dark"
            preselected={{
              value: parseInt(employeesData?.positionId),
              label: positionsState?.find(
                (type) => type.id === parseInt(employeesData?.positionId)
              )?.label,
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: 12 }}>
          <label>Team</label>
          <DropdownSelect
            options={teamsState?.optionData?.map((team) => ({
              value: team?.id,
              label: team?.name,
            }))}
            handleSelect={handleChange}
            type="primary"
            name="teamId"
            background="dark"
            preselected={{
              value: parseInt(employeesData?.teamId),
              label: teamsState?.optionData?.find(
                (type) => type.id === parseInt(employeesData?.teamId)
              )?.label,
            }}
          />
        </div>
        {/* {formType === "edit" && !employeesData ? (
                <></>
              ) : (
                <div className="form-group">
                  <label>Active</label>
                  <Select
                    menuPlacement="top"
                    name="active"
                    placeholder="Select Active"
                    options={userActiveValues}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(event) => handleChange(event, "active")}
                    defaultValue={userActiveValues?.find(
                      (item) => item?.value === employeesData?.active
                    )}
                  />
                </div>
              )} */}
        <div className="form-group">
          <Input
            type="number"
            label="Experience In Years"
            placeholder="Enter experience"
            name="experienceYears"
            value={employeesData?.experienceYears || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group">
          <Input
            type="number"
            label="Time In Position"
            placeholder="Enter time in position"
            name="timeInPositionYears"
            value={employeesData?.timeInPositionYears || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Level of seniority</label>
          <DropdownSelect
            options={seniorityTypes}
            handleSelect={handleChange}
            type="primary"
            name="levelOfSeniority"
            background="dark"
            preselected={{
              value: parseInt(employeesData?.levelOfSeniority),
              label: seniorityTypes.find(
                (type) =>
                  type.value === parseInt(employeesData?.levelOfSeniority)
              )?.label,
            }}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default EmployeeForm;
