import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTemplates,
  createTemplate,
  deleteTemplate,
  fetchSingleTemplate,
  fetchDrafts,
  fetchAssignedReviews,
  fetchCompletedReviews,
} from "./performanceThunks";

const performanceSlice = createSlice({
  name: "performance",
  initialState: {
    templates: [],
    drafts: [],
    reviews: {
      assigned: [],
      completed: [],
    },
    singleTemplate: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    clearSingleTemplate: (state) => {
      state.singleTemplate = null;
    },
    setPerformanceMessage: (state, action) => {
      state.message = action.payload;
    },
    resetPerformanceMessages: (state) => {
      state.message = null;
    },
    clearAssignedReviews: (state) => {
      state.reviews.assigned = [];
    },
    clearCompletedReviews: (state) => {
      state.reviews.completed = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.templates = action.payload.personal_performances;
    });
    builder.addCase(createTemplate.fulfilled, (state, action) => {
      state.templates = [...state?.templates, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Template successfully created",
      };
    });
    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      const filteredTemplates = state.templates.filter(
        (template) => template.id !== action.meta.arg
      );
      const filteredDrafts = state.drafts.filter(
        (draft) => draft.id !== action.meta.arg
      );
      state.templates = filteredTemplates;
      state.drafts = filteredDrafts;
      state.message = {
        type: "success",
        title: "Success",
        message: "Template successfully deleted",
      };
    });
    builder.addCase(fetchSingleTemplate.fulfilled, (state, action) => {
      state.singleTemplate = action.payload;
    });
    builder.addCase(fetchDrafts.fulfilled, (state, action) => {
      state.drafts = action.payload.personal_performances;
    });
    builder.addCase(fetchAssignedReviews.fulfilled, (state, action) => {
      state.reviews.assigned = action.payload;
    });
    builder.addCase(fetchCompletedReviews.fulfilled, (state, action) => {
      state.reviews.completed = action.payload;
    });
  },
});

export const {
  clearSingleTemplate,
  setPerformanceMessage,
  resetPerformanceMessages,
  clearAssignedReviews,
  clearCompletedReviews,
} = performanceSlice.actions;
export const performanceReducer = performanceSlice.reducer;
