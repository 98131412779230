const GreenCircleArrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="1 -1 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.416 1.42652e-06C17.4911 1.16097e-06 22.416 4.92487 22.416 11C22.416 17.0751 17.4911 22 11.416 22C5.34088 22 0.416015 17.0751 0.416015 11C0.416015 4.92487 5.34088 1.69208e-06 11.416 1.42652e-06Z"
      fill="#A9CE5B"
    />
    <path
      d="M6.41602 9.02789L9.74935 9.02789L9.74935 18.0469L13.0827 18.0469L13.0827 9.02789L16.416 9.02789L11.366 3.61649L6.41602 9.02789Z"
      fill="white"
    />
  </svg>
);

export default GreenCircleArrowIcon;
