import { useEffect, useState } from "react";

import {
  fetchSkills,
  createSkill,
  updateSkill,
  deleteSkill,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { Pagination, Table } from "../../../ui";
import SkillsModal from "./SkillsModal";
import { resetSkillMessage } from "../../../../store/skills/skillsSlice";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

const Skills = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;

  const [page, setPage] = useState(1);

  const [doFetchSkills] = useThunk(fetchSkills);
  const [doDeleteSkill] = useThunk(deleteSkill);
  const [doCreateSkill, isLoading] = useThunk(createSkill);
  const [doUpdateSkill] = useThunk(updateSkill);
  const [doShowToastMessage] = useToastMessage(resetSkillMessage);

  const skillsState = useSelector((state) => state.skills)?.skills;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state?.skills)?.message;
  const formTypeState = useSelector((state) => state?.form)?.type;

  useEffect(() => {
    if (!isLoading) {
      doFetchSkills({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchSkills]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const tableData = {
    rows: skillsState?.map((skill) => ({
      ...skill,
    })),
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
    ],
  };

  const handleEdit = (skill) => {
    dispatch(setFormType("edit"));
    dispatch(setFormData(skill));
    dispatch(setActiveModal("Skills-modal"));
  };
  const handleDelete = (skill) => {
    doDeleteSkill(skill?.id);
  };
  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSave = (skill) => {
    if (formTypeState === "edit") {
      doUpdateSkill(skill);
      handleModalClose();
      return;
    }
    doCreateSkill(skill);
    handleModalClose();
  };
  return (
    <div>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        hasActions={true}
      />

      {activeModalState === "Skills-modal" ? (
        <SkillsModal
          showModal={activeModalState === "Skills-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Skills;
