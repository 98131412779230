import "./styles.scss";
import { OutsideClick } from "..";
import { useEffect, useState } from "react";
import { CloseIcon } from "../../../icons";

const TypeAhead = ({
  name = "",
  label = "",
  placeholder = "Search...",
  icon = "",
  variant = "primary",
  labelClass = "",
  className = "",
  handleSelect = () => {},
  onRemove = () => {},
  handleSearch,
  slug,
  options,
  disabled = false,
  multiple = false,
  selectionColors = [],
  preselected = [],
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (!!preselected?.length && !selected.length) {
      setSelected(preselected);
    }
  }, [preselected]);

  useEffect(() => {
    if (searchTerm) {
      setIsShow(true);
      handleSearch(searchTerm);
    } else {
      if (!multiple) {
        handleClose();
      } else if (multiple && !searchTerm) {
        setIsShow(false);
      }
    }
  }, [searchTerm]);

  const handleValueChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClose = () => {
    setSearchTerm("");
    setIsShow(false);
  };

  const handleRemove = (index) => {
    const updatedSelected = selected.filter((_, i) => i !== index);
    setSelected(updatedSelected);
    onRemove(updatedSelected);
  };

  const handleSelectedItem = (option) => {
    if (multiple) {
      handleSelect([...selected, option]);
      setSelected([...selected, option]);
    } else {
      handleSelect(option);
      setSelected([option]);
      handleClose();
    }
    handleClose();
  };

  const handleRemoveSelected = () => {
    setSelected([]);
    handleSelect(null);
  };

  return (
    <OutsideClick onOutsideClick={handleClose}>
      <div className="typeahead-wrapper">
        {label ? <label className={labelClass}>{label}</label> : ""}
        {!!icon && icon}
        <input
          name={name}
          type="text"
          className={`${variant} ${icon ? "icon-input" : ""} ${className}`}
          placeholder={placeholder}
          onChange={handleValueChange}
          value={
            !multiple && selected?.length
              ? `${selected[0]?.first_name} ${selected[0]?.last_name}`
              : searchTerm
          }
          disabled={disabled}
        />
        {isShow && (
          <>
            <div className="typeahead-search">
              {!options?.length ? (
                <div className="typeahead-no-data">No matches found</div>
              ) : (
                options?.map((option, index) => (
                  <div
                    className="typeahead-item"
                    key={index}
                    onClick={() => {
                      handleSelectedItem(option);
                    }}
                  >
                    {typeof slug === "function" ? slug(option) : option[slug]}
                  </div>
                ))
              )}
            </div>
            <div className="typeahead-close" onClick={handleClose}>
              <CloseIcon fill="black" />
            </div>
          </>
        )}
        {!multiple && selected?.length ? (
          <div className="typeahead-close" onClick={handleRemoveSelected}>
            <CloseIcon fill="black" />
          </div>
        ) : (
          ""
        )}
      </div>

      {selected && multiple && (
        <div className="typeahead-selected">
          {selected.map((itemSelected, index) => (
            <div
              key={index}
              className={`typeahead-selected-item ${selectionColors[index]?.name}`}
            >
              <p>
                {typeof slug === "function"
                  ? slug(itemSelected)
                  : itemSelected[slug]}
              </p>
              <div
                className="typeahead-selected-remove"
                onClick={() => handleRemove(index)}
              >
                <CloseIcon fill="black" />
              </div>
            </div>
          ))}
        </div>
      )}
    </OutsideClick>
  );
};

export default TypeAhead;
