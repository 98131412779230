import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMeetings,
  fetchSingleMeeting,
  createMeeting,
  updateMeeting,
  deleteMeeting,
  fetchMeetingsStatistics,
  fetchMeetingsPriority,
  fetchTalkingPoints,
  fetchMeetingsTypeStatistics,
  fetchLeaderboardAmount,
} from "./meetingsThunks";

const meetingsSlice = createSlice({
  name: "meetings",
  initialState: {
    allMeetings: [],
    meetingsStatistics: null,
    meetingsPriority: [],
    talkingPoints: [],
    typeStatistics: [],
    leaderboardAmount: [],
    singleMeeting: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetMeetingsMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchMeetings.fulfilled, (state, action) => {
      state.allMeetings = action.payload;
    });
    builder.addCase(fetchSingleMeeting.fulfilled, (state, action) => {
      state.singleMeeting = action.payload[0];
    });
    builder.addCase(createMeeting.fulfilled, (state, action) => {
      state.allMeetings = [...state.allMeetings, ...action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Meeting successfully created",
      };
    });
    builder.addCase(updateMeeting.fulfilled, (state, action) => {
      const updatedState = state.allMeetings.map((meeting) => {
        return meeting.id === action.payload.id ? action.payload : meeting;
      });
      state.allMeetings = updatedState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Meeting successfully updated",
      };
    });
    builder.addCase(deleteMeeting.fulfilled, (state, action) => {
      const filteredMeetings = state.allMeetings.filter(
        (meeting) => meeting.id !== action.meta.arg
      );
      state.allMeetings = filteredMeetings;
      state.message = {
        type: "success",
        title: "Success",
        message: "Meeting successfully deleted",
      };
    });
    builder.addCase(fetchMeetingsStatistics.fulfilled, (state, action) => {
      state.meetingsStatistics = action.payload;
    });
    builder.addCase(fetchMeetingsPriority.fulfilled, (state, action) => {
      state.meetingsPriority = action.payload;
    });
    builder.addCase(fetchTalkingPoints.fulfilled, (state, action) => {
      state.talkingPoints = action.payload;
    });
    builder.addCase(fetchMeetingsTypeStatistics.fulfilled, (state, action) => {
      state.typeStatistics = action.payload;
    });
    builder.addCase(fetchLeaderboardAmount.fulfilled, (state, action) => {
      state.leaderboardAmount = action.payload;
    });
  },
});

export const { resetMeetingsMessage } = meetingsSlice.actions;

export const meetingsReducer = meetingsSlice.reducer;
