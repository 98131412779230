export const dummyActivities = {
  conferenceAttendance: [
    {
      id: 1,
      topics: ["Ecology in IT industry", "IT have no trash", "Led lightbulbs"],
      title: "Ecology conferencee",
      location: "Online",
      startDate: "31.08.2024",
      endDate: "31.08.2024",
      speakers: ["Petar Petrovic", "Marko Markovic", "Jova Jovicic"],
      notes: "Ask smart questions",
    },
    {
      id: 2,
      topics: ["Ecology in IT industry", "IT have no trash", "Led lightbulbs"],
      title: "Ecology conferencee",
      location: "Online",
      startDate: "31.08.2024",
      endDate: "31.08.2024",
      speakers: ["Petar Petrovic", "Marko Markovic", "Jova Jovicic"],
      notes: "Ask smart questions",
    },
    {
      id: 3,
      topics: ["Ecology in IT industry", "IT have no trash", "Led lightbulbs"],
      title: "Ecology conferencee",
      location: "Online",
      startDate: "31.08.2024",
      endDate: "31.08.2024",
      speakers: ["Petar Petrovic", "Marko Markovic", "Jova Jovicic"],
      notes: "Ask smart questions",
    },
    {
      id: 4,
      topics: ["Ecology in IT industry", "IT have no trash", "Led lightbulbs"],
      title: "Ecology conferencee",
      location: "Online",
      startDate: "31.08.2024",
      endDate: "31.08.2024",
      speakers: ["Petar Petrovic", "Marko Markovic", "Jova Jovicic"],
      notes: "Ask smart questions",
    },
    {
      id: 5,
      topics: ["Global economic growth", "How to trick people to buy"],
      title: "Economy Spetialists",
      location: "Belgrade, Serbia",
      startDate: "27.08.2024",
      endDate: "31.08.2024",
      speakers: ["Milos Petrovic", "Jova Markovic", "Marko Jovicic"],
      notes: "Ask stuped questions",
    },
    {
      id: 6,
      topics: ["Global economic growth", "How to trick people to buy"],
      title: "Economy Spetialists",
      location: "Belgrade, Serbia",
      startDate: "27.08.2024",
      endDate: "31.08.2024",
      speakers: ["Milos Petrovic", "Jova Markovic", "Marko Jovicic"],
      notes: "Ask stuped questions",
    },
    {
      id: 7,
      topics: ["Global economic growth", "How to trick people to buy"],
      title: "Economy Spetialists",
      location: "Belgrade, Serbia",
      startDate: "27.08.2024",
      endDate: "31.08.2024",
      speakers: ["Milos Petrovic", "Jova Markovic", "Marko Jovicic"],
      notes: "Ask stuped questions",
    },
    {
      id: 8,
      topics: ["Global economic growth", "How to trick people to buy"],
      title: "Economy Spetialists",
      location: "Belgrade, Serbia",
      startDate: "27.08.2024",
      endDate: "31.08.2024",
      speakers: ["Milos Petrovic", "Jova Markovic", "Marko Jovicic"],
      notes: "Ask stuped questions",
    },
  ],
  workshopSeminars: [
    {
      id: 1,
      type: "Workshop",
      speakers: ["Milos Jovanovic", "Stamenko Perovic", "Pera Milosevic"],
      title: "AI showcase seminar",
      host: "OpenAI",
      location: "Belgrade, Serbia",
      startDate: "05.08.2024",
      endDate: "05.08.2024",
      topics: [
        "Presenting",
        "Creating visuals",
        "Better thinking",
        "Data Stealing",
      ],
    },
    {
      id: 2,
      type: "Seminar",
      speakers: ["Jovan Milosevic", "Petar Stamenkovic", "Milos Perovic"],
      title: "IT Without AI",
      host: "CloseAI",
      location: "Online",
      startDate: "10.08.2024",
      endDate: "15.08.2024",
      topics: ["Presenting", "Better thinking", "Data keeping"],
    },
    {
      id: 3,
      type: "Workshop",
      speakers: ["Milos Jovanovic", "Stamenko Perovic", "Pera Milosevic"],
      title: "AI showcase seminar",
      host: "OpenAI",
      location: "Belgrade, Serbia",
      startDate: "05.08.2024",
      endDate: "05.08.2024",
      topics: [
        "Presenting",
        "Creating visuals",
        "Better thinking",
        "Data Stealing",
      ],
    },
    {
      id: 4,
      type: "Seminar",
      speakers: ["Jovan Milosevic", "Petar Stamenkovic", "Milos Perovic"],
      title: "IT Without AI",
      host: "CloseAI",
      location: "Online",
      startDate: "10.08.2024",
      endDate: "15.08.2024",
      topics: ["Presenting", "Better thinking", "Data keeping"],
    },
  ],
  publicSpeaking: [
    {
      id: 1,
      topics: ["Product detail", "Grwoth", "Version 2.0"],
      title: "Product presentation",
      location: "Online",
      startDate: "06.08.2024",
      endDate: "06.08.2024",
      notes: "Don't mess this",
    },
    {
      id: 2,
      topics: ["Products", "Grwoth", "Finances"],
      title: "Company policy",
      location: "Belgrade, Serbia",
      startDate: "20.08.2024",
      endDate: "21.08.2024",
      notes: "Don't mess this eather",
    },
    {
      id: 3,
      topics: ["Product detail", "Grwoth", "Version 2.0"],
      title: "Product presentation",
      location: "Online",
      startDate: "06.08.2024",
      endDate: "06.08.2024",
      notes: "Don't mess this",
    },
    {
      id: 4,
      topics: ["Products", "Grwoth", "Finances"],
      title: "Company policy",
      location: "Belgrade, Serbia",
      startDate: "20.08.2024",
      endDate: "21.08.2024",
      notes: "Don't mess this eather",
    },
  ],
  panelDiscussions: [
    {
      id: 1,
      panelists: ["Predrag Nenadovic", "Jovan Pericic", "Milos Zivkovic"],
      title: "Science Fair",
      location: "Online",
      startDate: "15.08.2024",
      endDate: "19.08.2024",
      topics: ["Science", "Technology", "AI"],
    },
    {
      id: 2,
      panelists: ["Nenad Predragic", "Pera Jovanovic", "Zivko Milosevic"],
      title: "Technology Fair",
      location: "Moscow, Russia",
      startDate: "25.08.2024",
      endDate: "25.08.2024",
      topics: ["Science", "Technology", "AI"],
    },
  ],
  professionalOrganizations: [
    {
      id: 1,
      benefits: ["Digital laboratory", "Background security"],
      title: "Red Hat",
      membershipLevel: "Pro",
      ongoing: true,
      endDate: null,
      startDate: "01.06.2024",
      activities: "Hacking the world",
    },
    {
      id: 2,
      benefits: ["Digital laboratory", "Background security"],
      title: "Blue Hat",
      membershipLevel: "Medior",
      ongoing: true,
      endDate: null,
      startDate: "01.06.2024",
      activities: "Securing the world",
    },
  ],
  committeeInvolvement: [
    {
      id: 1,
      ongoing: true,
      title: "IT Strategy Committee (ITSC)",
      organizationName: "Berkeley IT",
      role: "Roller",
      startDate: "01.08.2024",
      endDate: null,
      responsibilities:
        "- Recommend IT Resource Needs and Distribution\n- Manage IT Service Strategy\n- Facilitate IT Collaboration and Best Practices",
      achievements: "Award for best recommander",
    },
    {
      id: 2,
      ongoing: false,
      title: "Data Governance Committee (DGC)",
      organizationName: "Berkeley IT",
      role: "Roller",
      startDate: "01.05.2024",
      endDate: "01.08.2024",
      responsibilities:
        "- Recommend IT Resource Needs and Distribution\n- Manage IT Service Strategy\n- Facilitate IT Collaboration and Best Practices",
      achievements: "Award for best recommander",
    },
  ],
  advancedDegrees: [
    {
      id: 1,
      ongoing: false,
      title: "Master React programming",
      institution: "Udemy",
      field: "IT",
      startDate: "01.02.2019",
      endDate: "01.02.2022",
      courses:
        "- React basics\n- React intermediate classes\n- React Advanced\n- Master React",
    },
    {
      id: 2,
      ongoing: false,
      title: "Master C# programming",
      institution: "YouTube",
      field: "IT",
      startDate: "01.05.2023",
      endDate: "05.05.2023",
      courses:
        "- C# basics\n- C# intermediate classes\n- C# Advanced\n- Master C#",
    },
    {
      id: 3,
      ongoing: true,
      title: "Master C++ programming",
      institution: "Skyline",
      field: "IT",
      startDate: "01.08.2024",
      endDate: null,
      courses:
        "- C++ basics\n- C++ intermediate classes\n- C++ Advanced\n- Master C++",
    },
  ],
  certificationPrograms: [
    {
      id: 1,
      ongoing: false,
      title: "C programming language",
      institution: "Skysoft",
      field: "IT ",
      startDate: "01.08.2024",
      endDate: "01.09.2013",
      requirements: "Knowing anything about C language",
      benefits: "Knowing C language",
    },
  ],
};

export const dummuMeetings = [
  {
    id: 1,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 1, //One on one
  },
  {
    id: 2,
    meetWidth: {
      userId: 168,
      name: "Matko Markovic",
      position: "Junior PHP Dev",
    },
    type: 2, // In person
    scheduled: "07.07.2024",
    meetingType: 1, //One on one
  },
  {
    id: 3,
    meetWidth: {
      userId: 118,
      name: "Aleksandar Vasic",
      position: "Medior React Dev",
    },
    type: 1, // online
    scheduled: "10.07.2024",
    meetingType: 1, //One on one
  },
  {
    id: 4,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 1, //One on one
  },
  {
    id: 5,
    meetWidth: {
      userId: 172,
      name: "Pavle Pavlovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 1, //One on one
  },
  {
    id: 6,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 2, //One on one
  },
  {
    id: 7,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 2, //One on one
  },
  {
    id: 8,
    meetWidth: {
      userId: 169,
      name: "Jovan Jovanovic",
      position: "Senior Vue Dev",
    },
    type: 1, // online
    scheduled: null,
    meetingType: 2, //One on one
  },
];
