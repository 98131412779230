import { useEffect, useState } from "react";

import {
  fetchMeetingTypes,
  createMeetingType,
  updateMeetingType,
  deleteMeetingType,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { Pagination, Table } from "../../../ui";
import MeetingTypesModal from "./MeetingTypesModal";
import { resetMeetingTypesMessage } from "../../../../store/meetingTypes/meetingTypesSlice";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

const MeetingTypes = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;

  const [page, setPage] = useState(1);

  const [doFetchMeetingTypes] = useThunk(fetchMeetingTypes);
  const [doDeleteMeetingType] = useThunk(deleteMeetingType);
  const [doCreateMeetingType, isLoading] = useThunk(createMeetingType);
  const [doUpdateMeetingType] = useThunk(updateMeetingType);
  const [doShowToastMessage] = useToastMessage(resetMeetingTypesMessage);

  const meetingTypesState = useSelector(
    (state) => state.meetingTypes
  )?.meetingTypes;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state?.meetingTypes)?.message;
  const formTypeState = useSelector((state) => state?.form)?.type;

  useEffect(() => {
    if (!isLoading) {
      doFetchMeetingTypes({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchMeetingTypes]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const tableData = {
    rows: meetingTypesState?.map((meetingType) => ({
      ...meetingType,
    })),
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
    ],
  };

  const handleEdit = (meetingType) => {
    dispatch(setFormType("edit"));
    dispatch(setFormData(meetingType));
    dispatch(setActiveModal("Meeting Types-modal"));
  };
  const handleDelete = (meetingType) => {
    doDeleteMeetingType(meetingType?.id);
  };
  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSave = (meetingType) => {
    if (formTypeState === "edit") {
      doUpdateMeetingType(meetingType);
      handleModalClose();
      return;
    }
    doCreateMeetingType(meetingType);
    handleModalClose();
  };

  return (
    <div>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        hasActions={true}
      />

      {activeModalState === "Meeting Types-modal" ? (
        <MeetingTypesModal
          showModal={activeModalState === "Meeting Types-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default MeetingTypes;
