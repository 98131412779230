import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCommitteeInvolvements,
  fetchCommitteeInvolvement,
  createCommitteeInvolvement,
  updateCommitteeInvolvement,
  deleteCommitteeInvolvement,
} from "./committeeInvolvementThunks";

const committeeInvolvementSlice = createSlice({
  name: "committeeInvolvement",
  initialState: {
    data: [],
    singleCommitteeInvolvement: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetCommitteeInvolvementMessage: (state) => {
      state.message = null;
    },
    resetSingleCommitteeInvolvement: (state) => {
      state.singleCommitteeInvolvement = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCommitteeInvolvements.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchCommitteeInvolvement.fulfilled, (state, action) => {
      state.singleCommitteeInvolvement = action.payload;
    });
    builder.addCase(deleteCommitteeInvolvement.fulfilled, (state, action) => {
      const filteredCommitteeInvolvements = state?.data?.filter(
        (committeeInvolvement) => committeeInvolvement.id !== action.meta.arg
      );
      state.data = filteredCommitteeInvolvements;
      state.message = {
        type: "success",
        title: "Success",
        message: "Committee Involvement successfully deleted",
      };
    });
    builder.addCase(createCommitteeInvolvement.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Committee Involvement successfully created",
      };
    });
    builder.addCase(updateCommitteeInvolvement.fulfilled, (state, action) => {
      state.data = state.data.map((committeeInvolvement) =>
        committeeInvolvement.id === action.payload.id
          ? action.payload
          : committeeInvolvement
      );
      state.message = {
        type: "success",
        title: "Success",
        message: "Committee Involvement successfully updated",
      };
    });
  },
});

export const {
  resetCommitteeInvolvementMessage,
  resetSingleCommitteeInvolvement,
} = committeeInvolvementSlice.actions;

export const committeeInvolvementReducer = committeeInvolvementSlice.reducer;
