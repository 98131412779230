import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchMeetingTypes = createAsyncThunk(
  "meeting-type/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/meeting-types`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createMeetingType = createAsyncThunk(
  "meeting-type/create",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/meeting-type`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateMeetingType = createAsyncThunk(
  "meeting-type/update",
  async (payload) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/meeting-type/${payload?.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteMeetingType = createAsyncThunk(
  "meeting-type/delete",
  async (id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/meeting-type/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
