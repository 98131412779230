import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { DeleteIcon, EditIcon, SingleArrow } from "../../../../../icons";
import { Button } from "../../../../ui";
import ActivityData from "./ActivityData";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  deleteAdvancedDegree,
  deleteCertificationProgram,
  deleteCommitteeInvolvement,
  deleteConferenceAttendance,
  deletePanelDiscussion,
  deleteProfessionalOrganization,
  deletePublicSpeaking,
  deleteWorkshopSeminar,
  fetchAdvancedDegree,
  fetchCertificationProgram,
  fetchCommitteeInvolvement,
  fetchConferenceAttendance,
  fetchPanelDiscussion,
  fetchProfessionalOrganization,
  fetchPublicSpeaking,
  fetchWorkshopSeminar,
} from "../../../../../store";

const ActivityItem = ({ activity, activityItem, setIsModalShown }) => {
  const itemRef = useRef(null);
  const [doDeleteConferenceAttendance] = useThunk(deleteConferenceAttendance);
  const [doDeleteWorkshopSeminar] = useThunk(deleteWorkshopSeminar);
  const [doDeletePublicSpeaking] = useThunk(deletePublicSpeaking);
  const [doDeletePanelDiscussion] = useThunk(deletePanelDiscussion);
  const [doDeleteProfessionalOrganization] = useThunk(
    deleteProfessionalOrganization
  );
  const [doDeleteCommitteeInvolvement] = useThunk(deleteCommitteeInvolvement);
  const [doDeleteAdvancedDegree] = useThunk(deleteAdvancedDegree);
  const [doDeleteCertificationProgram] = useThunk(deleteCertificationProgram);

  const [doGetSingleConferenceAttendance] = useThunk(fetchConferenceAttendance);
  const [doGetSingleWorkshopSeminar] = useThunk(fetchWorkshopSeminar);
  const [doGetSinglePublicSpeaking] = useThunk(fetchPublicSpeaking);
  const [doGetSinglePanelDiscussion] = useThunk(fetchPanelDiscussion);
  const [doGetSingleProfessionalOrganization] = useThunk(
    fetchProfessionalOrganization
  );
  const [doGetSingleCommitteeInvolvement] = useThunk(fetchCommitteeInvolvement);
  const [doGetSingleAdvancedDegree] = useThunk(fetchAdvancedDegree);
  const [doGetSingleCertificationProgram] = useThunk(fetchCertificationProgram);

  const [isExpanded, setIsExpanded] = useState(false);
  const [initialHeight, setInitialHeight] = useState();
  const [minHeight, setMinHeight] = useState(null);

  const handleExpansionClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setInitialHeight(itemRef?.current?.scrollHeight);
  }, [itemRef]);

  useEffect(() => {
    setMinHeight(initialHeight);
  }, [initialHeight]);

  useEffect(() => {
    if (isExpanded) {
      setMinHeight(itemRef?.current?.scrollHeight);
    } else {
      setMinHeight(initialHeight);
    }
  }, [isExpanded]);

  const handleDelete = () => {
    switch (activity) {
      case "workshopSeminars":
        doDeleteWorkshopSeminar(activityItem?.id);
        break;
      case "conferenceAttendances":
        doDeleteConferenceAttendance(activityItem?.id);
        break;
      case "publicSpeakings":
        doDeletePublicSpeaking(activityItem?.id);
        break;

      case "panelDiscussions":
        doDeletePanelDiscussion(activityItem?.id);
        break;
      case "professionalOrganizations":
        doDeleteProfessionalOrganization(activityItem?.id);
        break;
      case "committeeInvolvements":
        doDeleteCommitteeInvolvement(activityItem?.id);
        break;
      case "advancedDegrees":
        doDeleteAdvancedDegree(activityItem?.id);
        break;
      case "certificationPrograms":
        doDeleteCertificationProgram(activityItem?.id);
        break;
      default:
        break;
    }
  };

  const handleEdit = () => {
    switch (activity) {
      case "workshopSeminars":
        doGetSingleWorkshopSeminar(activityItem?.id);
        break;
      case "conferenceAttendances":
        doGetSingleConferenceAttendance(activityItem?.id);
        break;
      case "publicSpeakings":
        doGetSinglePublicSpeaking(activityItem?.id);
        break;
      case "panelDiscussions":
        doGetSinglePanelDiscussion(activityItem?.id);
        break;
      case "professionalOrganizations":
        doGetSingleProfessionalOrganization(activityItem?.id);
        break;
      case "committeeInvolvements":
        doGetSingleCommitteeInvolvement(activityItem?.id);
        break;
      case "advancedDegrees":
        doGetSingleAdvancedDegree(activityItem?.id);
        break;
      case "certificationPrograms":
        doGetSingleCertificationProgram(activityItem?.id);
        break;
      default:
        break;
    }
    setIsModalShown(true);
  };

  return (
    <div
      ref={itemRef}
      className="activity-item"
      style={{ minHeight: minHeight }}
    >
      <div className="row">
        <div className="column">
          <p className="column-title">{activityItem?.title}</p>
          <p>
            {activityItem?.type ||
              activityItem?.topics?.[0]?.title ||
              activityItem?.name ||
              activityItem?.membershipLevel ||
              activityItem?.role ||
              activityItem?.institution ||
              ""}
          </p>
        </div>
        {!!activityItem?.location ? (
          <div className="column">
            {activityItem?.location === "Online" ? "Online" : "In person"}
          </div>
        ) : (
          <div className="column">
            {activityItem?.organizationName || activityItem?.institution}
          </div>
        )}
        <div className="column">
          {activityItem?.startDate === activityItem?.endDate ||
          !activityItem?.endDate
            ? activityItem?.startDate
            : `${activityItem?.startDate} - ${activityItem?.endDate}`}
        </div>
        <div className={`column actions ${isExpanded ? "expanded" : ""}`}>
          <Button variant="action" handleClick={handleEdit}>
            <EditIcon />
          </Button>
          <Button variant="action" handleClick={handleDelete}>
            <DeleteIcon />
          </Button>
          <div className={`drop-btn ${isExpanded ? "expanded-btn" : ""}`}>
            <span className="vertical-line" />
            <Button variant="control" handleClick={handleExpansionClick}>
              <SingleArrow />
            </Button>
          </div>
        </div>
      </div>
      {isExpanded && (
        <ActivityData
          className="row"
          activity={activity}
          activityItem={activityItem}
        />
      )}
    </div>
  );
};

export default ActivityItem;
