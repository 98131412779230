import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  fetchSingleUserTask,
  fetchUsers,
  createGoalSettings,
  updateGoalSettings,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

import { setSelectedGoal } from "../../../../store/goals/goalsSlice";
import { useDispatch } from "react-redux";

import DropdownSelect from "../../../DropdownSelect/DropdownSelect";

import { CalendarIcon } from "../../../../icons";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button } from "../../../ui";
import { taskStatuses } from "../../../../data/statuses";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../utils/textFormat";
import { useNavigate } from "react-router-dom";

const GoalDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [doFetchSingleUserTask] = useThunk(fetchSingleUserTask);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doCreateGoalSettings] = useThunk(createGoalSettings);
  const [doUpdateGoalSettings] = useThunk(updateGoalSettings);
  const goalState = useSelector((state) => state.goals)?.selectedGoal;
  const usersState = useSelector((state) => state.users)?.data?.optionData;

  const [allUsers, setAllUsers] = useState([]);
  const [goalData, setGoalData] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSelectTask = (task) => {
    doFetchSingleUserTask(task.id);
    navigate("/your-career/task-details");
  };

  const handleBack = () => {
    navigate("/your-career/home");
    dispatch(setSelectedGoal(null));
  };

  const privacy = [
    { value: 1, label: "Private" },
    { value: 2, label: "Public" },
  ];

  const progressReminderOptions = [
    { value: 1, label: "Every day" },
    { value: 2, label: "Every 3 days" },
    { value: 3, label: "Every 5 days" },
  ];

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    }
    const reducedUsers = usersState?.map((user) => {
      return {
        value: user.id,
        label: `${user?.first_name} ${user?.last_name}`,
      };
    });
    setAllUsers(reducedUsers);
  }, [usersState]);

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setGoalData({
      ...goalData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (goalState?.settings) {
      const { mentor, ...reducedSettings } = goalState?.settings;
      setGoalData({ ...reducedSettings, mentorId: mentor?.id });
    }
  }, [goalState]);

  const subMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() - months));
  };

  const addMonths = (date, months) => {
    return new Date(date.setMonth(date.getMonth() + months));
  };

  const handleStartDate = (date) => {
    setGoalData({ ...goalData, startDate: formatShortDate(date) });
  };

  const handleEndDate = (date) => {
    setGoalData({ ...goalData, endDate: formatShortDate(date) });
  };

  const preselectedMentor = () => {
    return allUsers?.find((item) => item.value === goalData?.mentorId);
  };

  const preselectedFrequency = () => {
    return progressReminderOptions?.find(
      (item) => item.value === goalData?.frequency
    );
  };

  const handleSaveGoal = () => {
    const payload = {
      data: goalData,
      goalId: goalState?.id,
    };
    if (!goalState?.settings) {
      doCreateGoalSettings(payload);
      dispatch(setSelectedGoal(null));
      navigate("/your-career/home");
      return;
    }
    payload.data = {
      ...payload.data,
      mentorId: payload?.data?.mentor?.id || payload?.data?.mentorId,
    };
    doUpdateGoalSettings(payload);
    navigate("/your-career/home");

    dispatch(setSelectedGoal(null));
  };

  return (
    <div className="career-vision-wrapper">
      <div className="career-header">
        <div className="header-title">
          <span onClick={handleBack}>Career Vision and Growth</span>
          <h2>{goalState?.name}</h2>
        </div>
      </div>
      <div className="career-position-content">
        <div className="position-details">
          <div className="details-header">
            <h3>Goal Details</h3>
          </div>
          <div className="details-block">
            <label>Goal title</label>
            <p>{goalState?.name}</p>
          </div>
          <div className="details-block">
            <label>Goal description</label>
            <p>{goalState?.description}</p>
          </div>
          <div className="details-block">
            <label>Privacy Settings</label>
            <p>
              {privacy?.find((item) => item.value === goalState?.type)?.label}
            </p>
          </div>
          <div className="details-block">
            <label>Tasks</label>
            {!!goalState?.tasks?.length ? (
              <ul>
                {goalState?.tasks.map((task, index) => (
                  <li
                    key={index}
                    className="clickable-list-item status"
                    onClick={() => handleSelectTask(task)}
                  >
                    {task.title}
                    <p>
                      {
                        taskStatuses?.find(
                          (status) => status.id === task?.status
                        )?.label
                      }
                    </p>
                    {/* {task?.status === 100 && <p>Completed</p>} */}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No Tasks</p>
            )}
          </div>
          <div className="details-block">
            <label>Resources</label>
            {!!goalState?.resources?.length ? (
              <ul className="details-resources">
                {goalState?.resources.map((resource, index) => (
                  <li key={index}>
                    <a
                      href={`${process.env.REACT_APP_BASE_API_URL}${resource.path}`}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      {resource.title}
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No Resources</p>
            )}
          </div>
          <div className="details-block">
            <label>Notes about position</label>
            <p>{goalState?.notes}</p>
          </div>
        </div>
        <div className="task-settings">
          <div className="task-settings-wrapper">
            <h3>Goal Settings</h3>

            <div className="form-group">
              <label>Mentor</label>
              <DropdownSelect
                options={allUsers}
                handleSelect={handleChange}
                type="primary"
                name="mentorId"
                preselected={{
                  value: preselectedMentor()?.value,
                  label: preselectedMentor()?.label,
                }}
              />
            </div>
            <div className="form-group date-picker-holder">
              <label>Goal Start</label>
              <DatePicker
                name="startDate"
                selected={convertTextToDate(goalData?.startDate) || ""}
                onChange={(e) => handleStartDate(e)}
                dateFormat="dd.MM.yyyy"
                dateFormatCalendar={"MMM yyyy"}
                placeholderText={"Enter due date"}
                minDate={subMonths(new Date(), 256)}
                maxDate={addMonths(new Date(), 256)}
                value={goalData?.startDate || ""}
                showMonthDropdown
                showYearDropdown
              />
              <CalendarIcon />
            </div>
            <div className="form-group date-picker-holder">
              <label>Goal End</label>
              <DatePicker
                name="endDate"
                selected={convertTextToDate(goalData?.endDate) || ""}
                onChange={handleEndDate}
                dateFormat="dd.MM.yyyy"
                dateFormatCalendar={"MMM yyyy"}
                placeholderText="Enter due date"
                minDate={subMonths(new Date(), 256)}
                maxDate={addMonths(new Date(), 256)}
                value={goalData?.endDate || ""}
                showMonthDropdown
                showYearDropdown
              />

              <CalendarIcon />
            </div>

            <div className="form-group">
              <label>
                How often would you like to be reminded about progress?
              </label>
              <DropdownSelect
                options={progressReminderOptions}
                handleSelect={handleChange}
                type="primary"
                name="frequency"
                preselected={{
                  value: preselectedFrequency()?.value,
                  label: preselectedFrequency()?.label,
                }}
              />
            </div>
            <div className="task-settings-footer">
              <Button
                handleClick={handleSaveGoal}
                disabled={
                  !goalData?.startDate ||
                  !goalData?.endDate ||
                  !goalData?.frequency
                }
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalDetails;
