import { useState } from "react";
import TasksUpdateModal from "./TasksUpdateModal";
import { Modal, Table, ProgressBar } from "../../../ui";
import { taskStatuses } from "../../../../data/statuses";
import { CalendarLightIcon } from "../../../../icons";
import "./styles.scss";

const tableColumns = [
  {
    id: 1,
    label: "Task",
    slug: "title",
  },
  {
    id: 2,
    label: "Project",
    slug: "projectTitle",
  },
  {
    id: 4,
    label: "Status",
    slug: "status",
    colorOptions: taskStatuses,
  },
  {
    id: 5,
    label: "Due Date",
    slug: "dueDate",
    icon: <CalendarLightIcon />,
  },
  {
    id: 6,
    label: "Progress",
    slug: "progressComponent",
  },
];

const ListTasksModal = ({ tasks, show, close }) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setSelectedTask(null);
    setShowModal(false);
  };

  const handleOpenUpdateModal = (task) => {
    setSelectedTask({
      id: task?.user_task_id,
      user: task?.user,
      userId: task?.user?.id,
      title: task?.title,
      description: task?.description,
      notes: task?.notes,
      privacy: task?.privacy,
      type: task?.type,
      assets: task?.assets,
      results: task?.results,
      resources: task?.resources,
      progress: task?.progress,
      startDate: task?.start_date,
      status: task?.status,
      settings: task?.settings,
      project: task?.project,
    });
    setShowModal(true);
  };

  const tableRows = () => {
    const updatedTasks = tasks?.map((task) => {
      return {
        ...task,
        progressComponent: <ProgressBar progress={task?.progress} />,
        projectTitle: task?.project?.title,
        dueDate: task?.settings?.endDate,
      };
    });
    return updatedTasks || [];
  };

  const tableActions = [
    {
      id: 1,
      element: "Update Now",
      action: (task) => handleOpenUpdateModal(task),
    },
  ];

  return (
    <Modal
      show={show}
      handleClose={close}
      title="List tasks"
      className={"list-tasks-modal"}
      hasFooter={false}
    >
      <>
        <Table
          columns={tableColumns}
          rows={tableRows()}
          customActions={tableActions}
        />

        <TasksUpdateModal
          selectedTask={selectedTask}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />
      </>
    </Modal>
  );
};

export default ListTasksModal;
