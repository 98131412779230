import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUsers,
  createUser,
  deleteUser,
  updateUser,
  updateUserAvailability,
  fetchSingleUser,
  fetchUserReviewTemplates,
  fetchUsersByManager,
} from "./usersThunks";

let localUserData = JSON.parse(localStorage.getItem("authUserData"));

const usersSlice = createSlice({
  name: "users",
  initialState: {
    data: [],
    singleUser: null,
    userReviewTemplate: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetUserMessage: (state) => {
      state.message = null;
    },
    resetSingleUser: (state) => {
      state.singleUser = null;
    },
    resetUserReviewTemplate: (state) => {
      state.userReviewTemplate = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchSingleUser.fulfilled, (state, action) => {
      state.singleUser = action.payload;
    });
    builder.addCase(fetchUsersByManager.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const filteredUsers = state?.data?.userData?.filter(
        (user) => user.id !== action.meta.arg
      );
      state.data.userData = filteredUsers;
      state.message = {
        type: "success",
        title: "Success",
        message: "User successfully deleted",
      };
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.data.userData = [...state.data.userData, action.payload.data];
      state.message = {
        type: "success",
        title: "Success",
        message: "User successfully created",
      };
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const updatedState = state?.data?.userData?.map((user) => {
        return user.id === action.payload.data.id ? action.payload.data : user;
      });
      if (state.data.userData) {
        state.data.userData = updatedState;
      }
      if (action.payload.data.id === localUserData?.id) {
        localStorage.setItem(
          "authUserData",
          JSON.stringify(action.payload.data)
        );
      }
      state.message = {
        type: "success",
        title: "Success",
        message: "User successfully updated",
      };
    });
    builder.addCase(updateUserAvailability.fulfilled, (state, action) => {
      state.singleUser = action?.payload?.data;
    });
    builder.addCase(fetchUserReviewTemplates.fulfilled, (state, action) => {
      state.userReviewTemplate = action.payload;
    });
  },
});

export const { resetUserMessage, resetSingleUser, resetUserReviewTemplate } =
  usersSlice.actions;

export const usersReducer = usersSlice.reducer;
