import { CircleCheckIcon, CircleTIcon, CircleClockIcon } from "../icons";

export const taskStatuses = [
  { id: 1, value: 1, label: "On Track", color: "blue" },
  { id: 2, value: 2, label: "Locked", color: "purple" },
  { id: 3, value: 3, label: "Feature Creep", color: "red" },
  { id: 4, value: 4, label: "No status", color: "grey" },
  { id: 5, value: 100, label: "Completed", color: "green" },
];

export const performanceTaskStatuses = [
  {
    id: 5,
    value: 100,
    label: "Completed",
    color: "green",
    icon: <CircleCheckIcon />,
  },
  {
    id: 1,
    value: 1,
    label: "On Track",
    color: "blue",
    icon: <CircleTIcon />,
  },
  {
    id: 2,
    value: 2,
    label: "Locked",
    color: "purple",
    icon: <CircleCheckIcon />,
  },
  {
    id: 3,
    value: 3,
    label: "Feature Creep",
    color: "yellow",
    icon: <CircleClockIcon />,
  },
  {
    id: 4,
    value: 4,
    label: "Overdue",
    color: "red",
    icon: <CircleClockIcon />,
  },
];

export const taskStatusesSelection = [
  { id: 1, label: "On Track" },
  { id: 2, label: "Locked" },
  { id: 3, label: "Feature Creep" },
];

export const supportCaseStatuses = [
  { value: 1, label: "Ongoing" },
  { value: 2, label: "Done" },
];

export const goalStatuses = [
  { id: 1, label: "Individual", color: "purple" },
  { id: 2, label: "Business", color: "blue" },
];

export const feedbacksStatuses = [
  {
    id: 0,
    label: "Pending",
  },
  {
    id: 1,
    label: "Received",
  },
  {
    id: 2,
    label: "Sent",
  },
];

export const meetingStatuses = [
  { value: 1, label: "4 / 31 (%4)" },
  { value: 2, label: "Buddy / Onboarding / Stand Up" },
  { value: 3, label: "1:1 / Stakeholder" },
  { value: 4, label: "Managers manager / Performance check-in" },
  { value: 5, label: "Mid year review" },
  { value: 6, label: "Performance review" },
];

export const performanceReviewStatuses = [
  { id: 1, label: "Not-scheduled", color: "#666" },
  { id: 2, label: "Completed", color: "#A9CE5B" },
  { id: 3, label: "Upcoming", color: "#E8D52D" },
  { id: 4, label: "Overdue", color: "#F07171" },
];

export const performanceGoalStatuses = [
  {
    value: 1,
    label: "Completed",
    color: "green",
  },
  {
    value: 2,
    label: "Pending",
    color: "blue",
  },
  {
    value: 3,
    label: "Overdue",
    color: "red",
  },
];

export const performanceSurveyStatuses = [
  {
    value: 1,
    label: "Overdue",
    color: "red",
  },
  {
    value: 2,
    label: "Completed",
    color: "green",
  },
  {
    value: 3,
    label: "Uncompleted",
    color: "blue",
  },
  {
    value: 4,
    label: "Underdue",
    color: "yellow",
  },
];
