import React from "react";
import { Offcanvas } from "../../../../ui";
import { checkinsTypes } from "../../../../../data/types";

const CheckinsHistory = ({
  today = "",
  history = [],
  show = false,
  onCurrentClick = () => {},
  onHistoryClick = () => {},
  onClose = () => {},
}) => {
  const isCurrentCheckinHidden = history.some((histopryItem) => {
    if (histopryItem?.date === today) {
      return true;
    }
  });

  return (
    <Offcanvas
      className="history-offcanvas"
      show={show}
      position="end"
      backdrop={true}
      scroll={true}
      onHide={onClose}
    >
      <div className="check-in-history">
        <div className="title">
          <h3>My recent check ins</h3>
        </div>
        <div className="history">
          <div
            className="history-item"
            onClick={onCurrentClick}
            hidden={isCurrentCheckinHidden}
          >
            <h3>{today}</h3>
            <span>Current</span>
          </div>
          {history?.map((checkin, index) => (
            <div
              key={index}
              className="history-item flex justify-between align-center"
              onClick={() => onHistoryClick(index)}
            >
              <h4>{checkin.date}</h4>
              {!!checkin?.grades?.[0]?.grade && (
                <div className="icon-wrapper">
                  <img
                    src={require(`../images/${
                      checkinsTypes?.find(
                        (type) => type.value === checkin?.grades?.[0]?.grade
                      )?.imageName
                    }.png`)}
                    alt={checkin?.grades?.[0].grade}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Offcanvas>
  );
};

export default CheckinsHistory;
