import { createSlice } from "@reduxjs/toolkit";

import {
  fetchWorkshopSeminars,
  fetchWorkshopSeminar,
  createWorkshopSeminar,
  updateWorkshopSeminar,
  deleteWorkshopSeminar,
} from "./workshopSeminarThunks";

const workshopSeminarSlice = createSlice({
  name: "workshopSeminar",
  initialState: {
    data: [],
    singleWorkshopSeminar: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetWorkshopSeminarMessage: (state) => {
      state.message = null;
    },
    resetSingleWorkshopSeminar: (state) => {
      state.singleWorkshopSeminar = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWorkshopSeminars.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchWorkshopSeminar.fulfilled, (state, action) => {
      state.singleWorkshopSeminar = action.payload;
    });
    builder.addCase(deleteWorkshopSeminar.fulfilled, (state, action) => {
      const filteredWorkshopSeminars = state?.data?.filter(
        (workshopSeminar) => workshopSeminar.id !== action.meta.arg
      );
      state.data = filteredWorkshopSeminars;
      state.message = {
        type: "success",
        title: "Success",
        message: "Workshop Seminar successfully deleted",
      };
    });
    builder.addCase(createWorkshopSeminar.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Workshop Seminar successfully created",
      };
    });
    builder.addCase(updateWorkshopSeminar.fulfilled, (state, action) => {
      const updatedWorkshopSeminars = state?.data?.map((workshopSeminar) => {
        if (workshopSeminar.id === action.payload.id) {
          return action.payload;
        }
        return workshopSeminar;
      });
      state.data = updatedWorkshopSeminars;
      state.message = {
        type: "success",
        title: "Success",
        message: "Workshop Seminar successfully updated",
      };
    });
  },
});

export const { resetWorkshopSeminarMessage, resetSingleWorkshopSeminar } =
  workshopSeminarSlice.actions;

export const workshopSeminarReducer = workshopSeminarSlice.reducer;
