import { useEffect, useState } from "react";
import LineChartCheckins from "../../helperComponents/LineChartCheckins";
import DoughnutChartCheckins from "../../helperComponents/DoughnutChartCheckins";
import {
  fetchUserMoodStatsLine,
  fetchUserMoodStatsDoughnut,
} from "../../../../../../store";
import { useThunk } from "../../../../../../hooks/useThunk";
import { useSelector } from "react-redux";
import moment from "moment";
import { shortMonths } from "../../../../../../data/filters";
import { getWeekNumbersInMonth } from "../../../../../../utils/textFormat";

const YourCheckIn = ({ dateFilterOptions, localUserData }) => {
  const [doFetchUserMoodStatsLine] = useThunk(fetchUserMoodStatsLine);
  const [doFetchUserMoodStatsDoughnut] = useThunk(fetchUserMoodStatsDoughnut);

  const [usersStatsLine, setUsersStatsLine] = useState([]);
  const [lineChartFilter, setLineChartFilter] = useState();
  const [doughnutFilter, setDoughnutFilter] = useState();
  const [doughnutChartFilter, setDoughnutChartFilter] = useState();
  const [doughnutChartData, setDoughnutChartData] = useState([]);
  const [doughnutAvgData, setDoughnutAvgData] = useState([]);

  const daysCollection = [1, 2, 3, 4, 5, 6, 7];

  const usersStatsLineState = useSelector(
    (state) => state?.checkins?.moodStatistics
  )?.userStatsLine;

  const usersStatsDoughnutState = useSelector(
    (state) => state?.checkins?.moodStatistics
  )?.userStatsDoughnut;

  const convertArrayToString = (collection) => {
    return JSON.stringify(collection);
  };

  const lineChartPayload = [
    {
      value: 1,
      data: {
        user: localUserData?.id,
        range: "year",
        years: convertArrayToString([new Date().getFullYear()]),
      },
    },
    {
      value: 2,
      data: {
        user: localUserData?.id,
        range: "month",
        months: convertArrayToString([new Date().getMonth()]),
      },
    },
    {
      value: 3,
      data: {
        user: localUserData?.id,
        range: "week",
        weeks: convertArrayToString([moment().week()]),
      },
    },
  ];

  useEffect(() => {
    doFetchUserMoodStatsLine({
      user: localUserData?.id,
      range: "year",
      years: convertArrayToString([new Date().getFullYear()]),
    });
  }, []);

  useEffect(() => {
    if (lineChartFilter === 1) {
      setUsersStatsLine(avgMonthlyArrays);
      return;
    }
    if (lineChartFilter === 2) {
      setUsersStatsLine(avgWeeklyArrays);
      return;
    } else {
      setUsersStatsLine(avgDailyArrays);
    }
  }, [usersStatsLineState]);

  useEffect(() => {
    let params = null;
    switch (doughnutFilter) {
      case 2:
        params = {
          user: localUserData?.id,
          range: "month",
          months: convertArrayToString(
            Array.from({ length: 12 }, (_, i) => i + 1)
          ),
        };
        break;
      case 3:
        params = {
          user: localUserData?.id,
          range: "week",
          weeks: convertArrayToString(
            getWeekNumbersInMonth(
              new Date().getFullYear(),
              new Date().getMonth()
            )
          ),
        };
        break;
      default:
        params = {
          user: localUserData?.id,
          range: "year",
          years: convertArrayToString(
            Array.from(
              { length: 12 },
              (_, index) => new Date().getFullYear() - index
            )
          ),
        };
        break;
    }
    if (!!params) {
      doFetchUserMoodStatsDoughnut(params);
    }
  }, [doughnutFilter]);

  useEffect(() => {
    if (!!usersStatsDoughnutState && !!doughnutChartFilter) {
      const periods = Object.keys(usersStatsDoughnutState);

      setDoughnutChartData(
        usersStatsDoughnutState[
          getPeriod(doughnutFilter, doughnutChartFilter, "string-to-number")
        ]?.usersStats[0]?.checkinsStats[0]?.grades?.map(
          (grade) => grade?.count
        ) || Array.from({ length: 5 }, () => 0)
      );

      const avgData = periods.map((period) => {
        const data = {
          period: getPeriod(doughnutFilter, period, "number-to-string"),
          avg: Math.round(
            usersStatsDoughnutState[period]?.usersStats[0]?.checkinsStats[0]
              ?.avgYearly ||
              usersStatsDoughnutState[period]?.usersStats[0]?.checkinsStats[0]
                ?.avgMonthly ||
              usersStatsDoughnutState[period]?.usersStats[0]?.checkinsStats[0]
                ?.avgWeekly
          ),
          most: getMax(
            usersStatsDoughnutState[period]?.usersStats[0]?.checkinsStats[0]
              ?.grades
          ),
        };
        return data;
      });
      setDoughnutAvgData(avgData);
    }
  }, [usersStatsDoughnutState, doughnutChartFilter, doughnutFilter]);

  const getPeriod = (filter, period, conversion) => {
    switch (filter) {
      case 2:
        if (conversion === "string-to-number") {
          return (
            moment.months().findIndex((x) => x === period) + 1
          ).toString();
        } else {
          return moment.months()[parseInt(period) - 1];
        }
      case 3:
        if (conversion === "string-to-number") {
          return period.replace("Week ", "");
        } else {
          return `Week ${period}`;
        }
      default:
        return period;
    }
  };

  const getMax = (grades) => {
    if (!!grades?.length) {
      const maxValue = Math.max(...grades.map((grade) => grade.count));
      if (!!maxValue) {
        const foundGrade = grades
          .slice()
          .reverse()
          ?.find((grade) => grade.count === maxValue)?.grade;
        return foundGrade;
      }
    }
    return null;
  };

  const avgMonthlyArrays = Object.entries(usersStatsLineState)
    .map(([year, yearData]) => {
      if (!yearData?.usersStats?.length) {
        return { period: year, data: moment.months().map(() => NaN) };
      }

      return yearData.usersStats.flatMap((userStats) => {
        return {
          period: year,
          data: moment.months().map((month) => {
            const foundMonth = userStats?.checkinsStats[0]?.months?.find(
              (stats) => {
                return stats?.month === month;
              }
            );

            return foundMonth ? Math.round(foundMonth?.avgMonthly) : NaN;
          }),
        };
      });
    })
    .flat();

  const avgWeeklyArrays = Object.entries(usersStatsLineState)
    .map(([month, monthData]) => {
      const weeks = getWeekNumbersInMonth(
        new Date().getFullYear(),
        month - 1
      )?.map((item) => `${new Date().getFullYear()}-${item}`);

      if (!monthData?.usersStats?.length) {
        return { period: month, data: weeks.map(() => NaN) };
      }

      return monthData.usersStats.flatMap((userStats) => {
        return {
          period: getPeriod(lineChartFilter, month, "number-to-string")?.slice(
            0,
            3
          ),
          data: weeks.map((week) => {
            const foundWeek = userStats.checkinsStats[0]?.weeks[week];
            return foundWeek ? Math.round(foundWeek.avgWeekly) : NaN;
          }),
        };
      });
    })
    .flat();

  const avgDailyArrays = Object.entries(usersStatsLineState)
    .map(([week, weekData]) => {
      if (!weekData?.usersStats?.length) {
        return {
          period: getPeriod(lineChartFilter, week, "number-to-string"),
          data: daysCollection.map(() => NaN),
        };
      }

      return weekData.usersStats.flatMap((userStats) => {
        return {
          period: getPeriod(lineChartFilter, week, "number-to-string"),
          data: daysCollection.map((day) => {
            const foundDay = userStats.checkinsStats[0]?.days?.find(
              (d) => d.day === day
            );
            return foundDay ? Math.round(foundDay.avgDaily) : NaN;
          }),
        };
      });
    })
    .flat();

  const handleLineFilterChange = (selectedFilter) => {
    setLineChartFilter(selectedFilter);
    const payload = lineChartPayload?.find(
      (item) => item?.value === selectedFilter
    );
    doFetchUserMoodStatsLine(payload.data);
  };

  const handleChartChange = (segment) => {
    let payload = {
      user: localUserData?.id,
      range: "year",
      years: convertArrayToString(segment.map((item) => parseInt(item))),
    };
    if (lineChartFilter === 2) {
      payload = {
        user: localUserData?.id,
        range: "month",
        months: convertArrayToString(
          segment
            .map((item) => {
              const monthObj = shortMonths.find(
                (month) => month.label === item
              );
              return monthObj ? monthObj.value : null;
            })
            .filter((value) => value !== null)
        ),
      };
    } else if (lineChartFilter === 3) {
      payload = {
        user: localUserData?.id,
        range: "week",
        weeks: convertArrayToString(
          segment.map((item) => parseInt(item.replace("Week ", "")))
        ),
      };
    }
    doFetchUserMoodStatsLine(payload);
  };

  const handleDoughnutFilterChange = (filter) => {
    setDoughnutFilter(filter);
  };

  const handleDoughnutChartFilterChange = (chartFilter) => {
    setDoughnutChartFilter(chartFilter);
  };

  return (
    <div className="chart">
      <LineChartCheckins
        name="usersStatsLine"
        title="Your Checkins"
        data={usersStatsLine}
        filterOptions={dateFilterOptions}
        onFilterChange={(e) => handleLineFilterChange(e)}
        handleChartChange={handleChartChange}
      />
      <DoughnutChartCheckins
        data={[doughnutChartData]}
        filterOptions={dateFilterOptions}
        avgData={doughnutAvgData}
        onFilterChange={handleDoughnutFilterChange}
        onChartFilterChange={handleDoughnutChartFilterChange}
      />
    </div>
  );
};

export default YourCheckIn;
