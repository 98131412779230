import React, { useState, useEffect } from "react";
import { Button, Form, Input, Switcher } from "../../../../ui";
import DatePicker from "react-datepicker";
import { AddIcon, CalendarIcon, CloseIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  createWorkshopSeminar,
  updateWorkshopSeminar,
} from "../../../../../store";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";
import { useDispatch } from "react-redux";

const WorkshopSeminars = ({ userId, editData }) => {
  const [doCreateWorkshopSeminar] = useThunk(createWorkshopSeminar);
  const [doUpdateWorkshopSeminar] = useThunk(updateWorkshopSeminar);
  const defaultPlaceholder = "Type here...";
  const dispatch = useDispatch();
  const [workshop, setWorkshop] = useState({});
  const [isSeminar, setIsSeminar] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [topic, setTopic] = useState("");
  const [topicsList, setTopicsList] = useState([]);
  const [speaker, setSpeaker] = useState("");
  const [speakersList, setSpeakersList] = useState([]);

  useEffect(() => {
    if (editData) {
      setWorkshop({
        title: editData?.name,
        host: editData?.host,
        location: editData?.location,
        startDate: String(editData?.start).split(" ")[0],
        endDate: editData?.end ? String(editData?.end).split(" ")[0] : null,
        topics: editData?.topics.map((topic) => topic?.title),
        speakers: editData?.speakers.map((speaker) => speaker?.name),
      });
      setIsSeminar(editData?.type === "Seminar");
      setIsOnline(editData?.location === "Online");
      setSpeakersList(editData?.speakers?.map((speaker) => speaker?.name));
      setTopicsList(editData?.topics?.map((topic) => topic?.title));
    }
  }, [editData]);

  useEffect(() => {
    isSeminar
      ? handleChange("type", "Seminar")
      : handleChange("type", "Workshop");
  }, [isSeminar]);

  useEffect(() => {
    if (isOnline) {
      handleChange("location", "Online");
    } else {
      workshop?.location && handleChange("location", "");
    }
  }, [isOnline]);

  useEffect(() => {
    handleChange("topics", topicsList);
  }, [topicsList]);

  useEffect(() => {
    handleChange("speakers", speakersList);
  }, [speakersList]);

  const handleChange = (name, value) => {
    setWorkshop({ ...workshop, [name]: value });
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopicsList(topicsList?.filter((topic) => topic !== topicToRemove));
  };

  const handleRemoveSpeaker = (speakerToRemove) => {
    setSpeakersList(
      speakersList?.filter((speaker) => speaker !== speakerToRemove)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!workshop.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!workshop.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (!workshop.endDate || !workshop.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doCreateWorkshopSeminar({
      name: workshop.title,
      location: workshop.location,
      host: workshop.host,
      type: workshop.type,
      user_id: userId,
      start: workshop.startDate + " 00:00:00",
      end: workshop.endDate + " 00:00:00",
      speakers: speakersList.map((speaker) => ({ name: speaker })),
      topics: topicsList.map((topic) => ({ title: topic })),
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!workshop.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!workshop.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }

    if (!workshop.endDate || !workshop.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdateWorkshopSeminar({
      id: editData?.id,
      data: {
        name: workshop.title,
        location: workshop.location,
        host: workshop.host,
        type: workshop.type,
        start: workshop.startDate + " 00:00:00",
        end: workshop.endDate + " 00:00:00",
        speakers: speakersList.map((speaker) => ({ name: speaker })),
        topics: topicsList.map((topic) => ({ title: topic })),
      },
    });
  };

  return (
    <div className="workshop-seminars">
      <Form>
        <Input
          label="Workshop / Seminar Name"
          placeholder={defaultPlaceholder}
          name="title"
          value={workshop?.title}
          handleChange={(e) => {
            e.preventDefault();
            handleChange(e?.target?.name, e?.target?.value);
          }}
        />
        <div className="form-group bottom-margin">
          <label>Select Type</label>
          <div className="form-row switcher-group">
            <label className="label-normal">Workshop</label>
            <Switcher
              customBackground="or-switch"
              checked={isSeminar}
              handleChange={() => setIsSeminar(!isSeminar)}
            />
            <label className="label-normal">Seminar</label>
          </div>
        </div>
        <Input
          label="Host organization"
          placeholder={defaultPlaceholder}
          name="host"
          value={workshop?.host}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Conference Location</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Online</label>
              <Switcher
                checked={isOnline}
                handleChange={() => setIsOnline(!isOnline)}
              />
            </div>
          </div>
          <Input
            placeholder={defaultPlaceholder}
            name="location"
            value={workshop?.location}
            disabled={isOnline}
            handleChange={(e) => {
              e.preventDefault();
              handleChange(e?.target?.name, e?.target?.value);
            }}
          />
        </div>
        <div className="form-group">
          <label>Date</label>
          <div className="form-row">
            <div className="form-group date-group">
              <label className="label-normal">Starting date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat={"dd.MM.yyyy"}
                  selected={convertTextToDate(workshop?.startDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                />
                <CalendarIcon />
              </div>
            </div>
            <div className="form-group date-group">
              <label className="label-normal">Ending date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat={"dd.MM.yyyy"}
                  selected={convertTextToDate(workshop?.endDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                />
                <CalendarIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Workshop / Seminar  Topics"
              name="topics"
              placeholder={defaultPlaceholder}
              value={topic}
              handleChange={(e) => setTopic(e?.target?.value)}
            />
            <Button
              className="btn-input"
              handleClick={(e) => {
                e.preventDefault();
                if (!!topic) {
                  setTopicsList([...topicsList, topic]);
                  setTopic("");
                }
              }}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {workshop?.topics?.length > 0 && (
            <ul className="topics-list">
              {workshop?.topics?.map((topic, index) => (
                <li key={`topic-${index}`}>
                  <p>{topic}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => {
                      e.preventDefault();
                      handleRemoveTopic(topic);
                    }}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Speakers / Instructors"
              name="speakers"
              placeholder={defaultPlaceholder}
              value={speaker}
              handleChange={(e) => setSpeaker(e?.target?.value)}
            />
            <Button
              className="btn-input"
              handleClick={(e) => {
                e.preventDefault();
                if (!!speaker) {
                  setSpeakersList([...speakersList, speaker]);
                  setSpeaker("");
                }
              }}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {workshop?.speakers?.length > 0 && (
            <ul className="speakers-list">
              {workshop?.speakers?.map((speaker, index) => (
                <li key={`speaker-${index}`}>
                  <p>{speaker}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => {
                      e.preventDefault();
                      handleRemoveSpeaker(speaker);
                    }}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default WorkshopSeminars;
