import React from "react";

const Point = ({
  color = "#000",
  label = "",
  legend = false,
  status = null,
  pointId = null,
  onClick = () => {},
  onMouseOver = () => {},
}) => {
  return (
    <div className="point-wrapper" data-legend={legend}>
      <div
        className="point"
        style={{ borderColor: color }}
        onClick={legend ? () => {} : (e) => onClick(pointId, status, e)}
        onMouseOver={legend ? () => {} : (e) => onMouseOver(pointId, status, e)}
      >
        <div style={{ backgroundColor: color }}></div>
      </div>
      {!!label && <label>{label}</label>}
    </div>
  );
};

export default Point;
