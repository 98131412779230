import React from "react";
import "./styles.scss";

const TimelineList = ({
  data = [],
  lineColor = "",
  noDataString = "No data",
}) => {
  return (
    <ul className="timeline-list">
      {!!data?.length
        ? data.map((item, index) => (
            <li key={`tli-${index}`}>
              <div className="dotted-line">
                <span
                  className="vertical-line"
                  style={{ backgroundColor: lineColor }}
                ></span>
                <div
                  className="dot"
                  style={{ backgroundColor: lineColor }}
                ></div>
              </div>
              <p className="item">{item}</p>
            </li>
          ))
        : noDataString}
    </ul>
  );
};

export default TimelineList;
