import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, Input, Switcher } from "../../../../ui";
import { AddIcon, CloseIcon, DatePickerIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  createConferenceAttendance,
  updateConferenceAttendance,
} from "../../../../../store";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";

const ConferenceAttendance = ({ userId, editData }) => {
  const inputPlaceholder = "Type here...";
  const [doCreateConferenceAttendance] = useThunk(createConferenceAttendance);
  const [doUpdateConferenceAttendance] = useThunk(updateConferenceAttendance);
  const dispatch = useDispatch();
  const [conference, setConference] = useState({});
  const [isOnline, setIsOnline] = useState(false);
  const [speaker, setSpeaker] = useState("");
  const [sepakersList, setSepakersList] = useState([]);
  const [topic, setTopic] = useState("");
  const [topicsList, setTopicsList] = useState([]);

  useEffect(() => {
    if (editData) {
      setIsOnline(editData.location === "Online");
      setTopicsList(editData.topics.map((topic) => topic.title));
      setSepakersList(editData.speakers.map((speaker) => speaker.name));
    }
  }, [editData]);

  useEffect(() => {
    if (isOnline) {
      handleChange("location", "Online");
    } else {
      conference?.location && handleChange("location", "");
    }
  }, [isOnline]);

  useEffect(() => {
    handleChange("speakers", sepakersList);
  }, [sepakersList]);

  useEffect(() => {
    handleChange("topics", topicsList);
  }, [topicsList]);

  const handleChange = (name, value) => {
    setConference({ ...conference, [name]: value });
  };

  const handleSpeakerChange = (value) => {
    setSpeaker(value);
  };

  const handleTopicChange = (value) => {
    setTopic(value);
  };

  const handleAddClick = (name) => {
    if (name === "speakers") {
      setSepakersList([...sepakersList, speaker]);
    } else {
      setTopicsList([...topicsList, topic]);
    }
  };

  const handleRemoveSpeaker = (speakerToRemove) => {
    setSepakersList(
      sepakersList.filter((speaker) => speaker !== speakerToRemove)
    );
  };

  const handleRemoveTopic = (topicToRemove) => {
    setTopicsList(topicsList.filter((topic) => topic !== topicToRemove));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!conference.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!conference.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (!conference.endDate || !conference.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doCreateConferenceAttendance({
      name: conference.title,
      location: conference.location,
      user_id: userId,
      start: conference.startDate + " 00:00:00",
      end: conference.endDate + " 00:00:00",
      speakers: sepakersList.map((speaker) => ({ name: speaker })),
      topics: topicsList.map((topic) => ({ title: topic })),
      notes: conference.notes,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!conference.title && !editData?.name) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!conference.location && !editData?.location) {
      dispatch(
        setToastMessage({
          title: "Location error",
          message: "Please add location.",
          type: "error",
        })
      );
      return;
    }
    if (
      (!conference.endDate || !conference.startDate) &&
      (!editData?.end || !editData?.start)
    ) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdateConferenceAttendance({
      id: editData.id,
      data: {
        name: conference.title || editData.name,
        location: conference.location || editData.location,
        user_id: userId,
        start: conference.startDate || editData.start,
        end: conference.endDate || editData.end,
        speakers: sepakersList.map((speaker) => ({ name: speaker })),
        topics: topicsList.map((topic) => ({ title: topic })),
        notes: conference.notes || editData.notes,
      },
    });
  };

  return (
    <div className="conference-attendance">
      <Form>
        <Input
          label="Conference Name"
          placeholder={inputPlaceholder}
          name="title"
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
          value={conference?.title || editData?.name}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Conference Location</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Online</label>
              <Switcher
                name="locationOnline"
                checked={isOnline}
                handleChange={() => {
                  setIsOnline(!isOnline);
                }}
              />
            </div>
          </div>
          <Input
            placeholder={inputPlaceholder}
            name="location"
            handleChange={(e) =>
              handleChange(e?.target?.name, e?.target?.value)
            }
            value={conference?.location || editData?.location}
            disabled={isOnline}
          />
        </div>
        <div className="form-group">
          <label>Date</label>
          <div className="form-row">
            <div className="form-group date-group">
              <label className="label-normal">Start date</label>
              <div className="date-picker-holder">
                <DatePicker
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                  dateFormat={"dd.MM.yyyy"}
                  showMonthDropdown
                  showYearDropdown
                  selected={
                    convertTextToDate(editData?.start) ||
                    convertTextToDate(conference?.startDate)
                  }
                />
                <DatePickerIcon />
              </div>
            </div>
            <div className="form-group date-group">
              <label className="label-normal">End date</label>
              <div className="date-picker-holder">
                <DatePicker
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                  dateFormat={"dd.MM.yyyy"}
                  showMonthDropdown
                  showYearDropdown
                  selected={
                    convertTextToDate(editData?.end) ||
                    convertTextToDate(conference?.endDate)
                  }
                />
                <DatePickerIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Speakers / Presenters"
              placeholder={inputPlaceholder}
              name="speakers"
              handleChange={(e) => handleSpeakerChange(e?.target?.value)}
              value={speaker}
            />
            <Button
              className="btn-input"
              handleClick={(e) => {
                e.preventDefault();
                if (!!speaker) {
                  handleAddClick("speakers");
                  setSpeaker("");
                }
              }}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {sepakersList.length > 0 && (
            <ul className="speakers-list">
              {sepakersList?.map((speaker, index) => (
                <li key={`speaker-${index}`}>
                  <span>{speaker}</span>
                  <Button
                    variant="control hidden"
                    buttonStyles={{
                      width: "18px",
                      height: "18px",
                      border: "1px solid #a1a1a1",
                      padding: "0",
                    }}
                    handleClick={() => handleRemoveSpeaker(speaker)}
                  >
                    <CloseIcon fill="#a1a1a1" width="5px" height="5px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Conference Topics"
              placeholder={inputPlaceholder}
              name="topics"
              handleChange={(e) => handleTopicChange(e?.target?.value)}
              value={topic}
            />
            <Button
              className="btn-input"
              handleClick={(e) => {
                e.preventDefault();
                if (!!topic) handleAddClick("topics");
                setTopic("");
              }}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {topicsList.length > 0 && (
            <ul className="topics-list">
              {conference?.topics?.map((topic, index) => (
                <li key={`topic-${index}`}>
                  <p>{topic}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => {
                      e.preventDefault();
                      handleRemoveTopic(topic);
                    }}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <Input
          type="textarea"
          label="Notes"
          name="notes"
          placeholder={inputPlaceholder}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
          value={conference?.notes || editData?.notes || ""}
        />
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ConferenceAttendance;
