import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchConferenceAttendances = createAsyncThunk(
  "conferenceAttendance/fetch",
  async ({ user_id }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/conference-attendances`,
        {
          params: {
            user_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchConferenceAttendance = createAsyncThunk(
  "conferenceAttendance/fetchById",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/conference-attendance/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createConferenceAttendance = createAsyncThunk(
  "conferenceAttendance/create",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/conference-attendance`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateConferenceAttendance = createAsyncThunk(
  "conferenceAttendance/update",
  async ({ id, data }) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/conference-attendance/${id}`,
        {
          ...data,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteConferenceAttendance = createAsyncThunk(
  "conferenceAttendance/delete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/conference-attendance/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
