import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTasks,
  fetchSingleTask,
  fetchUsersTask,
  createTask,
  updateTask,
  deleteTask,
  createTaskSettings,
  createTaskStatus,
  updateTaskSettings,
  fetchSingleUserTask,
  fetchProjectsTasks,
  fetchTaskComments,
} from "./tasksThunks";

const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    data: [],
    singleTask: null,
    usersTasks: [],
    filteredUsersTasks: [],
    filteredUsersTasksMeta: null,
    projectsTasks: [],
    projectsTasksMeta: null,
    createdTaskId: null,
    selectedTask: null,
    selectedTaskComments: [],
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },
    resetSingleTask: (state, action) => {
      state.singleTask = null;
    },
    resetTaskMessage: (state) => {
      state.message = null;
    },
    filterUsersTask: (state, action) => {
      const status = action.payload;
      const filteredTasks = state.usersTasks.filter(
        (task) => task?.status === status
      );
      state.filteredUsersTasks = filteredTasks;
    },
    resetFilterUsersTask: (state) => {
      state.filteredUsersTasks = state.usersTasks;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchSingleTask.fulfilled, (state, action) => {
      state.singleTask = action.payload;
    });
    builder.addCase(createTask.fulfilled, (state, action) => {
      state.createdTaskId = action.payload.id;
      state.data = [...state.data, action.payload];
      state.singleTask = action.payload;
      if (action.payload?.userId) {
        state.usersTasks = [...state.usersTasks, action.payload];
        state.selectedTask = action.payload;
      }
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      const updatedState = state.data.map((task) => {
        return task.id === action.payload.id ? action.payload : task;
      });
      const updatedUserTaskState = state.usersTasks.map((task) => {
        return task.id === action.payload.id ? action.payload : task;
      });
      const updatedProjectTaskState = state.projectsTasks.map((task) => {
        return task.id === action.payload.id ? action.payload : task;
      });
      state.singleTask = action.payload;
      state.data = updatedState;
      state.usersTasks = updatedUserTaskState;
      state.projectsTasks = updatedProjectTaskState;
      if (action.payload?.userId) {
        state.selectedTask = action.payload;
      }
      state.message = {
        type: "success",
        title: "Success",
        message: "Task successfully updated",
      };
    });
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      const filteredState = state.data.filter(
        (task) => task.id !== action.meta.arg
      );
      state.data = filteredState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Task successfully deleted",
      };
    });
    builder.addCase(fetchUsersTask.fulfilled, (state, action) => {
      state.usersTasks = action.payload.taskData;
      state.filteredUsersTasks = action.payload.taskData;
      state.filteredUsersTasksMeta = action.payload.pageData;
    });
    builder.addCase(createTaskSettings.fulfilled, (state, action) => {
      const updatedUserTaskState = state.usersTasks.map((task) => {
        return task.id === action.payload.taskId
          ? { ...task, settings: action.payload }
          : task;
      });
      state.usersTasks = updatedUserTaskState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Task successfully updated",
      };
    });
    builder.addCase(updateTaskSettings.fulfilled, (state, action) => {
      const updatedUserTaskState = state.usersTasks.map((task) => {
        return task.id === action.payload.taskId
          ? { ...task, settings: action.payload }
          : task;
      });
      const updatedMeta = {
        ...state.projectsTasksMeta,
        totalPerStatus: action.payload.totalPerStatus,
      };
      state.projectsTasksMeta = updatedMeta;
      state.usersTasks = updatedUserTaskState;
      state.message = {
        type: "success",
        title: "Success",
        message: "Task successfully updated",
      };
    });
    builder.addCase(createTaskStatus.fulfilled, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(fetchSingleUserTask.fulfilled, (state, action) => {
      state.singleTask = action.payload;
    });
    builder.addCase(fetchProjectsTasks.fulfilled, (state, action) => {
      state.projectsTasks = action.payload.taskData;
      state.projectsTasksMeta = action.payload.pageData;
    });
    builder.addCase(fetchTaskComments.fulfilled, (state, action) => {
      state.selectedTaskComments = action.payload;
    });
  },
});

export const {
  setSelectedTask,
  resetSingleTask,
  resetTaskMessage,
  filterUsersTask,
  resetFilterUsersTask,
} = tasksSlice.actions;

export const tasksReducer = tasksSlice.reducer;
