import React, { useEffect, useState } from "react";
import { Button, TypeAhead } from "../../../ui";
import "./styles.scss";
import { useThunk } from "../../../../hooks/useThunk";
import {
  createAnswers,
  createMood,
  fetchMeetingTypes,
  fetchUserCheckinsHistory,
} from "../../../../store";
import moment from "moment";
import { useSelector } from "react-redux";
import { checkinsTypes } from "../../../../data/types";
import SuggestionColumn from "./helperComponents/SuggestionColumn";
import { SearchIcon } from "../../../../icons";
import CheckinsHistory from "./helperComponents/CheckinsHistory";
import { useNavigate } from "react-router-dom";

import YourCheckIn from "./checkInGroups/YourCheckIn";
import TeamsCheckIn from "./checkInGroups/TeamsCheckIn";
import CompanyCheckIn from "./checkInGroups/CompanyCheckIn";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetCheckinsMessage } from "../../../../store/checkins/checkinsSlice";

// TODO: Refactor whole component
const CheckIns = () => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const navigate = useNavigate();

  const [doFetchHistory] = useThunk(fetchUserCheckinsHistory);
  const [doCreateMood] = useThunk(createMood);
  const [doCreateAnswer] = useThunk(createAnswers);
  const [doFetchMeetingTypes] = useThunk(fetchMeetingTypes);
  const [doShowToastMessage] = useToastMessage(resetCheckinsMessage);

  const checkinsHistoryState = useSelector(
    (state) => state?.checkins
  )?.userHistory;
  const meetingTypesState = useSelector(
    (state) => state.meetingTypes
  )?.meetingTypes;

  const toastMessages = useSelector((state) => state?.checkins)?.message;

  const [selectedCheckin, setSelectedCheckin] = useState(null);
  const [mood, setMood] = useState({ user_id: localUserData?.id, grade: 0 });
  const [suggestions, setSuggestions] = useState({
    wentWell: [],
    issues: [],
    solutions: [],
  });
  const [suggestionValue, setSuggestionValue] = useState({
    wentWell: "",
    issues: "",
    solutions: "",
  });
  const [showHistory, setShowHistory] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [addSolution, setAddSolution] = useState(false);
  const [referenceCounter, setReferenceCounter] = useState(0);
  const [redBorderMark, setRedBorderMark] = useState(false);

  const today = moment(new Date()).format("DD.MM.YY");

  const dateFilterOptions = ["Years", "Months", "Weeks"];

  useEffect(() => {
    doFetchHistory(localUserData?.id);
    doFetchMeetingTypes();
  }, []);

  useEffect(() => {
    doShowToastMessage(toastMessages);
  }, [toastMessages]);

  useEffect(() => {
    if (checkinsHistoryState?.length > 0) {
      for (const historyItem of checkinsHistoryState) {
        if (historyItem?.date === today) {
          setSelectedCheckin(historyItem);
          break;
        }
      }
    }
  }, [checkinsHistoryState]);

  useEffect(() => {
    if (!!selectedCheckin) {
      let maxReference = 0;

      if (selectedCheckin.date === today) {
        setIsSaveDisabled(false);
        setIsEditDisabled(false);
      } else {
        setIsSaveDisabled(true);
        setIsEditDisabled(true);
      }

      selectedCheckin?.questions
        ?.filter((question) => question?.question_id === 2)
        .map((question) => {
          if (question?.reference > maxReference) {
            maxReference = question?.reference;
          }
        });
      setReferenceCounter(maxReference);
    } else {
      setIsSaveDisabled(false);
    }
  }, [selectedCheckin]);

  useEffect(() => {
    const wentWell = selectedCheckin?.questions
      ?.filter((question) => question?.question_id === 1)
      .map((question) => {
        return { answer: question?.answer_text, id: question?.answer_id };
      });
    const issues = selectedCheckin?.questions
      ?.filter((question) => question?.question_id === 2)
      .map((question) => {
        return {
          answer: question?.answer_text,
          id: question?.answer_id,
          reference: question?.reference,
        };
      })
      .sort((a, b) => b.reference - a.reference);
    const solutions = selectedCheckin?.questions
      ?.filter((question) => question?.question_id === 3)
      .map((question) => {
        return {
          answer: question?.answer_text,
          id: question?.answer_id,
          refered_to: question?.refered_to,
        };
      })
      .sort((a, b) => b.refered_to - a.refered_to);
    setSuggestions({
      wentWell: wentWell?.reverse(),
      issues,
      solutions,
    });
  }, [selectedCheckin]);

  useEffect(() => {
    if (redBorderMark) {
      setTimeout(() => {
        setRedBorderMark(false);
      }, 3000);
    }
  }, [redBorderMark]);

  const handleHistoryClick = () => {
    setShowHistory(true);
  };

  const handleHistoryClose = () => {
    setShowHistory(false);
  };

  const handleMoodClick = (value) => {
    setMood({ ...mood, grade: value });
  };

  const handleSuggestionChange = (property, value) => {
    setSuggestionValue({ ...suggestionValue, [property]: value });
  };

  const handleSuggestionAdd = (property) => {
    let propertyArray = [];
    switch (property) {
      case "wentWell":
        propertyArray = [
          {
            question: 1,
            answer: suggestionValue[property],
            user_id: localUserData.id,
            reference: null,
            refered_to: null,
          },
          ...(suggestions[property] || []),
        ];
        setSuggestionValue({ ...suggestionValue, wentWell: "" });
        break;
      case "issues":
        setReferenceCounter((prev) => {
          const counter = prev + 1;
          propertyArray = [
            {
              question: 2,
              answer: suggestionValue[property],
              user_id: localUserData.id,
              reference: counter,
              refered_to: null,
            },
            ...(suggestions[property] || []),
          ];
          return counter;
        });
        setAddSolution(true);
        break;
      case "solutions":
        propertyArray = [
          {
            question: 3,
            answer: suggestionValue[property],
            user_id: localUserData.id,
            refered_to: referenceCounter,
            reference: null,
          },
          ...(suggestions[property] || []),
        ];
        setAddSolution(false);
        setSuggestionValue({ ...suggestionValue, solution: "" });
        break;
      default:
        break;
    }

    setSuggestions({ ...suggestions, [property]: propertyArray });
    setSuggestionValue({ ...suggestionValue, [property]: "" });
  };

  const handleSuggestionAddCancel = (property) => {
    setSuggestionValue({ suggestionValue, [property]: "" });
  };

  const handleSuggestionEdit = (selectedIndex, property) => {
    setSuggestions({
      ...suggestions,
      [property]: suggestions?.[property].map((suggestion, index) => {
        if (index === selectedIndex) {
          return {
            ...suggestion,
            answer: suggestionValue?.[property],
          };
        }
        return suggestion;
      }),
    });
  };

  const handleSuggestionDelete = (selectedIndex, property) => {
    const reference = suggestions?.[property][selectedIndex]?.reference;
    if (!!reference) {
      setSuggestions({
        ...suggestions,
        [property]: suggestions?.[property]?.filter(
          (suggestion, index) => index !== selectedIndex
        ),
        solutions: suggestions?.solutions?.filter(
          (solution) => solution?.refered_to !== reference
        ),
      });
      return;
    }
    setSuggestions({
      ...suggestions,
      [property]: suggestions?.[property]?.filter(
        (suggestion, index) => index !== selectedIndex
      ),
    });
  };

  const handleSaveClick = async () => {
    if (!selectedCheckin && mood?.grade === 0) {
      setRedBorderMark(true);
      return;
    }
    if (!selectedCheckin && mood?.grade !== 0) {
      await doCreateMood(mood);
    }

    const createAnswerPromises = [];
    for (const key of Object.keys(suggestions)) {
      const answers = suggestions[key];
      if (answers) {
        for (const answer of answers) {
          if (!answer?.id) {
            createAnswerPromises.push(doCreateAnswer(answer));
          }
        }
      }
    }

    await Promise.all(createAnswerPromises);

    await doFetchHistory(localUserData?.id);
  };

  const handleHistoryItemClick = (index) => {
    setSelectedCheckin(checkinsHistoryState[index]);
  };

  const handleHistoryCurrentClick = () => {
    setSelectedCheckin(null);
    handleHistoryClose();
  };

  const handleScheduleMeeting = () => {
    navigate("/meetings/form", {
      state: {
        type: meetingTypesState?.find((type) => type?.name === "One on One")
          ?.id,
        disableTypeChange: true,
      },
    });
  };

  const handleRequestFeedback = () => {
    // TODO: send feedback request
  };

  return (
    <div className="checkins-wrapper">
      <div className="title-wrapper">
        <h2>{`My check-in: ${selectedCheckin?.date || today}`}</h2>
        <div className="flex gap-20 items-center">
          {/* <TypeAhead
            placeholder="Search user"
            icon={<SearchIcon />}
            options={usersState || []}
            slug={(option) => `${option?.first_name} ${option?.last_name}`}
            handleSearch={handleUserSearch}
            setSelected={handleUserSelect}
            selected={selectedUser}
          /> */}
          <Button handleClick={handleSaveClick} disabled={isSaveDisabled}>
            Save
          </Button>
          <Button variant="secondary" handleClick={handleHistoryClick}>
            History
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="checkins">
          <div className="mood">
            <h3>How did you feel at work since last Check-in</h3>
            <div className={`mood-list ${redBorderMark ? "red-border" : ""}`}>
              <span>Not well</span>
              {checkinsTypes.map((moodItem, index) => (
                <Button
                  key={`mood-${index}`}
                  variant="action"
                  disabled={!!selectedCheckin}
                  className={`mood-btn ${
                    moodItem?.value === selectedCheckin?.grades[0].grade ||
                    moodItem?.value === mood?.grade
                      ? "checked"
                      : ""
                  }`}
                  handleClick={() => handleMoodClick(moodItem?.value)}
                >
                  <img
                    src={require(`./images/${moodItem?.imageName}.png`)}
                    alt={moodItem?.imageName}
                  />
                </Button>
              ))}
              <span>Amazing!</span>
            </div>
          </div>
          <div className="suggestions">
            <SuggestionColumn
              title="What went well"
              dataItems={suggestions?.wentWell}
              color="green"
              addInputValue={suggestionValue?.wentWell}
              disableEdit={isEditDisabled}
              onAddInputChange={(e) =>
                handleSuggestionChange("wentWell", e?.target?.value)
              }
              onInputSave={() => handleSuggestionAdd("wentWell")}
              onAddInputCancel={() => handleSuggestionAddCancel("wentWell")}
              onItemEdit={(index) => handleSuggestionEdit(index, "wentWell")}
              onItemDelete={(index) =>
                handleSuggestionDelete(index, "wentWell")
              }
            />
            <SuggestionColumn
              title="What you didnt like"
              dataItems={suggestions?.issues}
              color="red"
              addInputValue={suggestionValue?.issues}
              disableEdit={isEditDisabled}
              onAddInputChange={(e) =>
                handleSuggestionChange("issues", e?.target?.value)
              }
              onInputSave={() => handleSuggestionAdd("issues")}
              onAddInputCancel={() => handleSuggestionAddCancel("issues")}
              onItemEdit={(index) => handleSuggestionEdit(index, "issues")}
              onItemDelete={(index) => handleSuggestionDelete(index, "issues")}
            />
            <SuggestionColumn
              title="Possible solutions"
              dataItems={suggestions?.solutions}
              color="blue"
              haveAddButton={false}
              showAddInput={addSolution}
              addInputValue={suggestionValue?.solutions}
              disableEdit={isEditDisabled}
              onAddInputChange={(e) =>
                handleSuggestionChange("solutions", e?.target?.value)
              }
              onInputSave={() => handleSuggestionAdd("solutions")}
              onAddInputCancel={() => handleSuggestionAddCancel("solutions")}
              onItemEdit={(index) => handleSuggestionEdit(index, "solutions")}
              onItemDelete={(index) =>
                handleSuggestionDelete(index, "solutions")
              }
            />
          </div>
          <div className="feedback">
            <h3>Leave a feedback for Check-in</h3>
            <div className="flex gap-20">
              <Button handleClick={() => handleRequestFeedback()}>
                Request feedback
              </Button>
              <Button handleClick={handleScheduleMeeting}>Schedule 1-1</Button>
            </div>
          </div>
          <div className="charts">
            {/* Your CheckIn */}
            <YourCheckIn
              dateFilterOptions={dateFilterOptions}
              localUserData={localUserData}
            />
            <hr />

            {/* Teams */}
            <TeamsCheckIn localUserData={localUserData} />
            <hr />

            {/* Company */}
            <CompanyCheckIn dateFilterOptions={dateFilterOptions} />
          </div>
        </div>
        <CheckinsHistory
          today={today}
          history={checkinsHistoryState}
          show={showHistory}
          onClose={handleHistoryClose}
          onHistoryClick={handleHistoryItemClick}
          onCurrentClick={handleHistoryCurrentClick}
        />
      </div>
    </div>
  );
};

export default CheckIns;
