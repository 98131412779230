import React, { useState } from "react";
import { Button, Checkbox } from "../../../../ui";
import { EditIcon, SingleArrow } from "../../../../../icons";
import { performanceReviewExpectationOptions } from "../../../../../data/types";
import { useThunk } from "../../../../../hooks/useThunk";
import { updatePerformance } from "../../../../../store";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";

const ReviewRow = ({
  reviewData,
  isEditable = false,
  showConclusionMark = false,
  preExpanded = false,
  onEdit = () => {},
  onCancel = () => {},
  onSave = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(preExpanded || false);
  const [isEditing, setIsEditing] = useState(false);
  const [expandedExpectation, setExpandedExpectation] = useState([]);
  const [review, setReview] = useState({
    ...reviewData,
    sender: reviewData?.sender?.id,
  });

  const [doUpdateReview] = useThunk(updatePerformance);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleExpandExpectation = (type) => {
    if (expandedExpectation.includes(type)) {
      setExpandedExpectation(expandedExpectation.filter((t) => t !== type));
    } else {
      setExpandedExpectation([...expandedExpectation, type]);
    }
  };

  const handleCheckboxChange = (column, index) => {
    setReview({
      ...review,
      [column]: review[column]?.map((item, i) =>
        i === index
          ? { ...item, accomplished: Number(!item.accomplished) }
          : item
      ),
    });
  };

  const handleRadioChange = (e) => {
    setReview({
      ...review,
      expectation: parseInt(e?.target?.value),
      status: 1,
    });
  };

  const handleEdit = (id) => {
    setIsExpanded(true);
    setIsEditing(true);
    onEdit(id);
  };

  const handleCancel = () => {
    setIsExpanded(false);
    setIsEditing(false);
    onCancel();
  };

  const handleSave = (id) => {
    setIsExpanded(false);
    setIsEditing(false);
    doUpdateReview({
      ...review,
      schedule_date: formatShortDate(
        convertTextToDate(review.schedule_date),
        "DD.MM.YYYY HH:mm"
      ),
    });
    onSave(id);
  };

  return (
    <div
      className={`
        row 
        ${isExpanded ? "expanded" : ""} 
        ${isEditing ? "editing" : ""}
      `}
    >
      {showConclusionMark && (
        <div
          className="conclusion-mark"
          style={{
            backgroundColor: performanceReviewExpectationOptions.find(
              (conclusion) => conclusion.value === review.expectation
            )?.color,
          }}
        />
      )}
      <div className="columns">
        <div className="column">
          <p className="review-sender">From: {review?.sender?.name}</p>
          <p>{review.title}</p>
        </div>
        <div className="column">
          <p>Responsibilities</p>
          <div className="checklist responsibilities">
            {review.responsibilities?.map((responsibility, index) => (
              <div key={index} className="flex gap-4">
                <Checkbox
                  checked={Boolean(responsibility?.accomplished)}
                  disabled={!isEditing}
                  onChange={(e) =>
                    handleCheckboxChange("responsibilities", index)
                  }
                >
                  {responsibility?.responsibility}
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
        <div className="column">
          <p>Behaviors</p>
          <div className="checklist behaviors">
            {review.behaviours?.map((behaviour, index) => (
              <div key={index} className="flex gap-4">
                <Checkbox
                  checked={Boolean(behaviour?.accomplished)}
                  disabled={!isEditing}
                  onChange={(e) => handleCheckboxChange("behaviours", index)}
                >
                  {behaviour?.behaviour}
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
        <div className="column">
          <p>Interests</p>
          <div className="checklist interests">
            {review.interests?.map((interest, index) => (
              <div key={index} className="flex gap-4">
                <Checkbox
                  checked={Boolean(interest?.accomplished)}
                  disabled={!isEditing}
                  onChange={(e) => handleCheckboxChange("interests", index)}
                >
                  {interest?.interest}
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
        <div className="column">
          <p>Skills</p>
          <div className="checklist skills">
            {review.skills?.map((skill, index) => (
              <div key={index} className="flex gap-4">
                <Checkbox
                  checked={Boolean(skill?.accomplished)}
                  disabled={!isEditing}
                  onChange={(e) => handleCheckboxChange("skills", index)}
                >
                  {skill?.skill}
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
        <div className="column">
          <p>{review.date}</p>
        </div>
        <div className="column action-column">
          <div className="actions">
            {isEditable && (
              <>
                {!isEditing && (
                  <Button
                    variant="action"
                    handleClick={() => handleEdit(review.id)}
                  >
                    <EditIcon />
                  </Button>
                )}
              </>
            )}
            <span className="vertical-line" />
            <Button
              variant="control"
              className="expand-button"
              disabled={isEditing}
              handleClick={() => handleExpand(review.id)}
            >
              <SingleArrow />
            </Button>
          </div>
        </div>
      </div>
      <div className="expectations">
        {[
          "company_culture",
          "growth_opportunities",
          "compensation_information",
        ].map((expectationType) => (
          <div
            key={expectationType}
            className={`expectation-row ${
              expandedExpectation.includes(expectationType)
                ? "expanded-expectation"
                : ""
            }`}
          >
            <div className="columns">
              <div className="column span-6">
                <p>
                  {expectationType.replace("_", " ").charAt(0).toUpperCase() +
                    expectationType.replace("_", " ").slice(1)}
                </p>
                <p>{review[expectationType]}</p>
              </div>
              <div className="column expectation-action-column">
                <div className="expectation-actions">
                  <span className="vertical-line" />
                  <Button
                    variant="control"
                    handleClick={() => handleExpandExpectation(expectationType)}
                  >
                    <SingleArrow />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isEditing && (
        <div className="flex justify-between px-6">
          <div className="review-conclusion">
            <p>Expectations</p>
            {performanceReviewExpectationOptions?.map((conclusion, index) => (
              <div key={index} className="conclusion-option">
                <Checkbox
                  type="radio"
                  labelSide="right"
                  name="conclusion"
                  disabled={!isEditing}
                  onChange={handleRadioChange}
                  value={conclusion?.value}
                  checked={review?.expectation === conclusion?.value}
                >
                  <label htmlFor="conclusion">{conclusion.label}</label>
                </Checkbox>
              </div>
            ))}
          </div>
          <div className="flex">
            <Button
              variant="secondary"
              className="review-btn"
              handleClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="review-btn"
              handleClick={() => handleSave(review.id)}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewRow;
