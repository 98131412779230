import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Button, Form, Input } from "../../../ui";
import ProjectTaskForm from "./ProjectTaskForm";
import { useThunk } from "../../../../hooks/useThunk";
import {
  createProject,
  deleteTask,
  fetchSingleUserTask,
  fetchUsers,
} from "../../../../store";
import { projectNotificationTypes } from "../../../../data/types";
import { addMonths, subMonths } from "../../../../utils/textFormat";

import moment from "moment";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

import {
  PlusIcon,
  CalendarIcon,
  AttachmentIcon,
  EditIcon,
  DeleteIcon,
  DatePickerIcon,
} from "../../../../icons";
import { resetSingleTask } from "../../../../store/tasks/tasksSlice";

const ProjectForm = () => {
  const navigate = useNavigate();
  const [project, setProject] = useState({});
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [projectTasks, setProjectTasks] = useState([]);
  const [actionType, setActionType] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsersOptions, setSelectedUsersOptions] = useState([]);

  const [doDeleteTask] = useThunk(deleteTask);
  const [doFetchSingleUserTask] = useThunk(fetchSingleUserTask);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doCreateProject] = useThunk(createProject);

  const singleTaskState = useSelector((state) => state?.tasks)?.singleTask;
  const usersState = useSelector((state) => state.users)?.data?.optionData;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    } else {
      const reducedUsers = usersState.map((user) => {
        return {
          value: user?.id,
          label: `${user?.first_name} ${user?.last_name}`,
        };
      });
      setAllUsers(reducedUsers);
    }
  }, [usersState]);

  useEffect(() => {
    if (!!singleTaskState) {
      if (actionType === "edit") {
        setProjectTasks(
          projectTasks.map((task) =>
            task?.id === singleTaskState?.id ? singleTaskState : task
          )
        );
      } else {
        setProjectTasks([...projectTasks, singleTaskState]);
      }
    }
  }, [singleTaskState]);

  const handleChange = (event, componentName) => {
    const { name, value } = event.target ? event.target : event;
    !!componentName
      ? setProject({ ...project, [componentName]: value })
      : setProject({ ...project, [name]: value });
  };

  const handleMultiSelect = (event, componentName) => {
    if (componentName === "users") {
      setProject({ ...project, users: event.map((user) => user.value) });
      setSelectedUsersOptions(event);
    }
  };

  const handleSave = () => {
    doCreateProject({
      ...project,
      tasks: projectTasks.map((project) => project?.id),
      status: 1,
    });

    dispatch(resetSingleTask());
    navigate("/projects/projects-overview");
  };

  const privacyOptions = [
    { value: 1, label: "Private" },
    { value: 2, label: "Public" },
  ];

  const handleDateSelect = (date, type) => {
    if (type === "end") {
      setProject({ ...project, due_date: moment(date).format("DD.MM.yyyy") });
    } else if (type === "start") {
      setProject({ ...project, start_date: moment(date).format("DD.MM.yyyy") });
    }
  };

  const handleCloseModal = () => {
    setShowTaskModal(false);
  };

  const handleOpenModal = (type) => {
    setActionType(type);
    setShowTaskModal(true);
  };

  const handleTaskEdit = (e, id) => {
    e.preventDefault();
    doFetchSingleUserTask(id);
    handleOpenModal("edit");
  };

  const handleTaskDelete = (id) => {
    setProjectTasks(projectTasks.filter((task) => task?.id !== id));
    doDeleteTask(id);
  };

  return (
    <div className="projects-wrapper">
      <div className="projects-header header-action">
        <h2>New Project</h2>
        <Button handleClick={handleSave}>Save and Create</Button>
      </div>
      <Form className="project-form-wrapper">
        <div className="form-section">
          <h2>Add Project Details</h2>
          <Input
            variant="project-form-group"
            label="What is your project?"
            placeholder="Enter title of project"
            name="name"
            handleChange={handleChange}
            value={project?.name}
          />
          <Input
            variant="project-form-group"
            label="Description"
            placeholder="Enter description"
            name="description"
            value={project?.description}
            handleChange={handleChange}
          />
          <div className="project-form-group form-group project-flex-input">
            <div>
              <label>Start</label>
              <div className="input-icon-wrapper date-picker-holder">
                <DatePickerIcon />
                <DatePicker
                  onChange={(date) => handleDateSelect(date, "start")}
                  dateFormat="dd.MM.yyyy"
                  placeholderText="Select date"
                  minDate={subMonths(new Date(), 256)}
                  maxDate={addMonths(new Date(), 256)}
                  showMonthDropdown
                  showYearDropdown
                  value={project?.start_date || ""}
                />
              </div>
            </div>
            <div>
              <label>End</label>
              <div className="input-icon-wrapper date-picker-holder">
                <DatePickerIcon />
                <DatePicker
                  onChange={(date) => handleDateSelect(date, "end")}
                  dateFormat="dd.MM.yyyy."
                  placeholderText="Select date"
                  minDate={subMonths(new Date(), 256)}
                  maxDate={addMonths(new Date(), 256)}
                  showMonthDropdown
                  showYearDropdown
                  value={project?.due_date || ""}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-section">
          <h3>Share Details</h3>
          <div className="project-form-group form-group">
            <label>What is the project privacy settings?</label>
            <Select
              name="privacy"
              placeholder="Select Privacy"
              options={privacyOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(event) => handleChange(event, "privacy")}
              value={privacyOptions?.find(
                (item) => item?.value === project?.privacy
              )}
            />
          </div>
          <div className="project-form-group form-group">
            <label>Users</label>
            <Select
              name="users"
              placeholder="Select Users"
              options={allUsers}
              className="basic-multi-select users-select"
              classNamePrefix="select"
              isMulti
              isSearchable
              onChange={(event) => handleMultiSelect(event, "users")}
            />
          </div>
        </div>
        <div className="form-section">
          <h3>Notifications</h3>
          <p>When should users be reminded on their tasks and projects?</p>
          <div className="project-form-group form-group project-flex-input">
            <div className="project-form-group form-group">
              <label>Notification Level</label>
              <Select
                name="notificationLevel"
                placeholder="Select Level"
                options={projectNotificationTypes.levels}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(event) => handleChange(event, "notificationLevel")}
              />
            </div>
            <div className="project-form-group form-group">
              <label>Send Notifcation</label>
              <Select
                name="notificationTime"
                placeholder="Select When"
                options={projectNotificationTypes.times}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(event) => handleChange(event, "notificationTime")}
              />
            </div>
            <div className="project-form-group form-group">
              <label>Notification Type</label>
              <Select
                name="notificationType"
                placeholder="Select Type"
                options={projectNotificationTypes.types}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(event) => handleChange(event, "notificationType")}
              />
            </div>
          </div>
        </div>
        <div className="form-section">
          <h3>Project Tasks</h3>
          <div className="project-tasks-list">
            <table>
              <tbody>
                {projectTasks.length > 0 &&
                  projectTasks.map((task, index) => (
                    <tr key={index} className="task">
                      <td>{task?.title}</td>
                      <td>{task?.description}</td>
                      <td>
                        <CalendarIcon />
                        {`${task?.settings?.startDate} - ${task?.settings?.endDate}`}
                      </td>
                      <td>
                        <AttachmentIcon />
                        {`${task?.resources.length} ${
                          task?.resources.length === 1
                            ? "Attachment"
                            : "Attachments"
                        }`}
                      </td>
                      <td>
                        <div className="actions">
                          <Button
                            variant="action"
                            handleClick={(e) => handleTaskEdit(e, task?.id)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            variant="action"
                            handleClick={() => handleTaskDelete(task?.id)}
                          >
                            <DeleteIcon />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="add-field" onClick={() => handleOpenModal("create")}>
            <PlusIcon />
            <span>Add task</span>
          </div>
        </div>
      </Form>
      <ProjectTaskForm
        showTaskModal={showTaskModal}
        handleCloseTaskModal={handleCloseModal}
        projectPrivacy={project.privacy}
        taskFormType={actionType}
        selectedUsers={selectedUsersOptions}
      />
    </div>
  );
};

export default ProjectForm;
