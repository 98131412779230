import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Dropdown, Form, Input, TypeAhead, Button, UserIcon } from "../../ui";
import { DatePickerIcon, SearchIcon } from "../../../icons";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTeams,
  createTemplate,
  fetchBehaviours,
  fetchSkills,
  fetchInterests,
  fetchResponsibilities,
  fetchSingleUser,
  fetchTemplates,
  createMeeting,
  fetchUsersByManager,
  fetchMeetingTypes,
} from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { useNavigate } from "react-router-dom";
import {
  clearSingleTemplate,
  resetPerformanceMessages,
  setPerformanceMessage,
} from "../../../store/performance/performanceSlice";
import { setActiveViewData } from "../../../store/pageViews/pageViewsSlice";
import { resetSingleUser } from "../../../store/users/usersSlice";
import { convertTextToDate, formatShortDate } from "../../../utils/textFormat";
import { useToastMessage } from "../../../hooks/useToastMessage";

const DashboardForm = () => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [newPerformance, setNewPerformance] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersOptions, setUsersOptions] = useState([]);

  const [doFetchManagedUsers] = useThunk(fetchUsersByManager);
  const [doFetchSingleUser] = useThunk(fetchSingleUser);
  const [doFetchTeams] = useThunk(fetchTeams);
  const [doCreateTemplate] = useThunk(createTemplate);
  const [doFetchBehaviours] = useThunk(fetchBehaviours);
  const [doFetchSkills] = useThunk(fetchSkills);
  const [doFetchInterests] = useThunk(fetchInterests);
  const [doFetchResponsibilities] = useThunk(fetchResponsibilities);
  const [doFetchTemplates] = useThunk(fetchTemplates);
  const [doCreateMeeting] = useThunk(createMeeting);
  const [doFetchMeetingTypes] = useThunk(fetchMeetingTypes);

  const [doShowToastMessage] = useToastMessage(resetPerformanceMessages);

  const managedUsersState = useSelector((state) => state?.users)?.data;
  const singleUserState = useSelector((state) => state.users)?.singleUser;
  const teamsState = useSelector((state) => state.teams)?.teams?.teamData;
  const singleTemplateState = useSelector(
    (state) => state.performance
  )?.singleTemplate;
  const behavioursState = useSelector((state) => state.behaviours)?.behaviours;
  const skillsState = useSelector((state) => state.skills)?.skills;
  const interestsState = useSelector((state) => state.interests)?.interests;
  const responsibilitiesState = useSelector(
    (state) => state.responsibilities
  )?.responsibilities;
  const pageViewDataState = useSelector((state) => state?.pageViews)?.data;
  const messageState = useSelector((state) => state?.performance)?.message;
  const meetingTypesState = useSelector(
    (state) => state.meetingTypes
  )?.meetingTypes;

  useEffect(() => {
    if (!!singleTemplateState) {
      setNewPerformance(singleTemplateState);
    } else if (!!pageViewDataState) {
      setNewPerformance({
        ...pageViewDataState,
        schedule_date: pageViewDataState?.date,
      });
    }
  }, [singleTemplateState, pageViewDataState]);

  useEffect(() => {
    doFetchManagedUsers(localUserData?.id);
    doFetchTeams({});
    doFetchBehaviours({});
    doFetchSkills({});
    doFetchInterests({});
    doFetchResponsibilities({});
    doFetchMeetingTypes();
    if (!!pageViewDataState) {
      doFetchSingleUser(pageViewDataState?.user_id);
    }
    doFetchTemplates();

    return () => {
      dispatch(clearSingleTemplate());
      dispatch(setActiveViewData(null));
      dispatch(resetSingleUser());
    };
  }, []);

  useEffect(() => {
    if (!!pageViewDataState && !!singleUserState) {
      setSelectedUser(singleUserState);
    }
  }, [singleUserState]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  useEffect(() => {
    setUsersOptions(managedUsersState);
  }, [managedUsersState]);

  const handleSelect = (name, values) => {
    setNewPerformance({
      ...newPerformance,
      [name]: values?.map((value) => value.value),
    });
  };

  const handleChange = (event, customName) => {
    if (customName) {
      setNewPerformance({
        ...newPerformance,
        [customName]: event.value || event,
      });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    setNewPerformance({ ...newPerformance, [name]: value });
  };

  const setUser = (user) => {
    setNewPerformance({ ...newPerformance, users: [user?.id] });
    setSelectedUser(user);
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      setUsersOptions(
        managedUsersState?.filter((user) =>
          `${user?.first_name} ${user?.last_name}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setUsersOptions(managedUsersState);
    }
  };

  const handleSaveDraft = (e) => {
    e.preventDefault();
    const payload = { ...newPerformance, status: 3 };
    doCreateTemplate(payload);
  };

  const handleSaveTemplate = (e) => {
    e.preventDefault();
    const payload = { ...newPerformance, status: 2 };
    doCreateTemplate(payload);
    navigate("/personal-performance/dashboard");
  };

  const handleSendPerformance = (e) => {
    e.preventDefault();
    const reviewPayload = {
      ...newPerformance,
      status: 1,
      sender: localUserData?.id,
    };
    const meetingPayload = {
      title: newPerformance?.title,
      organizer: localUserData?.id,
      others: [selectedUser?.email],
      duration: newPerformance?.duration,
      status: 3,
      type:
        meetingTypesState?.find((type) => type?.name === "Performance review")
          ?.id || null,
      meeting_date: newPerformance?.schedule_date,
    };

    if (
      !!newPerformance?.schedule_date &&
      !!newPerformance?.duration &&
      !!selectedUser
    ) {
      doCreateMeeting(meetingPayload);
      doCreateTemplate(reviewPayload);

      navigate("/personal-performance/dashboard");
      dispatch(
        setPerformanceMessage({
          type: "success",
          title: "Success",
          message: "Performance created successfully",
        })
      );
    } else {
      dispatch(
        setPerformanceMessage({
          type: "error",
          title: "Error",
          message: "Please select user, schedule date and duration",
        })
      );
    }
  };

  return (
    <div className="dashboard-flex-item w-60">
      <div className="dashboard-content-head dashboard-block dashboard-title">
        <p>Performance Name/Title</p>
        <Input
          name="title"
          placeholder="Add title..."
          handleChange={handleChange}
          value={newPerformance?.title}
        />
      </div>
      <div className="dashboard-content-head dashboard-block">
        <p>Select users</p>
        <div className="dashboard-search-wrapper">
          <TypeAhead
            icon={<SearchIcon />}
            slug={(option) => `${option.first_name} ${option.last_name}`}
            options={usersOptions || []}
            handleSearch={(searchTerm) => handleSearch(searchTerm)}
            handleSelect={setUser}
            placeholder="Search for an user"
          />
        </div>
      </div>
      <div className="dashboard-block">
        <h3>Performance form</h3>
        <ul className="dashboard-table-labels">
          <li>Name Surname</li>
          <li>Position</li>
          <li>Team</li>
          <li>Experience</li>
        </ul>
      </div>
      <div className="dashboard-block">
        <ul className="dashboard-table-content">
          {!!selectedUser ? (
            <>
              <li className="flex items-center gap-4">
                <UserIcon
                  userImage={selectedUser?.image || selectedUser.photoUrl}
                  userName={selectedUser?.first_name}
                />
                {selectedUser?.first_name} {selectedUser?.last_name}
              </li>
              <li>{selectedUser?.position}</li>
              <li>
                {
                  teamsState?.find((item) => item?.id === selectedUser?.team)
                    ?.name
                }
              </li>
              <li>{selectedUser?.experienceYears} years of experience</li>
            </>
          ) : (
            <>
              <li>--</li>
              <li>--</li>
              <li>--</li>
              <li>--</li>
            </>
          )}
        </ul>
      </div>
      <div className="dashboard-block">
        <Form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <div className="date-picker-holder">
                <div className="input-icon-wrapper">
                  <DatePickerIcon />
                  <DatePicker
                    placeholderText="Select date"
                    showMonthDropdown
                    showYearDropdown
                    showTimeInput
                    timeIntervals={15}
                    dateFormat="dd.MM.yyyy. HH:mm"
                    onChange={(e) =>
                      handleChange(
                        formatShortDate(e, "DD.MM.YYYY HH:mm"),
                        "schedule_date"
                      )
                    }
                    selected={convertTextToDate(
                      newPerformance?.schedule_date,
                      "DD.MM.YYYY HH:mm"
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Duration</label>
              <Input
                type="number"
                name="duration"
                placeholder="Duration"
                value={newPerformance?.duration}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Responsibilities</label>
              <Dropdown
                options={responsibilitiesState?.map((responsibility) => ({
                  value: responsibility?.id,
                  label: responsibility?.name,
                }))}
                placeholder="Select responsibilities"
                isMultiSelect={true}
                name="responsibilities"
                handleSelect={handleSelect}
                preselected={
                  singleTemplateState?.responsibilities?.map(
                    (responsibility) => {
                      return {
                        value: responsibility?.id,
                        label: responsibility?.responsibility,
                      };
                    }
                  ) || []
                }
              />
            </div>
            <div className="form-group">
              <label>Behaviours for the next level</label>
              <Dropdown
                options={behavioursState?.map((behaviour) => ({
                  value: behaviour?.id,
                  label: behaviour?.name,
                }))}
                placeholder="Select behaviours"
                isMultiSelect={true}
                name="behaviours"
                handleSelect={handleSelect}
                preselected={
                  singleTemplateState?.behaviours?.map((behaviour) => {
                    return {
                      value: behaviour?.id,
                      label: behaviour?.behaviour,
                    };
                  }) || []
                }
              />
            </div>
          </div>
          <div className="form-row py-6">
            <div className="form-group">
              <label>Interests</label>
              <Dropdown
                options={interestsState?.map((interest) => ({
                  value: interest?.id,
                  label: interest?.name,
                }))}
                placeholder="Select interests"
                isMultiSelect={true}
                name="interests"
                handleSelect={handleSelect}
                preselected={
                  singleTemplateState?.interests?.map((interest) => {
                    return {
                      value: interest?.id,
                      label: interest?.interest,
                    };
                  }) || []
                }
              />
            </div>
            <div className="form-group">
              <label>Skills</label>
              <Dropdown
                options={skillsState?.map((skill) => ({
                  value: skill?.id,
                  label: skill?.name,
                }))}
                placeholder="Select skills"
                isMultiSelect={true}
                name="skills"
                handleSelect={handleSelect}
                preselected={
                  singleTemplateState?.skills?.map((skill) => {
                    return {
                      value: skill?.id,
                      label: skill?.skill,
                    };
                  }) || []
                }
              />
            </div>
          </div>
          <div className="form-group">
            <label>Company culture</label>
            <Input
              type="textarea"
              name="company_culture"
              placeholder="Type here..."
              handleChange={handleChange}
              value={newPerformance?.company_culture || ""}
            />
          </div>
          <div className="form-group">
            <label>Growth opportunities</label>
            <Input
              type="textarea"
              name="growth_opportunities"
              placeholder="Type here..."
              handleChange={handleChange}
              value={newPerformance?.growth_opportunities || ""}
            />
          </div>
          <div className="form-group">
            <label>Compensation information</label>
            <Input
              type="textarea"
              name="compensation_information"
              placeholder="Type here..."
              handleChange={handleChange}
              value={newPerformance?.compensation_information || ""}
            />
          </div>
          <div className="flex justify-end gap-16">
            <Button handleClick={(e) => handleSaveDraft(e)}>Save draft</Button>
            <Button handleClick={(e) => handleSaveTemplate(e)}>
              Save as Template
            </Button>
            <Button handleClick={(e) => handleSendPerformance(e)}>
              Send performance
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default DashboardForm;
