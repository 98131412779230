import "./DailyPulse.scss";
import { useEffect, useState } from "react";
import { TipOfArrow, ThumbsUpOrDown } from "../../icons";
import { FatArrow } from "../../icons";
import { getFirstAndLastWorkingDaysThisWeek } from "../../utils/textFormat";
import { useThunk } from "../../hooks/useThunk";
import { createDailyPulse, fetchDailyPulseSingle } from "../../store";
import { useSelector } from "react-redux";
import moment from "moment";
import DailyPulsePopup from "./DailyPulsePopup";

const colorGood = "#00BFA6";
const colorBad = "#E8D52D";

const DailyPulse = () => {
  let userId = JSON.parse(localStorage.getItem("authUserData"))?.id;
  const today = moment().format("DD.MM.YY");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [firstDay, lastDay, workingWeekDays] =
    getFirstAndLastWorkingDaysThisWeek();
  const [doFetchDailyPulse, isLoading] = useThunk(fetchDailyPulseSingle);
  const [doCreateDailyPulse] = useThunk(createDailyPulse);
  const [pulse, setPulse] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const dailyPulseState = useSelector(
    (state) => state.dailyPulse?.singleUserData
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    doFetchDailyPulse({
      user: userId,
      from: firstDay,
      to: lastDay,
    });
  }, [userId]);

  useEffect(() => {
    if (dailyPulseState[today]?.grades?.length) {
      setPulse(dailyPulseState[today]?.grades[0]?.grade);
    }
  }, [dailyPulseState]);

  const renderListDays = () => {
    return workingWeekDays.map((day, index) => {
      const grade = dailyPulseState?.[day.dateDay]?.grades?.[0]?.grade;

      return (
        <div key={day.dateDay} className="date-day">
          <span>{day.dayName}</span>
          {!isLoading && (
            <span className={"grade"}>
              {grade === 1 ? (
                <ThumbsUpOrDown size="8" fill={colorGood} variant="up" />
              ) : grade === 0 ? (
                <ThumbsUpOrDown size="8" fill={colorBad} variant="down" />
              ) : null}
            </span>
          )}
        </div>
      );
    });
  };

  const handleMoodClick = (grade) => {
    if (dailyPulseState?.[today]?.grades?.length) {
      return;
    }
    setPulse(grade);
    doCreateDailyPulse({ user_id: userId, grade: grade });
  };

  // const totalAverageGrade = parseInt(
  //   dailyPulseState?.[today]?.grades?.[0]?.average_grade * 100
  // );

  return (
    <div className="daily-pulse-component">
      <div className="daily-pulse-wrapper">
        <div className="daily-pulse-header">
          {windowWidth >= 767 && (
            <p className="daily-pulse-title">Daily Pulse</p>
          )}
          <div>
            {"W"}
            {"64"} <FatArrow fill={colorGood} />
          </div>
          {windowWidth < 767 && (
            <div className="expand" onClick={() => setShowPopup(!showPopup)}>
              <TipOfArrow fill="#263E4E" variant={!showPopup ? "up" : "down"} />
            </div>
          )}
        </div>
        {windowWidth >= 767 && (
          <div>
            <p>How do you feel today?</p>
          </div>
        )}
        <div className="moods">
          <div className="mood-picker">
            <div className="thumbs" onClick={() => handleMoodClick(1)}>
              <ThumbsUpOrDown
                variant="up"
                fill={pulse === 1 ? colorGood : undefined}
                size={windowWidth > 767 ? "22" : "18"}
              />
            </div>
            <div className="thumbs" onClick={() => handleMoodClick(0)}>
              <ThumbsUpOrDown
                variant="down"
                fill={pulse === 0 ? colorBad : undefined}
                size={windowWidth > 767 ? "22" : "18"}
              />
            </div>
          </div>
          {windowWidth >= 767 && (
            <div className="list-days">{renderListDays()}</div>
          )}
        </div>

        {windowWidth <= 767 && showPopup && (
          <DailyPulsePopup
            dailyPulse={dailyPulseState}
            handleMoodClick={handleMoodClick}
            renderListDays={renderListDays}
            colorGood={colorGood}
            colorBad={colorBad}
            pulse={pulse}
          />
        )}
      </div>
    </div>
  );
};
export default DailyPulse;
