import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBehaviours,
  createBehaviour,
  updateBehaviour,
  deleteBehaviour,
} from "./behavioursThunks";

export const behavioursSlice = createSlice({
  name: "behaviours",
  initialState: {
    behaviours: [],
    loading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetBehaviourMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBehaviours.fulfilled, (state, action) => {
      state.behaviours = action.payload;
    });
    builder.addCase(createBehaviour.fulfilled, (state, action) => {
      state.behaviours.push(action.payload);
    });
    builder.addCase(updateBehaviour.fulfilled, (state, action) => {
      const index = state.behaviours.findIndex(
        (behaviour) => behaviour.id === action.payload.id
      );
      state.behaviours[index] = action.payload;
    });
    builder.addCase(deleteBehaviour.fulfilled, (state, action) => {
      state.behaviours = state.behaviours.filter(
        (behaviour) => behaviour.id !== action.meta.arg
      );
    });
  },
});

export const { resetBehaviourMessage } = behavioursSlice.actions;

export const behavioursReducer = behavioursSlice.reducer;
