import React, { useState, useEffect, useRef } from "react";
import "./Messages.scss";
import moment from "moment/moment";
import parse from "html-react-parser";
import { UserIcon } from "../../ui";

function Messages({ messages, members }) {
  const [allMessages, setAllMessages] = useState([]);
  const [unavailablePeople, setUnavailablePeople] = useState([]);
  const messagesEndRef = useRef(null);

  const absenceReasons = [
    { value: 1, label: "busy" },
    { value: 2, label: "forgot" },
    { value: 3, label: "other" },
    { value: 4, label: "sick" },
    { value: 5, label: "on travel" },
    { value: 6, label: "on vacation" },
  ];

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
    });
  };

  // This useEffect will log new messages to the console
  useEffect(() => {
    if (messages.length > allMessages.length) {
      const newMessages = messages.slice(allMessages.length);
      newMessages.forEach((message) => {
        console.log("New message: ", message);
      });
    }
    setAllMessages(messages);
  }, [messages, allMessages]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 10);
  }, [messages]);

  useEffect(() => {
    const unavailable = members.filter(
      (user) => !user.availability && !!user.status
    );
    setUnavailablePeople(unavailable);
  }, [members]);

  return (
    <div className="chat-main messages-wrapper">
      <div className="messages-list" ref={messagesEndRef}>
        {allMessages?.map((message, index) => (
          <div className="messages-item" key={index}>
            <UserIcon userImage={message?.userImage} userName={message?.user} />
            <div className="single-message">
              <h4>
                {message.user}
                <span>
                  {new Date(
                    message?.timestamp?.seconds * 1000
                  ).toLocaleDateString()}{" "}
                  {new Date(
                    message?.timestamp?.seconds * 1000
                  ).toLocaleTimeString()}
                </span>
              </h4>
              <p className="message-body">{parse(message.message)}</p>
            </div>
          </div>
        ))}
        <div className="unavailable-users">
          {unavailablePeople.map((user, index) => (
            <div className="unavailable-item" key={index}>
              <div className="text-center">
                <p>
                  {user.name} is{" "}
                  {
                    absenceReasons.find((item) => item.value === user.status)
                      .label
                  }
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Messages;
