import { useSelector } from "react-redux";
import { useThunk } from "../../hooks/useThunk";
import { UserIcon } from "../ui";
import "./styles.scss";
import { useEffect } from "react";
import { fetchTeams } from "../../store";
const UserCard = () => {
  let user = JSON.parse(localStorage.getItem("authUserData"));

  const [doFetchTeams] = useThunk(fetchTeams);

  const teamsState = useSelector((state) => state?.teams?.teams)?.optionData;

  useEffect(() => {
    doFetchTeams({});
  }, []);

  return (
    <div className="user-card-wrapper">
      <div className="user-left">
        <div className="user-top flex gap-16">
          <div className="user-name">
            <p>{user?.first_name}</p>
            <p>{user?.last_name}</p>
            {/* TODO: zameniti sa podacima u bazi kada ih budemo imali */}
            <p className="secondary-text">NYC - USA</p>
          </div>
          <UserIcon
            userName={user?.first_name}
            userImage={user?.image}
            iconStyle={{ width: "50px", height: "50px", fontSize: "30px" }}
          />
        </div>
        <div className="user-bottom">
          <p>{user?.position}</p>
          <p>{user?.seniorityLevel}</p>
        </div>
      </div>
      <div className="user-right">
        <div className="user-bordered">
          <p>{teamsState?.find((team) => team?.id === user?.team)?.name}</p>
        </div>
        <div>
          <p>Degree</p>
          <p className="secondary-text">Degree name</p>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
