import { useEffect, useState } from "react";
import "./styles.scss";
import ReviewRow from "./helperComponents/ReviewRow";
import { performanceReviewExpectationOptions } from "../../../../data/types";
import Point from "../../../ui/Timeline/Point";
import { Filter } from "../../../ui";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ReviewLists = ({ selectedUser }) => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const location = useLocation();
  const { state } = location;

  const assignedReviewsState = useSelector(
    (state) => state?.performance?.reviews
  )?.assigned;
  const completedReviewState = useSelector(
    (state) => state?.performance?.reviews
  )?.completed;

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [previousReviews, setPreviousReviews] = useState(
    completedReviewState || []
  );
  const [assignedReviews, setAssignedReviews] = useState(
    assignedReviewsState || []
  );

  useEffect(() => {
    setAssignedReviews(assignedReviewsState || []);
  }, [assignedReviewsState]);

  useEffect(() => {
    setPreviousReviews(completedReviewState || []);
  }, [completedReviewState]);

  const handleFilterChange = (e) => {
    setSelectedFilter(parseInt(e.target.value));
    setPreviousReviews(
      previousReviews.filter(
        (review) =>
          review?.conclusion ===
          performanceReviewExpectationOptions[e.target.value].value
      )
    );
  };

  const handleResetFilters = () => {
    setSelectedFilter(null);
    setPreviousReviews(completedReviewState);
  };

  return (
    <div className="review-lists">
      <div className="flex flex-col gap-8 list">
        <h4>Assigned reviews</h4>
        {assignedReviews?.length === 0 ? (
          <div className="flex flex-col gap-8">
            <p>No assigned reviews</p>
          </div>
        ) : (
          assignedReviews?.map((review, index) => (
            <ReviewRow
              key={index}
              reviewData={review}
              isEditable={
                selectedUser?.id === localUserData?.id ||
                selectedUser?.manager === localUserData?.id ||
                false
              }
              preExpanded={review?.id === state?.expandedAssignedReviewId}
            />
          ))
        )}
      </div>
      <div className="flex flex-col gap-8 list">
        <div className="flex justify-between">
          <div className="flex gap-16">
            <h4>List of previous reviews</h4>
            {performanceReviewExpectationOptions?.map((option, index) => (
              <Point
                key={index}
                color={option.color}
                label={option.label}
                legend={true}
              />
            ))}
          </div>
          <Filter
            filters={performanceReviewExpectationOptions?.map(
              (option) => option.label
            )}
            onRadioChange={handleFilterChange}
            checked={selectedFilter}
            onReset={handleResetFilters}
          />
        </div>
        {previousReviews.length === 0 ? (
          <div className="flex flex-col gap-8">
            <p>No previous reviews</p>
          </div>
        ) : (
          previousReviews?.map((review, index) => (
            <ReviewRow
              key={index}
              reviewData={review}
              showConclusionMark={true}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ReviewLists;
