import React, { useState, useEffect } from "react";
import Datepicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { useThunk } from "../../../hooks/useThunk";
import { setSurveyCreator } from "../../../store/surveys/surveysSlice";
import { fetchTeams } from "../../../store";

import { Button, Input, Switcher } from "../../ui";
import DropdownSelect from "../../DropdownSelect/DropdownSelect";

import { CopyIcon, DoubleArrow } from "../../../icons";

import "./styles.scss";
import { convertTextToDate, formatShortDate } from "../../../utils/textFormat";
import { setToastMessage } from "../../../store/toastMessages/toastMessagesSlice";
import { useNavigate } from "react-router-dom";

const NewSurvey = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState({ isAnonymous: false });
  const [isEdit, setIsEdit] = useState(false);
  const [teams, setTeams] = useState([]);

  const surveyData = useSelector((state) => state.pageViews)?.data;
  const teamsState = useSelector((state) => state.teams)?.teams?.teamData;

  const [doFetchTeams] = useThunk(fetchTeams);

  useEffect(() => {
    doFetchTeams({});
  }, []);

  useEffect(() => {
    if (teamsState?.length > 0) {
      const updatedTeams = teamsState?.map((team) => {
        return { value: team?.id, label: team?.name };
      });
      setTeams([{ value: "all", label: "Company wide" }, ...updatedTeams]);
    }
  }, [teamsState]);

  useEffect(() => {
    if (surveyData?.isEdit) {
      setIsEdit(true);
      setSurvey(surveyData?.row);
    }
  }, [surveyData]);

  const reminderOptions = [
    { value: 1, label: "No reminder" },
    { value: 2, label: "One day before ending" },
    { value: 3, label: "Two days before ending" },
  ];

  const handleStartClick = () => {
    if (!survey?.startDate || !survey?.dueDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start and end date.",
          type: "error",
        })
      );
      return;
    }
    if (!survey?.receiver || survey?.receiver?.length === 0) {
      dispatch(
        setToastMessage({
          title: "Receiver error",
          message: "You have to add receiver.",
          type: "error",
        })
      );
      return;
    }

    if (survey?.receiver.includes("all")) {
      survey.receiver = teamsState.map((team) => team.id);
    }

    dispatch(setSurveyCreator(survey));
    navigate("/survey/questions");
  };

  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;

    if (name === "receiver" && value.includes("all")) {
      setSurvey({
        ...survey,
        receiver: survey?.receiver?.includes("all") ? [] : ["all"],
      });
    } else {
      setSurvey({ ...survey, [name]: value });
    }
  };

  const handleSwitcherChange = (name, isChecked) => {
    setSurvey({ ...survey, [name]: isChecked });
  };

  const handleStartDateChange = (date) => {
    setSurvey({ ...survey, startDate: formatShortDate(date) });
  };

  const handleEndtDateChange = (date) => {
    setSurvey({ ...survey, dueDate: formatShortDate(date) });
  };

  return (
    <div className="new-survey-wrapper">
      <div className="survey-options">
        <div className="survey-details">
          <Input
            label="Survey title"
            placeholder="Survey title"
            name="title"
            value={survey?.title || ""}
            handleChange={handleChange}
          />
          <Input
            label="Survey description"
            placeholder="Survey description"
            name="description"
            value={survey?.description || ""}
            handleChange={handleChange}
          />
          <div className="inline-group">
            <div className="switcher-wrapper">
              <label>Anonymous</label>
              <Switcher
                handleChange={handleSwitcherChange}
                name="isAnonymous"
                checked={survey?.isAnonymous}
              />
            </div>
            <DropdownSelect
              name="reminder"
              preselected={
                reminderOptions?.find(
                  (option) => option.value === survey?.reminder
                ) || ""
              }
              placeholder="Reminder"
              options={reminderOptions}
              handleSelect={handleChange}
              customStyle={{
                width: "100%",
                height: "56px",
              }}
            />
          </div>
          <div className="block-group input-wrapper">
            <label>Survey receiver</label>
            <DropdownSelect
              name="receiver"
              preselected={
                teams?.filter((team) => {
                  if (survey?.receiver?.includes(team?.value)) {
                    return team;
                  } else {
                    return null;
                  }
                }) || []
              }
              options={teams}
              isMultiselect={true}
              customStyle={{ maxWidth: "unset" }}
              handleSelect={handleChange}
            />
          </div>
          <div className="inline-group last">
            {/* <div className="switcher-wrapper">
              <label>Anyone with the link</label>
              <Switcher
                name="anyone_with_link"
                checked={survey?.anyone_with_link}
                handleChange={handleSwitcherChange}
              />
            </div>
            <div className="block-group">
              <label>Survey link</label>
              <div className="link-holder">
                {"link"}
                <Button variant="control">
                  <CopyIcon />
                </Button>
              </div>
            </div> */}
          </div>
          <Button handleClick={handleStartClick}>
            <DoubleArrow /> Start
          </Button>
        </div>
        <div className="survey-dates">
          <div className="datepicker-wrapper">
            <label>Start date</label>
            <div className="date-picker-holder">
              <Datepicker
                name="startDate"
                value={survey?.startDate || ""}
                selected={
                  survey?.startDate ? convertTextToDate(survey?.startDate) : ""
                }
                onChange={handleStartDateChange}
                dateFormat="dd.MM.yyyy"
                inline
                showMonthDropdown
                showYearDropdown
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="datepicker-wrapper">
            <label>End date</label>
            <div className="date-picker-holder">
              <Datepicker
                name="dueDate"
                value={survey?.dueDate || ""}
                selected={
                  survey?.dueDate ? convertTextToDate(survey?.dueDate) : ""
                }
                onChange={handleEndtDateChange}
                dateFormat="dd.MM.yyyy"
                inline
                showMonthDropdown
                showYearDropdown
                minDate={new Date()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSurvey;
