import { createSlice } from "@reduxjs/toolkit";

import {
  fetchCertificationPrograms,
  fetchCertificationProgram,
  createCertificationProgram,
  updateCertificationProgram,
  deleteCertificationProgram,
} from "./certificationProgramThunks";

const certificationProgramSlice = createSlice({
  name: "certificationProgram",
  initialState: {
    data: [],
    singleCertificationProgram: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetCertificationProgramMessage: (state) => {
      state.message = null;
    },
    resetSingleCertificationProgram: (state) => {
      state.singleCertificationProgram = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCertificationPrograms.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchCertificationProgram.fulfilled, (state, action) => {
      state.singleCertificationProgram = action.payload;
    });
    builder.addCase(deleteCertificationProgram.fulfilled, (state, action) => {
      const filteredCertificationPrograms = state?.data?.filter(
        (certificationProgram) => certificationProgram.id !== action.meta.arg
      );
      state.data = filteredCertificationPrograms;
      state.message = {
        type: "success",
        title: "Success",
        message: "Certification Program successfully deleted",
      };
    });
    builder.addCase(createCertificationProgram.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Certification Program successfully created",
      };
    });
    builder.addCase(updateCertificationProgram.fulfilled, (state, action) => {
      state.data = state.data.map((certificationProgram) =>
        certificationProgram.id === action.payload.id
          ? action.payload
          : certificationProgram
      );
      state.message = {
        type: "success",
        title: "Success",
        message: "Certification Program successfully updated",
      };
    });
  },
});

export const {
  resetCertificationProgramMessage,
  resetSingleCertificationProgram,
} = certificationProgramSlice.actions;

export const certificationProgramReducer = certificationProgramSlice.reducer;
