import { useEffect, useState } from "react";
import {
  fetchAchievements,
  createAchievement,
  updateAchievement,
  deleteAchievement,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

import { Pagination, Table } from "../../../ui";
import AchievementsModal from "./AchievementsModal";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetAchievementsMessage } from "../../../../store/achievements/achievementsSlice";

const Achievements = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;
  const [page, setPage] = useState(1);

  const [doFetchAchievements] = useThunk(fetchAchievements);
  const [doCreateAchievement, isLoading, error] = useThunk(createAchievement);
  const [doUpdateAchievement] = useThunk(updateAchievement);
  const [doDeleteAchievement] = useThunk(deleteAchievement);
  const [doShowToastMessage] = useToastMessage(resetAchievementsMessage);

  const achievementsState = useSelector(
    (state) => state.achievements
  )?.achievements;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const formType = useSelector((state) => state.form)?.type;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state.achievements)?.message;

  useEffect(() => {
    if (!isLoading) {
      doFetchAchievements({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchAchievements]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const achievementStatuses = [
    { value: 1, label: "Not started", color: "rest" },
    { value: 2, label: "Working", color: "purple" },
    { value: 3, label: "Parked", color: "yellow" },
    { value: 4, label: "Blocked", color: "red" },
    { value: 5, label: "Completed", color: "green" },
  ];

  const achievementTypes = [
    { value: 1, label: "Personal" },
    { value: 2, label: "Team" },
    { value: 3, label: "Company" },
  ];

  const tableData = {
    rows: achievementsState?.achievementData,
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
      {
        id: 3,
        label: "Description",
        slug: "description",
      },
      {
        id: 4,
        label: "Status",
        slug: "status",
        colorOptions: achievementStatuses,
      },
      {
        id: 5,
        label: "Type",
        slug: "type",
        colorOptions: achievementTypes,
      },
    ],
  };

  const handleEdit = (achievement) => {
    dispatch(setFormType("edit"));
    dispatch(setFormData(achievement));
    dispatch(setActiveModal("Achievements-modal"));
  };

  const handleDelete = (achievement) => {
    doDeleteAchievement(achievement.id);
  };

  const handleSave = (achievement) => {
    if (formType === "edit") {
      doUpdateAchievement(achievement);
      handleModalClose();
      return;
    }
    doCreateAchievement(achievement);
    handleModalClose();
  };

  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };
  return (
    <div>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        hasActions={true}
      />

      <Pagination
        currentPage={page}
        total={achievementsState?.pageData?.total}
        onPageChange={setPage}
        limit={achievementsState?.pageData?.perPage}
        position="center"
      />
      {activeModalState === "Achievements-modal" ? (
        <AchievementsModal
          showModal={activeModalState === "Achievements-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Achievements;
