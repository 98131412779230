import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const createMood = createAsyncThunk(
  "checkins/mood/create",
  async (mood) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/checkin/mood-check`,
        mood
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchQuestions = createAsyncThunk(
  "checkins/questions/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkin/questions`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createAnswers = createAsyncThunk(
  "checkins/answers/create",
  async (answer) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/checkin/answer`,
        answer
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCheckinsHistory = createAsyncThunk(
  "checkins/history/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkin/history`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserCheckinsHistory = createAsyncThunk(
  "checkins/userHistory/fetch",
  async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkin/history`);
      const data = response.data;

      const filteredDataArray = Object.keys(data)
        .reduce((acc, date) => {
          const userData = data[date];
          const filteredQuestions = (userData?.questions || []).filter(
            (question) => question?.user_id === userId
          );
          const filteredGrades = (userData?.grades || []).filter(
            (grades) => grades?.user_id === userId
          );

          if (filteredQuestions.length > 0 || filteredGrades.length > 0) {
            acc.push({
              date,
              questions: filteredQuestions,
              grades: filteredGrades,
            });
          }
          return acc;
        }, [])
        .sort((a, b) => {
          const dateA = a.date.split(".").reverse().join("-");
          const dateB = b.date.split(".").reverse().join("-");

          return dateB.localeCompare(dateA);
        });

      return filteredDataArray;
    } catch (error) {
      throw error;
    }
  }
);

/* Users checkins */

export const fetchUserMoodStatsLine = createAsyncThunk(
  "checkins/statistics/user/line/fetch",
  async ({ user, range, weeks, months, years }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: {
          user,
          range,
          weeks,
          months,
          years,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserMoodStatsDoughnut = createAsyncThunk(
  "checkins/statistics/user/doughnut/fetch",
  async ({ user, range, weeks, months, years }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: {
          user,
          range,
          weeks,
          months,
          years,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

/* Your team checkins */

export const fetchUsersTeamMoodStatsLine = createAsyncThunk(
  "checkins/statistics/users-team/line/fetch",
  async ({ range, team, month, year, week }) => {
    try {
      const params = new URLSearchParams({
        range,
        year,
        month,
        week,
      });
      params.append("teams", `[${team}]`);

      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUsersTeamMoodStatsDoughnut = createAsyncThunk(
  "checkins/statistics/users-team/doughnut/fetch",
  async ({ range, teams, month }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: {
          range,
          teams,
          month,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

/* Teams checkins */
export const fetchTeamsMoodStatsLine = createAsyncThunk(
  "checkins/statistics/users-team/line/fetch",
  async ({ range, teams, months, years, weeks }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: {
          teams,
          range,
          months,
          years,
          weeks,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTeamsMoodStatsDoughnut = createAsyncThunk(
  "checkins/statistics/users-team/doughnut/fetch",
  async ({ range, teams, years, months, weeks }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: {
          range,
          teams,
          years,
          months,
          weeks,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

/* Company checkins */

export const fetchCompanyMoodStatsLine = createAsyncThunk(
  "checkins/statistics/company/line/fetch",
  async ({ range, weeks, months, years, company = 1 }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: {
          range,
          weeks,
          months,
          years,
          company,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCompanyMoodStatsDoughnut = createAsyncThunk(
  "checkins/statistics/company/doughnut/fetch",
  async ({ range, weeks, months, years, company = 1 }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/checkins-dashboard`, {
        params: {
          range,
          weeks,
          months,
          years,
          company,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
