import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const TalkingPointsChart = ({ dataPoints }) => {
  const dataValues = dataPoints?.map((item) => item?.tag_count);
  const data = {
    labels: dataPoints
      ?.map((item) => item?.tag_name)
      .slice(0, dataPoints?.length),
    datasets: [
      {
        label: "Dataset",
        data: dataValues,
        backgroundColor: "#969CB9",
        borderRadius: 6,
        barThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        height: "200px",
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default TalkingPointsChart;
