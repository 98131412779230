import { createSlice } from "@reduxjs/toolkit";

import {
  fetchPublicSpeakings,
  fetchPublicSpeaking,
  createPublicSpeaking,
  updatePublicSpeaking,
  deletePublicSpeaking,
} from "./publicSpeakingThunks";

const publicSpeakingSlice = createSlice({
  name: "publicSpeaking",
  initialState: {
    data: [],
    singlePublicSpeaking: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetPublicSpeakingMessage: (state) => {
      state.message = null;
    },
    resetSinglePublicSpeaking: (state) => {
      state.singlePublicSpeaking = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPublicSpeakings.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchPublicSpeaking.fulfilled, (state, action) => {
      state.singlePublicSpeaking = action.payload;
    });
    builder.addCase(deletePublicSpeaking.fulfilled, (state, action) => {
      const filteredPublicSpeakings = state?.data?.filter(
        (publicSpeaking) => publicSpeaking.id !== action.meta.arg
      );
      state.data = filteredPublicSpeakings;
      state.message = {
        type: "success",
        title: "Success",
        message: "Public Speaking successfully deleted",
      };
    });
    builder.addCase(createPublicSpeaking.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Public Speaking successfully created",
      };
    });
    builder.addCase(updatePublicSpeaking.fulfilled, (state, action) => {
      const updatedPublicSpeakings = state?.data?.map((publicSpeaking) => {
        if (publicSpeaking.id === action.payload.id) {
          return action.payload;
        }
        return publicSpeaking;
      });
      state.data = updatedPublicSpeakings;
      state.message = {
        type: "success",
        title: "Success",
        message: "Public Speaking successfully updated",
      };
    });
  },
});

export const { resetPublicSpeakingMessage, resetSinglePublicSpeaking } =
  publicSpeakingSlice.actions;

export const publicSpeakingReducer = publicSpeakingSlice.reducer;
