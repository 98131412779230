import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchCommitteeInvolvements = createAsyncThunk(
  "committeeInvolvement/fetch",
  async ({ user_id }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/committe-involvements`,
        {
          params: {
            user_id,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCommitteeInvolvement = createAsyncThunk(
  "committeeInvolvement/fetchById",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/committe-involvement/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createCommitteeInvolvement = createAsyncThunk(
  "committeeInvolvement/create",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/committe-involvement`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateCommitteeInvolvement = createAsyncThunk(
  "committeeInvolvement/update",
  async ({ id, data }) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/committe-involvement/${id}`,
        {
          ...data,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCommitteeInvolvement = createAsyncThunk(
  "committeeInvolvement/delete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/committe-involvement/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
