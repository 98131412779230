import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, Input, Switcher } from "../../../../ui";
import { CalendarLightIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import {
  createCertificationProgram,
  updateCertificationProgram,
} from "../../../../../store";
import { useThunk } from "../../../../../hooks/useThunk";
import { useDispatch } from "react-redux";

import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";
const CertificationPrograms = ({ userId, editData }) => {
  const [doCreateCerificationProgram] = useThunk(createCertificationProgram);
  const [doUpdateCertificationProgram] = useThunk(updateCertificationProgram);
  const defaultPlaceholder = "Type here...";
  const dispatch = useDispatch();
  const [certification, setCertification] = useState({});
  const [isOngoing, setIsOngoing] = useState(false);

  useEffect(() => {
    if (editData) {
      setCertification({
        title: editData?.name,
        institution: editData?.institution,
        field: editData?.field,
        startDate: String(editData?.start).split(" ")[0],
        endDate: editData?.end ? String(editData?.end).split(" ")[0] : null,
        requirements: editData?.requirements,
        benefits: editData?.benefits,
      });
      setIsOngoing(editData?.ongoing);
    }
  }, [editData]);

  useEffect(() => {
    setCertification((prevCertification) => ({
      ...prevCertification,
      ongoing: isOngoing,
    }));
    if (isOngoing) {
      setCertification((prevCertification) => ({
        ...prevCertification,
        endDate: null,
      }));
    }
  }, [isOngoing]);

  const handleChange = (name, value) => {
    setCertification({ ...certification, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!certification.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add Certification Name.",
          type: "error",
        })
      );
      return;
    }

    if (!certification.institution) {
      dispatch(
        setToastMessage({
          title: "Institution error",
          message: "Please add institution.",
          type: "error",
        })
      );
      return;
    }

    if (!certification.field) {
      dispatch(
        setToastMessage({
          title: "Field error",
          message: "Please add field.",
          type: "error",
        })
      );
      return;
    }

    if (!certification.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start date.",
          type: "error",
        })
      );
    }

    if (!certification.requirements) {
      dispatch(
        setToastMessage({
          title: "Requirements error",
          message: "Please add requirements.",
          type: "error",
        })
      );
      return;
    }

    if (!certification.benefits) {
      dispatch(
        setToastMessage({
          title: "Benefits error",
          message: "Please add benefits.",
          type: "error",
        })
      );
      return;
    }

    doCreateCerificationProgram({
      user_id: userId,
      name: certification.title,
      institution: certification.institution,
      field: certification.field,
      ongoing: certification.ongoing,
      requirements: certification.requirements,
      benefits: certification.benefits,
      start: certification.startDate + " 00:00:00",
      end: certification.endDate ? certification.endDate + " 00:00:00" : null,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!certification.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add Certification Name.",
          type: "error",
        })
      );
      return;
    }

    if (!certification.institution) {
      dispatch(
        setToastMessage({
          title: "Institution error",
          message: "Please add institution.",
          type: "error",
        })
      );
      return;
    }

    if (!certification.field) {
      dispatch(
        setToastMessage({
          title: "Field error",
          message: "Please add field.",
          type: "error",
        })
      );
      return;
    }

    doUpdateCertificationProgram({
      id: editData?.id,
      data: {
        name: certification.title,
        institution: certification.institution,
        field: certification.field,
        ongoing: certification.ongoing,
        requirements: certification.requirements,
        benefits: certification.benefits,
        start: certification.startDate + " 00:00:00",
        end: certification.endDate ? certification.endDate + " 00:00:00" : null,
      },
    });
  };

  return (
    <div className="certification-programs">
      <Form>
        <Input
          name="title"
          label="Certification Name"
          placeholder={defaultPlaceholder}
          value={certification?.title}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          name="institution"
          label="Institution"
          placeholder={defaultPlaceholder}
          value={certification?.institution}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          name="field"
          label="Field"
          placeholder={defaultPlaceholder}
          value={certification?.field}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Duration</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Ongoing</label>
              <Switcher
                checked={isOngoing}
                handleChange={() => setIsOngoing(!isOngoing)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label className="label-normal">Starting date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(certification?.startDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                />
                <CalendarLightIcon />
              </div>
            </div>
            <div className="form-group">
              <label className="label-normal">Ending date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(certification?.endDate)}
                  disabled={isOngoing}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                />
                <CalendarLightIcon />
              </div>
            </div>
          </div>
        </div>
        <Input
          type="textarea"
          name="requirements"
          label="Requirements"
          placeholder={defaultPlaceholder}
          value={certification?.requirements}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          type="textarea"
          name="benefits"
          label="Benefits"
          placeholder={defaultPlaceholder}
          value={certification?.benefits}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CertificationPrograms;
