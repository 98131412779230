import { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

const TypeStatisticsChart = ({ typeData, allTypes }) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const typeColors = [
    "#D9B8FF",
    "#FFD966",
    "#85E0A3",
    "#80CAFF",
    "#ffafa3",
    "#ccc",
  ];

  const [typesWithColors, setTypesWithColors] = useState([]);
  useEffect(() => {
    if (allTypes?.length) {
      const updatedTypes = allTypes?.map((type, index) => ({
        ...type,
        color: typeColors[index],
        value:
          typeData?.find((item) => item?.type_name === type?.name)
            ?.type_count || 0,
      }));
      setTypesWithColors(updatedTypes);
    }
  }, [allTypes, typeData]);

  const doughnutData = {
    labels: [],
    datasets: [
      {
        label: "Number of meetings",
        data: typesWithColors?.map((item) => item?.value),
        backgroundColor: typesWithColors?.map((item) => item?.color),
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="type-statistics-content flex justify-between">
      <div className="type-statistics-labels">
        <h4>Type</h4>
        <ul>
          {typesWithColors?.map((type) => (
            <li key={`type-statistics-${type?.id}`}>
              <span
                className="type-circle"
                style={{ backgroundColor: type?.color }}
              ></span>
              <div className="type-statistics-label">
                <p>{type?.name}</p>
              </div>
              <p>{type?.value}%</p>
            </li>
          ))}
        </ul>
      </div>
      {!!typeData?.length ? (
        <div className="type-statistics-chart">
          <div className="doughnut-wrapper" style={{ maxWidth: "200px" }}>
            <Doughnut data={doughnutData} />
          </div>
        </div>
      ) : (
        <p className="type-statistics-chart-empty">No Data</p>
      )}
    </div>
  );
};

export default TypeStatisticsChart;
