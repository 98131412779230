import { Routes, Route } from "react-router-dom";

import PersonalPerformance from "../components/PersonalPerformance";
import Dashboard from "../components/PersonalPerformance/dashboard";
import AdminProtectedRoute from "./AdminProtectedRoute";

let localUserData = JSON.parse(localStorage.getItem("authUserData"));

const PersonalPerformanceRoutes = () => (
  <Routes>
    <Route path="/" element={<PersonalPerformance />} />

    <Route
      element={
        <AdminProtectedRoute isAllowed={!!localUserData?.permissions?.length} />
      }
    >
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/form" element={<Dashboard />} />
      <Route path="/drafts" element={<Dashboard />} />
    </Route>
  </Routes>
);

export default PersonalPerformanceRoutes;
