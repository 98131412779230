import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { convertDecimalToHours } from "../../../../utils/textFormat";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const AllMeetingsChart = ({ dataPoints }) => {
  const dataValues = dataPoints?.map((item) => item?.hour);
  const data = {
    labels: dataPoints?.map((item) => item.name),
    datasets: [
      {
        label: "Dataset",
        data: dataValues,
        backgroundColor: "#969CB9",
        borderRadius: 6,
        barThickness: 30,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    scales: {
      y: {
        display: false,
      },
      x: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          display: true,
          color: "#000",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => convertDecimalToHours(context.raw),
        },
      },
      datalabels: {
        display: true,
        align: "center",
        anchor: "center",
        color: "#FFF",
        font: {
          size: 14,
        },
        formatter: (value, context) =>
          `${dataPoints[context.dataIndex]?.name} ${convertDecimalToHours(
            value
          )}`,
      },
    },
    maintainAspectRatio: false,
  };

  const chartHeight = dataPoints?.length * 50;

  return (
    <div
      className="meetings-chart-wrapper"
      style={{
        maxHeight: "250px",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          height: `${chartHeight}px`,
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default AllMeetingsChart;
