import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PriorityStatisticsChart = ({ chartData }) => {
  const [priorityData, setPriorityData] = useState([]);
  const formatData = () => {
    const colors = ["#CCC", "#FFAFA3", "#FFD966", "#85E0A3"];
    const updatedData = chartData.map((item, index) => ({
      ...item,
      color: colors[index],
    }));
    setPriorityData(updatedData);
  };
  useEffect(() => {
    if (chartData?.length) {
      formatData();
    }
  }, [chartData]);
  const data = {
    labels: [],
    datasets: [
      {
        data: priorityData?.map((item) => item?.priority_count),
        backgroundColor: priorityData?.map((item) => item?.color),
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="type-statistics-content flex justify-between">
      <div className="type-statistics-labels">
        <h4>Priority statistics</h4>
        <ul>
          {priorityData?.map((priority) => (
            <li key={`meeting-priority-${priority?.priority_name}`}>
              <span
                className="type-circle"
                style={{ backgroundColor: priority?.color }}
              ></span>
              <div className="type-statistics-label">
                <p>{priority?.priority_name}</p>
              </div>
              <p>{priority?.priority_count} %</p>
            </li>
          ))}
        </ul>
      </div>
      <div style={{ maxWidth: "180px" }}>
        <Pie data={data} />
      </div>
    </div>
  );
};

export default PriorityStatisticsChart;
