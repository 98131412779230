import React from "react";
import "./styles.scss";

const Input = ({
  icon,
  wrapperStyles = {},
  label = "",
  labelStyles = {},
  placeholder = "",
  type = "input",
  name = "",
  inputStyles = {},
  variant = "",
  value = "",
  disabled = false,
  autoFocus = false,
  handleChange = () => {},
}) => {
  return (
    <div className="input-wrapper" style={wrapperStyles}>
      {label ? <label style={labelStyles}>{label}</label> : ""}
      {!!icon && icon}
      {type === "textarea" ? (
        <textarea
          rows="3"
          placeholder={placeholder}
          name={name}
          style={inputStyles}
          value={value}
          autoFocus={autoFocus}
          onChange={handleChange}
        />
      ) : (
        <input
          placeholder={placeholder}
          type={type}
          name={name}
          style={inputStyles}
          className={`${variant} ${icon ? "icon-input" : ""}`}
          value={value}
          autoFocus={autoFocus}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default Input;
