import { useEffect, useState } from "react";
import "./employees.scss";

import {
  fetchEmployees,
  fetchUsers,
  createUser,
  updateUser,
  fetchPositions,
  createEmployee,
  deleteEmployee,
  updateEmployee,
  fetchTeams,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";
import { SearchIcon, AddIcon } from "../../../../icons";
import { Button, Input, Table } from "../../../ui";
import moment from "moment/moment";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { resetEmployeesMessage } from "../../../../store/employees/employeesSlice";
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from "firebase/auth";
import EmployeeForm from "./EmployeeForm";

const Employees = () => {
  const [doFetchEmployees] = useThunk(fetchEmployees);
  const [doCreateEmployee] = useThunk(createEmployee);
  const [doDeleteEmployee] = useThunk(deleteEmployee);
  const [doUpdateEmployee] = useThunk(updateEmployee);
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doFetchPositions] = useThunk(fetchPositions);
  const [doFetchTeams] = useThunk(fetchTeams);
  const [doShowToastMessage] = useToastMessage(resetEmployeesMessage);

  const employeesState = useSelector((state) => state.employees)?.data;
  const usersState = useSelector((state) => state.users)?.data?.userData;
  const positionsState = useSelector((state) => state.positions)?.data;
  const teamsState = useSelector((state) => state.teams)?.teams;
  const messageState = useSelector((state) => state.employees)?.message;

  useEffect(() => {
    doFetchEmployees();
    doFetchUsers({});
    doFetchPositions();
    doFetchTeams({});
  }, []);

  const [employeesData, setEmployeesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [formType, setFormType] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (!!selectedEmployee) {
      setEmployeesData(selectedEmployee);
    } else {
      setEmployeesData(null);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  useEffect(() => {
    setEmployees(employeesState);
  }, [employeesState]);

  const columns = [
    { id: 1, label: "Employee", slug: "employee" },
    { id: 2, label: "Position", slug: "positionTitle" },
    { id: 3, label: "Email", slug: "email" },
    { id: 4, label: "Phone", slug: "phone" },
    { id: 5, label: "Projects", slug: "projects" },
  ];

  const userActiveValues = [
    { value: "", label: "Select One" },
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    const employeesUsers = employeesState.map(
      (employee) => employee.employeeId
    );
    let reducedUsers = usersState?.filter((user) => {
      if (!selectedEmployee) {
        return !employeesUsers.includes(user.employeeId);
      }
      return (
        !employeesUsers.includes(user.employeeId) ||
        user.employeeId === selectedEmployee.employeeId
      );
    });

    setFilteredUsers(reducedUsers);
  }, [positionsState, usersState, selectedEmployee]);

  const filterEmployees = () => {
    const filteredEmployees = employeesState?.filter((employee) => {
      return (
        employee?.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee?.lastName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setEmployees(filteredEmployees);
  };

  useEffect(() => {
    if (!!searchTerm) {
      filterEmployees();
    } else {
      setEmployeesData(employeesState);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!filteredUsers) {
      doFetchUsers({});
    }
    const reducedUsers = filteredUsers?.map((user) => {
      return {
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
      };
    });
    setAllUsers(reducedUsers);
  }, [filteredUsers, selectedEmployee]);

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedEmployee(null);
  };

  const handleChange = (event, customName) => {
    if (customName) {
      setEmployeesData({ ...employeesData, [customName]: event.value });
      return;
    }
    const { name, value } = event.target ? event.target : event;
    if (name === "phone") {
      setEmployeesData({ ...employeesData, [name]: value.replace(/\D/g, "") });
      return;
    }
    setEmployeesData({ ...employeesData, [name]: value });
  };

  const setUserBirthday = (date) => {
    setEmployeesData({
      ...employeesData,
      birthday: moment(date).format("DD.MM.yyy"),
    });
  };

  const handleSave = () => {
    if (formType === "create") {
      doCreateEmployee({
        ...employeesData,
        uid: "",
      });

      const auth = getAuth();

      createUserWithEmailAndPassword(
        auth,
        employeesData.email,
        employeesData.password
      )
        .then((response) => {
          return updateProfile(auth.currentUser, {
            displayName: employeesData.firstName + " " + employeesData.lastName,
          });
        })
        .catch((error) => {
          console.error("Error creating user:", error);
        });
    } else {
      doUpdateEmployee(employeesData);
    }
    setShowModal(false);
    setSelectedEmployee(null);
  };

  const handleDeleteEmployee = (employeeId) => {
    doDeleteEmployee(employeeId);
  };

  const handleShowModal = (type, employee) => {
    if (type === "edit") {
      const selectedEmployees = employeesState.find(
        (selectedEmployee) =>
          selectedEmployee.employeeId === employee.employeeId
      );
      setSelectedEmployee(selectedEmployees);
    }

    setFormType(type);
    setShowModal(true);
  };

  const tableData = {
    columns: columns,
    rows: employees.map((employee) => ({
      employeeId: employee.employeeId,
      employee: `${employee.firstName} ${employee.lastName}`,
      positionTitle: employee.positionTitle || "Not assigned",
      email: employee.email,
      phone: employee.phone,
      projects:
        employee.projects.length === 0
          ? "No projects"
          : employee.projects
              .slice(0, 3)
              .map((project) => project.name)
              .join(", ") + (employee.projects.length > 3 ? "..." : ""),
    })),
  };

  return (
    <div className="carrer-content">
      <div className="career-header">
        <h2>Employees</h2>
        <div className="header-actions">
          <div className="table-search">
            <Input
              placeholder="Search"
              variant="form-control light"
              handleChange={handleSearch}
              icon={<SearchIcon />}
              value={searchTerm}
            />
          </div>
          <Button handleClick={() => handleShowModal("create")}>
            <AddIcon />
            Add employee
          </Button>
        </div>
      </div>
      <div className="career-content employee-content">
        <Table
          rows={tableData.rows}
          columns={tableData.columns}
          isAdmin={true}
          hasActions={true}
          handleEdit={(row) => handleShowModal("edit", row)}
          handleDelete={(row) => handleDeleteEmployee(row.employeeId)}
        />
        <EmployeeForm
          formType={formType}
          positionsState={positionsState}
          teamsState={teamsState}
          employeesData={employeesData}
          handleChange={handleChange}
          handleSave={handleSave}
          setUserBirthday={setUserBirthday}
          showModal={showModal}
          handleModalClose={handleModalClose}
        />
      </div>
    </div>
  );
};

export default Employees;
