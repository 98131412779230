import { useState, useEffect } from "react";
import DoughnutOverview from "./DoughnutOverview";
import ShortAnswers from "./ShortAnswers";
import "./styles.scss";
import DropdownSelect from "../../DropdownSelect/DropdownSelect";

import { useSelector } from "react-redux";
import { fetchTeams, fetchSingleSurveyByUser } from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { Button, TypeAhead } from "../../ui";
import { ResetIcon } from "../../../icons";
import IndividualOverview from "./IndividualOverview";

const Overview = () => {
  const [surveyUsers, setSurveyUsers] = useState([]);
  const [filteredSurveyUsers, setFilteredSurveyUsers] = useState([]);
  const [dropdownResetFlag, setDropdownResetFlag] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [selectedUserOption, setSelectedUserOption] = useState(null);
  const [doFetchTeams] = useThunk(fetchTeams);
  const [doFetchSingleSurveyByUser] = useThunk(fetchSingleSurveyByUser);
  const selectedSurvey = useSelector((state) => state.pageViews)?.data;
  const [currentSurvey, setCurrentSurvey] = useState(selectedSurvey);
  const singleSurveyState = useSelector((state) => state.surveys)?.singleSurvey;
  const teamsState = useSelector((state) => state.teams)?.teams?.optionData;

  useEffect(() => {
    doFetchTeams({});
  }, []);

  useEffect(() => {
    if (!teamsState?.length) {
      return;
    }
    const selectedTeams = teamsState?.filter((team) => {
      if (selectedSurvey?.receiver?.includes(team?.id)) {
        return team;
      } else {
        return null;
      }
    });
    let usersFromTeams = [];
    selectedTeams.forEach((team) => {
      const teamMembers = team?.members?.map((member) => {
        return {
          value: member?.id,
          label: `${member?.first_name} ${member?.last_name}`,
          first_name: member?.first_name,
          last_name: member?.last_name,
        };
      });
      usersFromTeams = usersFromTeams.concat(teamMembers);
    });
    setSurveyUsers(usersFromTeams);
    setFilteredSurveyUsers(usersFromTeams);
  }, [teamsState]);

  useEffect(() => {
    if (!singleSurveyState) {
      return;
    }
    setCurrentSurvey(singleSurveyState);
  }, [singleSurveyState]);

  useEffect(() => {
    if (!!currentSurvey && selectedUserOption) {
      setIsIndividual(true);
    }
  }, [currentSurvey, selectedUserOption]);

  useEffect(() => {
    setCurrentSurvey(selectedSurvey);
  }, [selectedSurvey]);

  // const handleChange = (event) => {
  //   if (!event || !event.value) {
  //     console.error("Invalid event object:", event);
  //     return;
  //   }
  //   setSelectedUserOption(event);
  // };

  const handleResetClick = () => {
    setCurrentSurvey(selectedSurvey);
    setDropdownResetFlag((prev) => !prev);
    setSelectedUserOption(null);
    setIsIndividual(false);
    setFilteredSurveyUsers(surveyUsers);
  };

  const handleSearch = (searchTerm) => {
    const filteredUsers = surveyUsers.filter((user) =>
      user?.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSurveyUsers(filteredUsers);
  };

  const handleSelect = (user) => {
    if (!user || !user.value) {
      handleResetClick();
      return;
    }
    setSelectedUserOption(user);
    doFetchSingleSurveyByUser({
      surveyId: selectedSurvey?.id,
      userId: user.value || user?.id,
    });
  };

  return (
    <div>
      <h2 className="survey-title">{selectedSurvey?.title}</h2>
      <div className="survey-overview">
        {!currentSurvey?.isAnonymous ? (
          <div className="survey-overview-question individual-results">
            <h3>Individual results</h3>
            {/* <DropdownSelect
              name="user"
              placeholder="Select participants"
              options={surveyUsers}
              handleSelect={handleChange}
              resetFlag={dropdownResetFlag}
              customStyle={{
                width: "100%",
                height: "56px",
              }}
            /> */}
            <TypeAhead
              name="user"
              placeholder="Search participants"
              options={filteredSurveyUsers || []}
              multiple={false}
              slug={(option) => option?.label}
              handleSelect={handleSelect}
              selected={selectedUserOption}
              handleSearch={(searchTerm) => handleSearch(searchTerm)}
              onRemove={handleResetClick}
              customStyle={{
                width: "100%",
                height: "56px",
              }}
            />
            {/* <div className="reset">
              <Button
                variant="control"
                handleClick={handleResetClick}
                buttonStyles={{
                  color: "#6A6565",
                  padding: "5px",
                  gap: "10px",
                }}
              >
                Reset <ResetIcon />
              </Button>
            </div> */}
          </div>
        ) : (
          ""
        )}
        {currentSurvey?.questions?.map((question) => (
          <div key={`question-${question?.id}`}>
            {question.type !== 1 ? (
              <>
                {isIndividual ? (
                  <IndividualOverview question={question} />
                ) : (
                  <DoughnutOverview question={question} />
                )}
              </>
            ) : (
              <ShortAnswers question={question} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Overview;
