import { useState, useEffect } from "react";
import { Button, Filter, Status, Timeline, TimelineList } from "../../../ui";
import { performanceReviewStatuses } from "../../../../data/statuses";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../utils/textFormat";
import { performanceReviewExpectationOptions } from "../../../../data/types";
import { useNavigate } from "react-router-dom";
import { setActiveViewData } from "../../../../store/pageViews/pageViewsSlice";
import { setFormType } from "../../../../store/form/formSlice";
import moment from "moment";

const PerformanceReviewTimeline = ({ selectedUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const positionHistory = selectedUser?.positions;

  const userReviewTemplatesState = useSelector(
    (state) => state?.users
  )?.userReviewTemplate;
  const assignedReviewsState = useSelector(
    (state) => state?.performance?.reviews
  )?.assigned;
  const completedReviewsState = useSelector(
    (state) => state?.performance?.reviews
  )?.completed;

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [timelineData, setTimelineData] = useState([]);
  const [timelineListData, setTimelineListData] = useState([]);
  const [userReviewTemplates, setUserReviewTemplates] = useState(
    userReviewTemplatesState || []
  );
  const [assignedReviews, setAssignedReviews] = useState(
    assignedReviewsState || []
  );
  const [completedReviews, setCompletedReviews] = useState(
    completedReviewsState || []
  );
  const [selectedPoint, setSelectedPoint] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setSelectedPoint(null);
    };
    if (selectedPoint) {
      window.addEventListener("scroll", handleScroll, true);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [selectedPoint]);

  useEffect(() => {
    if (!!userReviewTemplatesState) {
      setUserReviewTemplates(
        userReviewTemplatesState.map((review) => ({
          ...review,
          pointStatus: 1,
        }))
      );
    }
  }, [userReviewTemplatesState]);

  useEffect(() => {
    if (!!assignedReviewsState) {
      setAssignedReviews(
        assignedReviewsState.map((review) => ({
          ...review,
          pointStatus:
            convertTextToDate(review?.schedule_date) >= new Date() ? 3 : 4,
        }))
      );
    }
  }, [assignedReviewsState]);

  useEffect(() => {
    if (!!completedReviewsState) {
      setCompletedReviews(
        completedReviewsState?.map((review) => ({ ...review, pointStatus: 2 }))
      );
    }
  }, [completedReviewsState]);

  useEffect(() => {
    setTimelineData(
      [...userReviewTemplates, ...assignedReviews, ...completedReviews]
        .map((review) => ({
          ...review,
          date: review.date || review.schedule_date,
          description: review.description || "performance review",
        }))
        .filter(
          (review) =>
            convertTextToDate(review.date).getFullYear() ===
            new Date().getFullYear()
        )
    );

    setTimelineListData(
      [
        ...assignedReviews.map((review) => ({
          date: convertTextToDate(review?.schedule_date),
          text: `${
            convertTextToDate(review?.schedule_date) >= new Date()
              ? "Upcoming"
              : "Overdue"
          } ${review?.title} ${moment(
            convertTextToDate(review?.schedule_date)
          ).format("MMM. YYYY")}`,
        })),
        ...completedReviews.map((review) => ({
          date: convertTextToDate(review?.schedule_date),
          text: `Completed ${review?.title} ${moment(
            convertTextToDate(review?.schedule_date)
          ).format("MMM. YYYY")}`,
        })),
      ].sort((a, b) => b.date - a.date)
    );
  }, [userReviewTemplates, assignedReviews, completedReviews]);

  useEffect(() => {
    if (selectedFilter) {
      if (selectedFilter === 1) {
        setTimelineListData(
          completedReviews
            .map((review) => ({
              date: convertTextToDate(review?.schedule_date),
              text: `Completed ${review?.title} ${moment(
                convertTextToDate(review?.schedule_date)
              ).format("MMM. YYYY")}`,
            }))
            .sort((a, b) => b.date - a.date)
        );
        return;
      }
      if (selectedFilter === 2) {
        setTimelineListData(
          assignedReviews
            .filter(
              (review) => convertTextToDate(review?.schedule_date) >= new Date()
            )
            .map((review) => ({
              date: convertTextToDate(review?.schedule_date),
              text: `Upcoming ${review?.title} ${moment(
                convertTextToDate(review?.schedule_date)
              ).format("MMM. YYYY")}`,
            }))
        );
        return;
      }
      if (selectedFilter === 3) {
        setTimelineListData(
          assignedReviews
            .filter(
              (review) => convertTextToDate(review?.schedule_date) < new Date()
            )
            .map((review) => ({
              date: convertTextToDate(review?.schedule_date),
              text: `Overdue ${review?.title} ${moment(
                convertTextToDate(review?.schedule_date)
              ).format("MMM. YYYY")}`,
            }))
        );
      }
    } else {
      setTimelineListData(
        [
          ...assignedReviews.map((review) => ({
            date: convertTextToDate(review?.schedule_date),
            text: `${
              convertTextToDate(review?.schedule_date) >= new Date()
                ? "Upcoming"
                : "Overdue"
            } ${review?.title} ${moment(
              convertTextToDate(review?.schedule_date)
            ).format("MMM. YYYY")}`,
          })),
          ...completedReviews.map((review) => ({
            date: convertTextToDate(review?.schedule_date),
            text: `Completed ${review?.title} ${moment(
              convertTextToDate(review?.schedule_date)
            ).format("MMM. YYYY")}`,
          })),
        ].sort((a, b) => b.date - a.date)
      );
    }
  }, [selectedFilter]);

  const handleFilterChange = (e) => {
    setSelectedFilter(parseInt(e.target.value));
  };

  const handleResetFilters = () => {
    setSelectedFilter(null);
  };

  const handlePointClick = (pointId, status, e) => {
    const x = e.clientX;
    const y = e.clientY;

    if (selectedPoint?.id !== pointId) {
      setSelectedPoint({ id: pointId, status, x, y });
    } else {
      setSelectedPoint(null);
    }
  };

  const handleAssignReview = (pointId) => {
    dispatch(
      setActiveViewData(
        userReviewTemplatesState?.find((template) => template?.id === pointId)
      )
    );
    dispatch(setFormType("edit"));
    navigate("/personal-performance/form");
  };

  const renderPointOnClick = () => {
    if (selectedPoint.status === 1) {
      return (
        <>
          <p>
            Recomended date:{" "}
            {
              timelineData?.find((review) => review?.id === selectedPoint.id)
                ?.date
            }
          </p>
          {localUserData?.id === selectedUser?.manager?.id ? (
            <Button
              className="review-btn"
              handleClick={() => handleAssignReview(selectedPoint?.id)}
            >
              Assign Review
            </Button>
          ) : (
            ""
          )}
        </>
      );
    }
    if (selectedPoint.status === 2) {
      return (
        <>
          <p>
            Review completed:{" "}
            {
              timelineData?.find((review) => review?.id === selectedPoint.id)
                ?.date
            }
          </p>
          <Status
            status={{
              label: performanceReviewExpectationOptions.find(
                (option) =>
                  option.value ===
                  timelineData?.find(
                    (review) => review?.id === selectedPoint.id
                  )?.expectation
              )?.label,
              color: "green",
            }}
          />
        </>
      );
    }
    if (selectedPoint.status === 3) {
      return (
        <>
          <p>
            Scheduled at:{" "}
            {
              timelineData?.find((review) => review?.id === selectedPoint.id)
                ?.date
            }
          </p>
        </>
      );
    }
    if (selectedPoint.status === 4) {
      return (
        <>
          <p>
            Scheduled at:{" "}
            {
              timelineData?.find((review) => review?.id === selectedPoint.id)
                ?.date
            }
          </p>
          <Status status={{ label: "Overdue", color: "red" }} />
        </>
      );
    }
  };

  return (
    <div className="timelines">
      <Timeline
        title="Performance review timeline"
        legendItems={performanceReviewStatuses}
        points={timelineData || []}
        statusSlug="pointStatus"
        onPointClick={handlePointClick}
      />
      {!!selectedPoint ? (
        <div
          className="point-on-click"
          style={{
            top: `${selectedPoint.y}px`,
            left: `${selectedPoint.x}px`,
          }}
        >
          {renderPointOnClick()}
        </div>
      ) : null}
      <div className="side-timelines">
        <div className="reviews-timeline-list">
          <div className="reviews-timeline-list-header">
            <p className="reviews-timeline-list-title">
              List of past and upcoming reviews
            </p>
            <Filter
              filters={performanceReviewStatuses
                .filter((status) => status?.id !== 1)
                .map((status) => status?.label)}
              checked={selectedFilter}
              onRadioChange={handleFilterChange}
              onReset={handleResetFilters}
            />
          </div>
          <TimelineList
            data={timelineListData.map((item) => item?.text) || []}
            noDataString="No upcoming or past reviews"
          />
        </div>
        <hr />
        <div className="reviews-timeline-list">
          <div className="reviews-timeline-list-header">
            <p className="reviews-timeline-list-title">Time on each position</p>
          </div>
          <TimelineList
            data={
              positionHistory?.map(
                (position) =>
                  `${position?.position} ${formatShortDate(
                    convertTextToDate(position?.start, "DD.MM.YYYY HH:mm:ss"),
                    "DD.MM.YYYY"
                  )}`
              ) || []
            }
            noDataString="No previous positions"
          />
        </div>
      </div>
    </div>
  );
};

export default PerformanceReviewTimeline;
