import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchTemplates = createAsyncThunk(
  "performance/templates/fetch",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/personal-performances/template`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createTemplate = createAsyncThunk(
  "performance/templates/create",
  async (payload) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/personal-performance`,
        payload
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "performance/templates/delete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/personal-performance/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSingleTemplate = createAsyncThunk(
  "performance/template/fetch",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/personal-performance/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDrafts = createAsyncThunk(
  "performance/drafts/fetch",
  async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/personal-performances/draft`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAssignedReviews = createAsyncThunk(
  "performance/assigned-reviews/fetch",
  async ({ user_id = null, page = null, managedUsersIds = [] }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/personal-performances`,
        {
          params: {
            user_id,
            page,
          },
        }
      );
      let responseData = response?.data?.personal_performances?.filter(
        (resp) => resp?.status === 1 && resp?.expectation === null
      );
      if (managedUsersIds.length > 0) {
        responseData = responseData?.filter((resp) =>
          managedUsersIds.includes(resp?.user?.id)
        );
      }
      return responseData;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCompletedReviews = createAsyncThunk(
  "performance/completed-reviews/fetch",
  async ({ user_id = null, page = null, managedUsers = [] }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/personal-performances`,
        {
          params: {
            user_id,
            page,
          },
        }
      );
      let responseData = response?.data?.personal_performances?.filter(
        (resp) => resp?.expectation !== null
      );
      if (!!managedUsers?.length) {
        responseData = responseData.filter((resp) =>
          managedUsers?.includes(resp?.user?.id)
        );
      }
      return responseData;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePerformance = createAsyncThunk(
  "performance/review/update",
  async (review) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/personal-performance/${review?.id}`,
        review
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
