import { useEffect, useState } from "react";

import {
  fetchBehaviours,
  createBehaviour,
  updateBehaviour,
  deleteBehaviour,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector, useDispatch } from "react-redux";

import { Pagination, Table } from "../../../ui";
import BehavioursModal from "./BehavioursModal";
import { resetBehaviourMessage } from "../../../../store/behaviours/behavioursSlice";
import { useToastMessage } from "../../../../hooks/useToastMessage";
import { setFormType, setFormData } from "../../../../store/form/formSlice";
import { setActiveModal } from "../../../../store/modal/modalSlice";

const Behaviours = () => {
  const dispatch = useDispatch();
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const isAdmin = localUserData?.role === 1;

  const [page, setPage] = useState(1);

  const [doFetchBehaviours] = useThunk(fetchBehaviours);
  const [doDeleteBehaviour] = useThunk(deleteBehaviour);
  const [doCreateBehaviour, isLoading] = useThunk(createBehaviour);
  const [doUpdateBehaviour] = useThunk(updateBehaviour);
  const [doShowToastMessage] = useToastMessage(resetBehaviourMessage);

  const behavioursState = useSelector((state) => state.behaviours)?.behaviours;
  const activeModalState = useSelector((state) => state.modal)?.activeModal;
  const searchTerm = useSelector((state) => state.pageViews)?.searchTerm;
  const messageState = useSelector((state) => state?.behaviours)?.message;
  const formTypeState = useSelector((state) => state?.form)?.type;

  useEffect(() => {
    if (!isLoading) {
      doFetchBehaviours({ page, searchTerm });
    }
  }, [page, searchTerm, isLoading, doFetchBehaviours]);

  useEffect(() => {
    doShowToastMessage(messageState);
  }, [messageState]);

  const tableData = {
    rows: behavioursState?.map((behaviour) => ({
      ...behaviour,
    })),
    columns: [
      {
        id: 1,
        label: "#",
        slug: "id",
      },
      {
        id: 2,
        label: "Name",
        slug: "name",
      },
    ],
  };

  const handleEdit = (behaviour) => {
    dispatch(setFormType("edit"));
    dispatch(setFormData(behaviour));
    dispatch(setActiveModal("Behaviours-modal"));
  };
  const handleDelete = (behaviour) => {
    doDeleteBehaviour(behaviour?.id);
  };
  const handleModalClose = () => {
    dispatch(setActiveModal(""));
  };

  const handleSave = (behaviour) => {
    if (formTypeState === "edit") {
      doUpdateBehaviour(behaviour);
      handleModalClose();
      return;
    }
    doCreateBehaviour(behaviour);
    handleModalClose();
  };
  return (
    <div>
      <Table
        rows={tableData?.rows}
        columns={tableData?.columns}
        isAdmin={isAdmin}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        hasActions={true}
      />

      {activeModalState === "Behaviours-modal" ? (
        <BehavioursModal
          showModal={activeModalState === "Behaviours-modal"}
          handleModalClose={handleModalClose}
          handleSave={handleSave}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Behaviours;
