import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchSkills = createAsyncThunk("/skills/fetch", async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/skills`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const createSkill = createAsyncThunk("/skills/create", async (skill) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/skill`, skill);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateSkill = createAsyncThunk("/skills/update", async (skill) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/skill/${skill.id}`,
      skill
    );
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const deleteSkill = createAsyncThunk(
  "/skills/delete",
  async (skillId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/skill/${skillId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
