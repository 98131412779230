import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSkills,
  createSkill,
  updateSkill,
  deleteSkill,
} from "./skillsThunks";

export const skillsSlice = createSlice({
  name: "skills",
  initialState: {
    skills: [],
    loading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetSkillMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSkills.fulfilled, (state, action) => {
      state.skills = action.payload;
    });
    builder.addCase(createSkill.fulfilled, (state, action) => {
      state.skills.push(action.payload);
    });
    builder.addCase(updateSkill.fulfilled, (state, action) => {
      const index = state.skills.findIndex(
        (skill) => skill.id === action.payload.id
      );
      state.skills[index] = action.payload;
    });
    builder.addCase(deleteSkill.fulfilled, (state, action) => {
      state.skills = state.skills.filter(
        (skill) => skill.id !== action.meta.arg
      );
    });
  },
});

export const { resetSkillMessage } = skillsSlice.actions;

export const skillsReducer = skillsSlice.reducer;
