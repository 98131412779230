import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchAdvancedDegrees = createAsyncThunk(
  "advancedDegree/fetch",
  async ({ user_id }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/advanced-degrees`, {
        params: {
          user_id,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAdvancedDegree = createAsyncThunk(
  "advancedDegree/fetchById",
  async (id) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/advanced-degree/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createAdvancedDegree = createAsyncThunk(
  "advancedDegree/create",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/advanced-degree`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateAdvancedDegree = createAsyncThunk(
  "advancedDegree/update",
  async ({ id, data }) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/advanced-degree/${id}`,
        {
          ...data,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteAdvancedDegree = createAsyncThunk(
  "advancedDegree/delete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/advanced-degree/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
