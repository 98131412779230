import { useEffect, useState, useRef } from "react";
import InitiativeReview from "./InitiativeReview";
import CompensationReview from "./CompensationReview";
import { fetchMatrixByPosition } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { dummyPosition } from "./dummyData";

const InitiativeAndCompensation = ({ isExpanded, selectedUser }) => {
  const contentRef = useRef(null);
  const careerPath = dummyPosition;
  const [height, setHeight] = useState(0);

  const [doFetchMatrix] = useThunk(fetchMatrixByPosition);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
      doFetchMatrix({
        positionId: careerPath?.position_id,
        userId: !!selectedUser ? selectedUser?.id : careerPath?.userId,
      });
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view">
        <InitiativeReview selectedUser={selectedUser} />
        <hr />
        <CompensationReview />
      </div>
    </div>
  );
};

export default InitiativeAndCompensation;
