import React from "react";

const Preloader = ({ size = "auto", color = "#36c1e2" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 200 200"
  >
    <circle
      transform="rotate(0)"
      transform-origin="center"
      fill="none"
      stroke={color}
      strokeWidth="15"
      strokeLinecap="round"
      strokeDasharray="230 1000"
      strokeDashoffset="0"
      cx="100"
      cy="100"
      r="70"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0"
        to="360"
        dur="1"
        repeatCount="indefinite"
      ></animateTransform>
    </circle>
  </svg>
);

export default Preloader;
