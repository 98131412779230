import { useState, useEffect } from "react";

import { fetchFeedbackTasks, fetchUserFeedbacks } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

import { useSelector, useDispatch } from "react-redux";

import "./feedback.scss";

import FeedbackList from "./FeedbackList";
import FeedbackTaskDetails from "./FeedbackTaskDetails";
import FeedbackDetails from "./FeedbackDetails";
import { setActiveView } from "../../../../store/pageViews/pageViewsSlice";

const Feedback = () => {
  const dispatch = useDispatch();
  const [selectedTask, setSelectedTask] = useState(null);

  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const feedbackTasksState = useSelector(
    (state) => state.feedback
  )?.feedbackTasks;
  const feedbacksUserState = useSelector(
    (state) => state.feedback
  )?.feedbacksUser;
  const activeViewState = useSelector((state) => state.pageViews)?.activeView;

  useEffect(() => {
    dispatch(setActiveView({ view: "feedbacks", page: "Career-path-admin" }));
  }, []);

  const handleActiveView = (view, feedback) => {
    if (feedback) {
      setSelectedTask(feedback);
    }
    dispatch(
      setActiveView({
        view: "feedbacks",
        subview: view,
        page: "Career-path-admin",
      })
    );
  };
  const [doFetchFeedbackTasks] = useThunk(fetchFeedbackTasks);
  const [doFetchUserFeedbacks] = useThunk(fetchUserFeedbacks);

  useEffect(() => {
    doFetchFeedbackTasks();
    doFetchUserFeedbacks(localUserData?.id);
  }, []);

  return (
    <>
      {(!activeViewState?.subview ||
        activeViewState?.subview === "feedback-list") && (
        <FeedbackList
          handleActiveView={handleActiveView}
          tasksData={feedbackTasksState}
          feedbackUserData={feedbacksUserState}
        />
      )}
      {activeViewState?.subview === "feedback-form" && (
        <FeedbackTaskDetails
          handleActiveView={handleActiveView}
          selectedTask={selectedTask}
        />
      )}
      {activeViewState?.subview === "feedback-form-client" && (
        <FeedbackDetails
          handleActiveView={handleActiveView}
          selectedFeedback={selectedTask}
        />
      )}
    </>
  );
};

export default Feedback;
