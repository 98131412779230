import React, { useEffect, useState } from "react";
import UserCard from "./UserCard";
import { personalPerformanceViews } from "./views";
import { Button, Modal, TypeAhead } from "../ui";
import { useDispatch, useSelector } from "react-redux";
import { useThunk } from "../../hooks/useThunk";
import {
  fetchSingleUser,
  fetchTeams,
  fetchUsers,
  updateUser,
} from "../../store";
import { seniorityTypes } from "../../data/types";
import {
  CircleCheckIcon,
  CloseIcon,
  EditIcon,
  Preloader,
  SearchIcon,
} from "../../icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetSingleUser,
  resetUserReviewTemplate,
} from "../../store/users/usersSlice";

const PersonalPerformance = () => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [doFetchTeams, teamsStateIsLoading] = useThunk(fetchTeams);
  const [doFetchSingleUser, singleUserStateIsLoading] =
    useThunk(fetchSingleUser);
  const [doUpdateUser] = useThunk(updateUser);
  const [doFetchUsers] = useThunk(fetchUsers);

  const currentUserState = useSelector((state) => state?.users)?.singleUser;
  const teamsState = useSelector((state) => state.teams?.teams)?.optionData;
  const usersState = useSelector((state) => state?.users)?.data?.userData;

  const [expandedViews, setExpandedViews] = useState([]);
  const [selectedUser, setSelectedUser] = useState(localUserData);
  const [isAboutEditEnabled, setIsAboutEditEnabled] = useState(false);
  const [aboutMe, setAboutMe] = useState(localUserData?.about_me || "");
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleToggleView = (id) => {
    setExpandedViews((prev) =>
      prev.includes(id)
        ? prev.filter((childId) => childId !== id)
        : [...prev, id]
    );
  };

  useEffect(() => {
    doFetchTeams({});
    if (!!state && currentUserState?.id !== state?.userId) {
      doFetchSingleUser(state?.userId);
    } else {
      doFetchSingleUser(localUserData?.id);
    }
    return () => {
      dispatch(resetSingleUser());
      dispatch(resetUserReviewTemplate());
    };
  }, []);

  useEffect(() => {
    if (!!currentUserState) {
      setSelectedUser(currentUserState);
      if (!!state?.expandedViews) {
        setExpandedViews(state?.expandedViews);
      }
    }
  }, [currentUserState]);

  const handleEditAboutMe = () => {
    setIsAboutEditEnabled(true);
  };

  const handleConfirmAboutMe = () => {
    setIsAboutEditEnabled(false);
    const {
      id,
      active,
      birthday,
      email,
      first_name,
      last_name,
      phone,
      team,
      manager,
    } = localUserData;
    doUpdateUser({
      id,
      active,
      birthday,
      email,
      first_name,
      last_name,
      phone,
      team,
      manager: manager?.id,
      about_me: aboutMe,
    });
  };

  const handleCancelAboutMe = () => {
    setIsAboutEditEnabled(false);
    setAboutMe(localUserData?.about_me || "");
  };

  const handleTextAreaChange = (value) => {
    setAboutMe(value);
  };

  const handleOpenSearchModal = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleUserSearch = (searchTerm) => {
    doFetchUsers({ searchTerm });
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleSelectUser = () => {
    setShowModal(false);
    setExpandedViews([]);
  };

  const handleAssignReview = () => {
    navigate("/personal-performance/form");
  };

  return (
    <div className="personal-performance-wrapper">
      <div className="personal-performance-content">
        <div className="personal-performance-header">
          {teamsStateIsLoading ? (
            <div className="preloader-wrapper">
              <Preloader size="120" />
            </div>
          ) : (
            <UserCard />
          )}
          <div className="header-info">
            <div className="about-me-wrapper">
              <textarea
                rows="3"
                placeholder="About me:"
                readOnly={!isAboutEditEnabled}
                value={aboutMe}
                onChange={(e) => handleTextAreaChange(e.target.value)}
              />
              <div className="action-wrapper">
                {!isAboutEditEnabled ? (
                  <Button variant="action" handleClick={handleEditAboutMe}>
                    <EditIcon />
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="action confirm"
                      handleClick={handleConfirmAboutMe}
                    >
                      <CircleCheckIcon />
                    </Button>
                    <Button
                      variant="action close"
                      handleClick={handleCancelAboutMe}
                    >
                      <CloseIcon />
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="button-group">
              <Button
                variant="secondary"
                disabled={selectedUser?.manager?.id !== localUserData?.id}
                handleClick={handleAssignReview}
              >
                Assign review
              </Button>
              <Button handleClick={handleOpenSearchModal}>Search</Button>
            </div>
          </div>
        </div>
        <div className="personal-performance-user-info">
          <table>
            <thead>
              <tr>
                <td>Name Surname</td>
                <td>Position / Seniority</td>
                <td>Department / Team</td>
                <td>Mentor</td>
                <td>Mentees</td>
                <td>Current time in company</td>
              </tr>
            </thead>
            <tbody>
              {singleUserStateIsLoading ? (
                <tr>
                  <td colSpan="6" className="preloader-wrapper">
                    <Preloader size="40" />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>{`${selectedUser?.first_name} ${selectedUser?.last_name}`}</td>
                  <td>
                    {!!selectedUser?.position
                      ? `${selectedUser?.position}`
                      : "None"}
                    {selectedUser?.levelOfSeniority &&
                      ` / ${
                        seniorityTypes.find(
                          (type) =>
                            type.value === selectedUser?.levelOfSeniority
                        ).label
                      }`}
                  </td>
                  {/* TODO: kada budemo imali department property, postaviti ga td */}
                  <td>
                    {
                      teamsState?.find(
                        (team) => team?.id === selectedUser?.team
                      )?.name
                    }
                  </td>
                  <td>{selectedUser?.mentor || "None"}</td>
                  <td>{selectedUser?.mentees || "None"}</td>
                  {/* TODO: promeeniti kada budemo imali datum prijave */}
                  <td>Since: 2022-12-09</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="personal-performance-views">
          {personalPerformanceViews?.map((view) => (
            <React.Fragment key={`view-${view?.id}`}>
              <div
                className="performance-view"
                onClick={() => handleToggleView(view?.id)}
              >
                <div className="view-icon">{view?.icon}</div>
                <h3>{view?.label}</h3>
              </div>
              <div className="expandable-content">
                {view?.component({
                  isExpanded: expandedViews.includes(view?.id),
                  viewId: view?.id,
                  selectedUser,
                })}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <Modal
        show={showModal}
        handleClose={handleModalClose}
        title="Select User"
        handleConfirm={handleSelectUser}
        confirmButtonLabel="Select"
        rejectButtonLabel="Close"
        className="limited-height-typeahead-dropdown"
      >
        <TypeAhead
          placeholder="Search user"
          icon={<SearchIcon />}
          options={usersState || []}
          slug={(option) => `${option?.first_name} ${option?.last_name}`}
          handleSearch={handleUserSearch}
          handleSelect={handleUserSelect}
          selected={selectedUser}
        />
      </Modal>
    </div>
  );
};

export default PersonalPerformance;
