import React, { useEffect, useState } from "react";
import { Filter, LineChart } from "../../../../ui";
import moment from "moment";
import { checkinsFilterColors, checkinsTypes } from "../../../../../data/types";
import ChartFilterOption from "./ChartFilterOption";
import { getWeekNumbersInMonth } from "../../../../../utils/textFormat";
import _ from "lodash";

const LineChartCheckins = ({
  name = "",
  title = "",
  data = [],
  chartFilterMultiselect = true,
  filterOptions = [],
  filterType = "date",
  defaultFilter = 2,
  colors = [],
  onFilterChange = () => {},
  handleChartChange = () => {},
}) => {
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartFilters, setChartFilters] = useState([]);
  const [selectedChartFilter, setSelectedChartFilter] = useState([]);
  const [segmentColors, setSegmentColors] = useState(colors);

  const images = checkinsTypes.map((type) =>
    require(`../images/${type.imageName}.png`)
  );

  const datasets = data.map((dataArray, index) => {
    return {
      data: dataArray?.data || dataArray,
      fill: false,
      borderColor:
        segmentColors.find(
          (segmentColor) =>
            segmentColor?.segment === dataArray?.period ||
            segmentColor?.segment === dataArray?.team
        )?.color || checkinsFilterColors[index],
      backgroundColor:
        segmentColors.find(
          (segmentColor) =>
            segmentColor?.segment === dataArray?.period ||
            segmentColor?.segment === dataArray?.team
        )?.color || checkinsFilterColors[index],
      pointBackgroundColor: "#fff",
      pointBorderColor:
        segmentColors.find(
          (segmentColor) =>
            segmentColor?.segment === dataArray?.period ||
            segmentColor?.segment === dataArray?.team
        )?.color || checkinsFilterColors[index],
      pointRadius: 6,
      pointBorderWidth: 3,
      spanGaps: true,
      tension: 0.3,
    };
  });
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        type: "category",
        labels: [5, 4, 3, 2, 1],
        afterFit: (scaleInsance) => {
          scaleInsance.width = 37;
        },
        grid: {
          display: true,
          color: "rgba(228, 227, 227, 0.8)",
        },
      },
      y1: {
        type: "category",
        position: "right",
        labels: ["Amazing!", "Not well"],
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          color: "rgba(228, 227, 227, 0.3)",
        },
      },
    },
  };

  useEffect(() => {
    if (!!colors?.length && !_.isEqual(colors, segmentColors)) {
      setSegmentColors(colors);
    }
  }, [colors]);

  useEffect(() => {
    if (filterType === "date") {
      handleDateFilterChange(selectedFilter);
      onFilterChange(selectedFilter);
    }
  }, [selectedFilter]);

  useEffect(() => {
    switch (selectedFilter) {
      case 1:
        setSelectedChartFilter([moment(new Date()).format("YYYY")]);
        break;
      case 2:
        setSelectedChartFilter([moment(new Date()).format("MMM")]);
        break;
      case 3:
        setSelectedChartFilter([`Week ${moment(new Date()).week()}`]);
        break;
      default:
        break;
    }

    if (chartFilterMultiselect) {
      setSegmentColors([
        { segment: selectedChartFilter[0], color: checkinsFilterColors[0] },
      ]);
    }
  }, [selectedFilter, chartFilters]);

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handleFilterReset = () => {
    setSelectedFilter(defaultFilter);
  };

  const handleChartFilterClick = (segment) => {
    if (chartFilterMultiselect) {
      if (selectedChartFilter.includes(segment)) {
        setSelectedChartFilter(
          selectedChartFilter.filter((item) => item !== segment)
        );
        setSegmentColors(
          segmentColors.filter((item) => item.segment !== segment)
        );
      } else {
        setSelectedChartFilter([...selectedChartFilter, segment]);
        const firstNotInSegmentColors = checkinsFilterColors.find(
          (color) => !segmentColors.map((sc) => sc.color).includes(color)
        );
        setSegmentColors([
          ...segmentColors,
          { segment, color: firstNotInSegmentColors },
        ]);
      }
      return;
    }

    setSelectedChartFilter([segment]);
  };

  useEffect(() => {
    handleChartChange(selectedChartFilter);
  }, [selectedChartFilter]);

  const yearsChartFilters = () => {
    let years = [];
    for (let i = 12; i >= 0; i--) {
      years.push(moment(new Date()).subtract(i, "years").format("YYYY"));
    }
    return years;
  };

  const handleDateFilterChange = (value) => {
    if (value === 1) {
      setChartFilters(yearsChartFilters());
      setChartLabels(moment.monthsShort());
      return;
    }
    if (value === 2) {
      setChartFilters(moment.monthsShort());
      setChartLabels(
        getWeekNumbersInMonth(
          new Date().getFullYear(),
          new Date().getMonth()
        ).map((week, index) => `Week ${index + 1}`)
      );
      return;
    }
    if (value === 3) {
      moment.updateLocale("en", {
        week: {
          dow: 1,
        },
      });
      setChartFilters(
        getWeekNumbersInMonth(
          new Date().getFullYear(),
          new Date().getMonth()
        )?.map((week) => `Week ${week}`)
      );
      setChartLabels(moment.weekdays(true));
      return;
    }
  };

  return (
    <div className="line-chart">
      <div className="title-line">
        <h4 className="title">{title}</h4>
        <Filter
          filters={filterOptions}
          checked={selectedFilter}
          onRadioChange={(e) => handleFilterChange(parseInt(e?.target?.value))}
          onReset={handleFilterReset}
        />
      </div>
      <LineChart
        labels={chartLabels}
        datasets={datasets}
        chartOptions={options}
        outsideTitle="Check-in mood"
      >
        {images.reverse().map((image, index) => (
          <div key={`label-${index}`} className="icon-wrapper">
            <img src={image} alt={checkinsTypes[index]?.imageName} />
          </div>
        ))}
      </LineChart>
      <div className="chart-filters">
        {filterType === "date" && (
          <>
            {chartFilters?.map((filter, index) => (
              <ChartFilterOption
                key={`filter-option-${index}`}
                option={filter}
                color={
                  chartFilterMultiselect
                    ? segmentColors.find((sc) => sc.segment === filter)?.color
                    : "#263E4E"
                }
                multiselect={chartFilterMultiselect}
                isSelected={selectedChartFilter.includes(filter)}
                onClick={(e) => handleChartFilterClick(e, index)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default LineChartCheckins;
