import { useState } from "react";
import { Button, Input, Modal } from "../../../ui";
import { taskStatuses } from "../../../../data/statuses";
import "./styles.scss";
import DropdownSelect from "../../../DropdownSelect/DropdownSelect";
import { updateProject } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";

const UpdateProjectStatusModal = ({ show, close, selectedProject }) => {
  const [doUpdateStatus] = useThunk(updateProject);
  const [status, setStatus] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleStatusChange = (status) => {
    setStatus(status.value);
  };

  const hnadleProgressChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    if (name === "progress" && (value < 0 || value > 100)) {
      return;
    }
    setProgress(value);
  };

  const handleUpdate = () => {
    if (selectedProject.status === status) {
      return;
    }
    if (progress < 0 || progress > 100) {
      return;
    }

    let updateData = {
      status: status,
      id: selectedProject.id,
      name: selectedProject.name,
    };

    if (progress !== null || progress > 0) {
      updateData.progress = progress;
    }
    doUpdateStatus(updateData);
    close();
  };
  return (
    <Modal
      show={show}
      handleClose={close}
      title="Update Project Statuses"
      className={"modal-status"}
      hasFooter={false}
    >
      <label>What is the status of the project?</label>
      <DropdownSelect
        name="statusOfProject"
        type="primary"
        placeholder={
          taskStatuses.find(
            (status) => status?.value === parseInt(selectedProject?.status)
          )?.label
        }
        options={taskStatuses}
        background="dark"
        handleSelect={handleStatusChange}
        preselected={{
          value: parseInt(selectedProject?.status),
          label: taskStatuses.find(
            (status) => status?.value === parseInt(selectedProject?.status)
          )?.label,
        }}
      />
      <div className="modal-task-options">
        <div className="option-wrapper">
          <label>What is the progress?</label>
          <div className="input-wrapper">
            <div className="custom-input">
              <label>Current</label>
              <Input
                name="progress"
                type="number"
                placeholder="Enter %"
                value={selectedProject?.progress || progress}
                handleChange={hnadleProgressChange}
              />
            </div>
            <div className="custom-input disabled-input">
              <label>Target</label>
              <Input placeholder="100%" disabled value={""} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-status-footer">
        <Button variant="primary" handleClick={handleUpdate}>
          Update
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateProjectStatusModal;
