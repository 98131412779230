import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, Input, Switcher } from "../../../../ui";
import { CalendarLightIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  createCommitteeInvolvement,
  updateCommitteeInvolvement,
} from "../../../../../store";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";

const CommitteeInvolvement = ({ userId, editData }) => {
  const [doCreateCommitteeInvolvement] = useThunk(createCommitteeInvolvement);
  const [doUpdateCommitteeInvolvement] = useThunk(updateCommitteeInvolvement);
  const defaultPlaceholder = "Type here...";
  const [committee, setCommittee] = useState({});
  const [isOngoing, setIsOngoing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (editData) {
      setCommittee({
        title: editData?.name,
        role: editData?.role,
        organizationName: editData?.organization,
        startDate: String(editData?.start).split(" ")[0],
        endDate: editData?.end ? String(editData?.end).split(" ")[0] : null,
        responsibilities: editData?.responsibilities,
        achievements: editData?.achievements,
      });

      setIsOngoing(editData?.ongoing);
    }
  }, [editData]);

  useEffect(() => {
    setCommittee((prevCommittee) => ({ ...prevCommittee, ongoing: isOngoing }));
    if (isOngoing) {
      setCommittee((prevCommittee) => ({ ...prevCommittee, endDate: null }));
    }
  }, [isOngoing]);

  const handleChange = (name, value) => {
    setCommittee({ ...committee, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!committee.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add Committee Name.",
          type: "error",
        })
      );
      return;
    }
    if (!committee.role) {
      dispatch(
        setToastMessage({
          title: "Role error",
          message: "Please add role.",
          type: "error",
        })
      );
      return;
    }
    if (!committee.organizationName) {
      dispatch(
        setToastMessage({
          title: "Organization Name error",
          message: "Please add organization name.",
          type: "error",
        })
      );
      return;
    }
    if (!committee.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start  date.",
          type: "error",
        })
      );
      return;
    }
    if (!committee.responsibilities) {
      dispatch(
        setToastMessage({
          title: "Responsibilities error",
          message: "Please add responsibilities.",
          type: "error",
        })
      );
      return;
    }

    if (!committee.achievements) {
      dispatch(
        setToastMessage({
          title: "Achievements error",
          message: "Please add achievements.",
          type: "error",
        })
      );
      return;
    }

    doCreateCommitteeInvolvement({
      name: committee.title,
      role: committee.role,
      organization: committee.organizationName,
      ongoing: isOngoing,
      user_id: userId,
      start: committee.startDate + " 00:00:00",
      end: committee.endDate ? committee.endDate + " 00:00:00" : null,
      responsibilities: committee.responsibilities,
      achievements: committee.achievements,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!committee.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add Committee Name.",
          type: "error",
        })
      );
      return;
    }

    if (!committee.role) {
      dispatch(
        setToastMessage({
          title: "Role error",
          message: "Please add role.",
          type: "error",
        })
      );
      return;
    }

    if (!committee.organizationName) {
      dispatch(
        setToastMessage({
          title: "Organization Name error",
          message: "Please add organization name.",
          type: "error",
        })
      );
      return;
    }

    if (!committee.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start  date.",
          type: "error",
        })
      );
      return;
    }

    if (!isOngoing && !committee.endDate) {
      dispatch(
        setToastMessage({
          title: "End date error",
          message: "Please add end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdateCommitteeInvolvement({
      id: editData?.id,
      data: {
        name: committee?.title,
        role: committee?.role,
        organization: committee?.organizationName,
        ongoing: isOngoing,
        start: committee?.startDate + " 00:00:00",
        end: committee?.endDate ? committee?.endDate + " 00:00:00" : null,
        responsibilities: committee?.responsibilities,
        achievements: committee?.achievements,
      },
    });
  };

  return (
    <div className="committee-involvement">
      <Form onSubmit={(e) => e.preventDefault()}>
        <Input
          name="title"
          label="Committee Name"
          placeholder={defaultPlaceholder}
          value={committee?.title}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          name="organizationName"
          label="Organization Name"
          placeholder={defaultPlaceholder}
          value={committee?.organizationName}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          name="role"
          label="Role"
          placeholder={defaultPlaceholder}
          value={committee?.role}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Duration</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Ongoing</label>
              <Switcher
                checked={isOngoing}
                handleChange={() => setIsOngoing(!isOngoing)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label className="label-normal">Starting date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(committee?.startDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                />
                <CalendarLightIcon />
              </div>
            </div>
            <div className="form-group">
              <label className="label-normal">Ending date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(committee?.endDate)}
                  showMonthDropdown
                  showYearDropdown
                  disabled={isOngoing}
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                />
                <CalendarLightIcon />
              </div>
            </div>
          </div>
        </div>
        <Input
          type="textarea"
          name="responsibilities"
          label="Responsibilities"
          placeholder={defaultPlaceholder}
          value={committee?.responsibilities}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          type="textarea"
          name="achievements"
          label="Achievements"
          placeholder={defaultPlaceholder}
          value={committee?.achievements}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CommitteeInvolvement;
