import { useState, useEffect } from "react";
import "./styles.scss";

import { fetchUsers, createFeedback } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";
import { Form, Input, Modal, Switcher, TypeAhead } from "../../../ui";
import { SearchIcon } from "../../../../icons";

const GiveFeedbackForm = ({
  showGiveFeedbackModal,
  handleModalClose,
  isSent,
}) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const [feedbackData, setFeedbackData] = useState(null);

  const usersState = useSelector((state) => state.users)?.data?.userData;
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doCreateFeedback] = useThunk(createFeedback);

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    }
  }, [usersState]);

  const handleSearch = (searchTerm) => {
    doFetchUsers({ searchTerm });
  };

  const handleChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setFeedbackData({ ...feedbackData, [name]: value });
    return;
  };

  const handleInvolveManagerChange = (name, value) => {
    setFeedbackData({ ...feedbackData, [name]: Number(value) });
  };

  const handeGiveFeedback = () => {
    const payload = {
      ...feedbackData,
      senderId: localUserData?.id,
      involveManager: feedbackData?.involveManager || 0,
    };
    doCreateFeedback(payload);
    handleModalClose();
  };

  return (
    <Modal
      show={showGiveFeedbackModal}
      handleClose={handleModalClose}
      title="Give Feedback"
      confirmButtonLabel="Give feedback"
      handleConfirm={handeGiveFeedback}
    >
      <Form>
        <div className="form-group bottom-margin">
          <label>Who would you like to give feedback to?</label>
          <TypeAhead
            name="receiverId"
            options={usersState}
            placeholder="Select teammates"
            disabled={isSent}
            slug={(option) => `${option?.first_name} ${option?.last_name}`}
            handleSearch={handleSearch}
            handleSelect={(e) =>
              handleChange({ name: "receiverId", value: e?.id })
            }
            icon={<SearchIcon />}
          />
        </div>

        <div className="form-group">
          <Input
            type="input"
            label="Your feedback"
            placeholder="My feedback..."
            name="answer"
            value={feedbackData?.answer || ""}
            handleChange={handleChange}
            disabled={isSent}
          />
        </div>

        <div className="flex gap-8 items-center">
          <Switcher
            name="involveManager"
            checked={Boolean(feedbackData?.involveManager)}
            handleChange={handleInvolveManagerChange}
          />
          <label htmlFor="involveManager">
            Will manager see this feedback?
          </label>
        </div>

        {/* <div className="form-group">
            <label>Who will see this feedback?</label>
            <DropdownSelect
              name="involveManager"
              background="dark"
              options={transparencyOptions}
              handleSelect={handleChange}
              type="primary"
              selectListPosition="top"
              isDisabled={isSent}
              // preselected={types.find(
              //   (type) => type.value === parseInt(singleTaskState?.type)
              // )}
            />
          </div> */}
        {/* <div className="feedback-note">
            <div>
              <QuestionIcon />
            </div>
            <p>
              Since the person you selected doesn't have a manager, your
              feedback will be sent directly to them.
            </p>
          </div> */}
      </Form>
    </Modal>
  );
};

export default GiveFeedbackForm;
