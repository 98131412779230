import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, Input, Switcher } from "../../../../ui";
import { AddIcon, CalendarLightIcon, CloseIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import { useThunk } from "../../../../../hooks/useThunk";
import {
  createProfessionalOrganization,
  updateProfessionalOrganization,
} from "../../../../../store";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";

const ProfessionalOrganizations = ({ userId, editData }) => {
  const [doCreateProfesionalOrganization] = useThunk(
    createProfessionalOrganization
  );
  const [doUpdateProfessionalOrganization] = useThunk(
    updateProfessionalOrganization
  );
  const defaultPlaceholder = "Type here...";
  const dispatch = useDispatch();

  const [isOngoing, setIsOngoing] = useState(false);
  const [benefit, setBenefit] = useState("");
  const [benefitsList, setBenefitsList] = useState([]);
  const [organization, setOrganization] = useState({});

  useEffect(() => {
    if (editData) {
      setOrganization({
        title: editData?.name,
        membershipLevel: editData?.membershipLevel,
        startDate: String(editData?.start).split(" ")[0],
        endDate: editData?.end ? String(editData?.end).split(" ")[0] : null,
        ongoing: editData?.ongoing,
        activities: editData?.activities,
      });
      setIsOngoing(editData?.ongoing);
      setBenefitsList(editData?.benefits.map((benefit) => benefit?.title));
    }
  }, [editData]);

  useEffect(() => {
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      ongoing: isOngoing,
    }));
    if (isOngoing) {
      setOrganization((prevOrganization) => ({
        ...prevOrganization,
        endDate: null,
      }));
    }
  }, [isOngoing]);

  useEffect(() => {
    handleChange("benefits", benefitsList);
  }, [benefitsList]);

  const handleChange = (name, value) => {
    setOrganization({ ...organization, [name]: value });
  };

  const handleRemoveBenefit = (benefitToRemove) => {
    setBenefitsList(
      benefitsList.filter((benefit) => benefit !== benefitToRemove)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!organization.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }

    if (!organization.membershipLevel) {
      dispatch(
        setToastMessage({
          title: "Membership Level error",
          message: "Please add membership level.",
          type: "error",
        })
      );
      return;
    }
    if (!organization.startDate) {
      dispatch(
        setToastMessage({
          title: "Date error",
          message: "Pick start  date.",
          type: "error",
        })
      );
      return;
    }

    doCreateProfesionalOrganization({
      name: organization.title,
      membership_level: organization.membershipLevel,
      ongoing: organization.ongoing,
      start: organization.startDate + " 00:00:00",
      end: organization.endDate ? organization.endDate + " 00:00:00" : null,
      benefits: organization.benefits.map((benefit) => ({ title: benefit })),
      activities: organization.activities,
      user_id: userId,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!organization.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add title.",
          type: "error",
        })
      );
      return;
    }
    if (!isOngoing && !organization.endDate) {
      dispatch(
        setToastMessage({
          title: "End date error",
          message: "Please add end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdateProfessionalOrganization({
      id: editData?.id,
      data: {
        name: organization.title,
        membership_level: organization.membershipLevel,
        ongoing: organization.ongoing,
        start: organization.startDate + " 00:00:00",
        end: organization.endDate ? organization.endDate + " 00:00:00" : null,
        benefits: organization.benefits.map((benefit) => ({ title: benefit })),
        activities: organization.activities,
      },
    });
  };

  return (
    <div className="proessional-organizations">
      <Form>
        <Input
          name="title"
          label="Organization Name"
          placeholder={defaultPlaceholder}
          value={organization?.title}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          name="membershipLevel"
          label="Membership Level"
          placeholder={defaultPlaceholder}
          value={organization?.membershipLevel}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Membership Duration</label>
            <div className="form-row switcher-group">
              <label className="label-normal">Ongoing</label>
              <Switcher
                checked={isOngoing}
                handleChange={() => setIsOngoing(!isOngoing)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label className="label-normal">Starting date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(organization?.startDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                />
                <CalendarLightIcon />
              </div>
            </div>
            <div className="form-group">
              <label className="label-normal">Ending date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(organization?.endDate)}
                  disabled={isOngoing}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                />
                <CalendarLightIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group bottom-margin">
          <div className="form-row input-btn">
            <Input
              label="Benefits"
              placeholder={defaultPlaceholder}
              value={benefit}
              handleChange={(e) => setBenefit(e?.target?.value)}
            />
            <Button
              className="btn-input"
              handleClick={(e) => {
                e.preventDefault();
                setBenefitsList([...benefitsList, benefit]);
                setBenefit("");
              }}
            >
              <AddIcon />
              Add
            </Button>
          </div>
          {organization?.benefits?.length > 0 && (
            <ul className="topics-list">
              {organization?.benefits?.map((benefit, index) => (
                <li key={`benefit-${index}`}>
                  <p>{benefit}</p>
                  <Button
                    variant="control"
                    handleClick={(e) => {
                      e.preventDefault();
                      handleRemoveBenefit(benefit);
                    }}
                  >
                    <CloseIcon fill="#000" width="10px" height="10px" />
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <Input
          type="textarea"
          label="Activities"
          name="activities"
          placeholder={defaultPlaceholder}
          value={organization?.activities}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ProfessionalOrganizations;
