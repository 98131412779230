import { useSelector } from "react-redux";
import { useThunk } from "../../../../../../hooks/useThunk";
import { AddIcon } from "../../../../../../icons";
import {
  fetchTeams,
  fetchTeamsMoodStatsDoughnut,
  fetchTeamsMoodStatsLine,
} from "../../../../../../store";
import { TypeAhead } from "../../../../../ui";
import LineChartCheckins from "../../helperComponents/LineChartCheckins";
import { useEffect, useState } from "react";
import { checkinsDateFilters } from "../../../../../../data/filters";
import { typeAheadCheckinsColors } from "../../../../../../data/types";
import DoughnutChartCheckins from "../../helperComponents/DoughnutChartCheckins";
import {
  convertArrayToString,
  getWeekNumbersInMonth,
} from "../../../../../../utils/textFormat";
import moment from "moment";

const TeamsCheckIn = ({ localUserData }) => {
  const [doFetchTeams] = useThunk(fetchTeams);
  const [doFetchTeamsMoodStatsLine] = useThunk(fetchTeamsMoodStatsLine);
  const [doFetchTeamsMoodStatsDoughnut] = useThunk(fetchTeamsMoodStatsDoughnut);

  const teamsState = useSelector((state) => state?.teams?.teams)?.teamData;
  const teamsOptionsState = useSelector(
    (state) => state?.teams?.teams
  )?.optionData;
  const teamsStatsLineState = useSelector(
    (state) => state?.checkins?.moodStatistics
  )?.teamsStatsLine;
  const teamsStatsDoughnutState = useSelector(
    (state) => state?.checkins?.moodStatistics
  )?.teamsStatsDoughnut;

  const [lineFilter, setLineFilter] = useState();
  const [lineChartFilter, setLineChartFilter] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [doughnutFilter, setDoughnutFilter] = useState();
  const [doughnutChartFilter, setDoughnutChartFilter] = useState();
  const [doughnutChartData, setDoughnutChartData] = useState([]);
  const [doughnutAvgData, setDoughnutAvgData] = useState([]);
  const [barChartSelectedMood, setBarChartSelectedMood] = useState(null);
  const [barChartData, setBarChartData] = useState([]);
  const [typeaheadColors, setTypeaheadColors] = useState([]);

  useEffect(() => {
    doFetchTeams({});
  }, []);

  useEffect(() => {
    if (!selectedTeams?.length && !!teamsOptionsState?.length) {
      setSelectedTeams(
        [teamsOptionsState?.find((team) => team?.id === localUserData?.team)] ||
          []
      );
    }
  }, [teamsOptionsState]);

  useEffect(() => {
    let params = null;
    if (!!lineChartFilter.length && !!selectedTeams?.length) {
      switch (lineFilter) {
        case 2:
          params = {
            range: "month",
            teams: convertArrayToString(selectedTeams?.map((team) => team?.id)),
            months: convertArrayToString(
              lineChartFilter?.map(
                (filter) =>
                  moment.monthsShort().findIndex((x) => x === filter) + 1
              )
            ),
          };
          break;
        case 3:
          params = {
            range: "week",
            teams: convertArrayToString(selectedTeams?.map((team) => team?.id)),
            weeks: convertArrayToString(
              lineChartFilter?.map((filter) =>
                parseInt(filter.replace("Week ", ""))
              )
            ),
          };
          break;
        default:
          params = {
            range: "year",
            teams: convertArrayToString(selectedTeams?.map((team) => team?.id)),
            years: convertArrayToString(
              lineChartFilter.map((filter) => parseInt(filter))
            ),
          };
          break;
      }
      doFetchTeamsMoodStatsLine(params);
    }
  }, [lineChartFilter, lineFilter, selectedTeams]);

  useEffect(() => {
    if (lineFilter === 1 && !!teamsStatsLineState) {
      setLineChartData(avgMonthlyArrays);
      return;
    }
    if (lineFilter === 2 && !!teamsStatsLineState) {
      setLineChartData(avgWeeklyArrays);
      return;
    }
    if (lineFilter === 3 && !!teamsStatsLineState) {
      setLineChartData(avgDailyArrays);
      return;
    }
  }, [teamsStatsLineState]);

  useEffect(() => {
    let params = null;
    if (!!doughnutChartFilter && !!selectedTeams?.length) {
      switch (doughnutFilter) {
        case 2:
          params = {
            range: "month",
            teams: convertArrayToString(selectedTeams?.map((team) => team?.id)),
            months: convertArrayToString(
              Array.from({ length: 12 }, (_, i) => i + 1)
            ),
          };
          break;
        case 3:
          params = {
            range: "week",
            teams: convertArrayToString(selectedTeams?.map((team) => team?.id)),
            weeks: convertArrayToString(
              getWeekNumbersInMonth(
                new Date().getFullYear(),
                new Date().getMonth()
              )
            ),
          };
          break;
        default:
          params = {
            range: "year",
            teams: convertArrayToString(selectedTeams?.map((team) => team?.id)),
            years: convertArrayToString(
              Array.from(
                { length: 12 },
                (_, index) => new Date().getFullYear() - index
              )
            ),
          };
          break;
      }
      doFetchTeamsMoodStatsDoughnut(params);
    }
  }, [doughnutFilter, selectedTeams]);

  useEffect(() => {
    if (
      !!teamsStatsDoughnutState &&
      !!doughnutFilter &&
      !!doughnutChartFilter
    ) {
      const periods = Object.keys(teamsStatsDoughnutState);
      const sum = Array.from({ length: 5 }, () => 0);
      getGradesArray(
        getActiveFilter(doughnutFilter, doughnutChartFilter)
      )?.forEach((team) => {
        team?.map((gradeCount, index) => {
          sum[index] += gradeCount;
        });
      });
      setDoughnutChartData([sum]);

      const avgData = periods?.map((period) => {
        const data = {
          period: getPeriod(doughnutFilter, period),
          avg: getAvg(
            teamsStatsDoughnutState[period]?.teamsStats?.map(
              (stat) =>
                stat?.checkinsStats[0]?.avgYearly ||
                stat?.checkinsStats[0]?.avgMonthly ||
                stat?.checkinsStats[0]?.avgWeekly
            )
          ),
          most: getMax(teamsStatsDoughnutState[period]?.teamsStats),
        };
        return data;
      });
      setDoughnutAvgData(avgData);
    }
  }, [teamsStatsDoughnutState, doughnutChartFilter, doughnutFilter]);

  useEffect(() => {
    if (!!barChartSelectedMood) {
      const barData = {
        labels: [],
        data: [],
      };
      teamsStatsDoughnutState[
        getActiveFilter(doughnutFilter, doughnutChartFilter)
      ]?.teamsStats?.map((team) => {
        barData.labels.push(team?.team?.name);
        barData.data.push(
          team?.checkinsStats[0]?.grades.find(
            (grade) => grade?.grade === barChartSelectedMood
          )?.count
        );
      });
      setBarChartData(barData);
    }
  }, [barChartSelectedMood]);

  useEffect(() => {
    setTypeaheadColors(
      selectedTeams?.map((team, index) => {
        return {
          segment: team?.id,
          color: typeAheadCheckinsColors[index],
        };
      })
    );
  }, [selectedTeams]);

  const monthMap = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const avgMonthlyArrays =
    teamsStatsLineState?.[Object.keys(teamsStatsLineState)]?.teamsStats
      ?.map((teamStats) => {
        return {
          team: teamStats?.team?.id,
          data: moment.months().map((monthName) => {
            const foundMonth = teamStats.checkinsStats?.[0]?.months?.find(
              (monthStats) => monthMap[monthStats.month] === monthName
            );
            return foundMonth ? Math.round(foundMonth.avgMontly) : NaN;
          }),
        };
      })
      .flat() || [];

  const avgWeeklyArrays =
    teamsStatsLineState[Object.keys(teamsStatsLineState)]?.teamsStats
      ?.map((teamStats) => {
        return {
          team: teamStats?.team?.id,
          data: getWeekNumbersInMonth(
            new Date().getFullYear(),
            teamStats?.checkinsStats[0]?.month - 1
          ).map((week) => {
            const foundWeek = teamStats.checkinsStats[0]?.weeks?.find(
              (weekStats) => weekStats.week === week
            );
            return foundWeek ? Math.round(foundWeek.avgWeekly) : NaN;
          }),
        };
      })
      .flat() || [];

  const avgDailyArrays =
    teamsStatsLineState[Object.keys(teamsStatsLineState)]?.teamsStats
      ?.map((teamStats) => {
        return {
          team: teamStats?.team?.id,
          data: moment.weekdays(true).map((day, index) => {
            const foundDay = teamStats.checkinsStats[0]?.days?.find(
              (dayStats) => dayStats.day === index + 1
            );
            return foundDay ? Math.round(foundDay.avgDaily) : NaN;
          }),
        };
      })
      .flat() || [];

  const getActiveFilter = (filter, chartFilter) => {
    let activeFilter = 0;
    switch (filter) {
      case 2:
        activeFilter = moment.months().findIndex((x) => x === chartFilter) + 1;
        break;
      case 3:
        activeFilter = chartFilter.replace("Week ", "");
        break;
      default:
        activeFilter = chartFilter;
        break;
    }
    return activeFilter;
  };

  const getGradesArray = (chartFillter) => {
    const gradesArray = teamsStatsDoughnutState[
      chartFillter
    ]?.teamsStats?.flatMap((stat) => {
      return stat?.checkinsStats?.map((checkinStats) => {
        return checkinStats?.grades?.map((grade) => grade?.count);
      });
    });
    return gradesArray;
  };

  const getAvg = (numbers) => {
    const sum = numbers.reduce((a, b) => a + b, 0);
    return Math.round(sum / numbers.length);
  };

  const getPeriod = (doughnutFilter, period) => {
    if (doughnutFilter === 1) {
      return period;
    }
    if (doughnutFilter === 2) {
      return moment.months()[period - 1];
    }
    return `Week ${period}`;
  };

  const getMax = (teamsStats) => {
    const calculatedGrades = [
      { grade: 1, count: 0 },
      { grade: 2, count: 0 },
      { grade: 3, count: 0 },
      { grade: 4, count: 0 },
      { grade: 5, count: 0 },
    ];
    teamsStats?.map((team) => {
      team?.checkinsStats[0]?.grades?.map((grade, index) => {
        calculatedGrades[index].count += grade?.count;
      });
    });
    const maxValue = Math.max(...calculatedGrades.map((grade) => grade.count));
    if (!!maxValue) {
      return calculatedGrades
        .reverse()
        ?.find((grade) => grade.count === maxValue)?.grade;
    }
    return null;
  };

  const handleTeamSearch = (searchTerm) => {
    doFetchTeams({ searchTerm: searchTerm });
  };

  const handleTypeAheadSelect = (teams) => {
    setSelectedTeams(teams);
  };

  const handleRemoveTeam = (selection) => {
    setSelectedTeams(selection);
  };

  const handleFilterChange = (filter) => {
    setLineFilter(filter);
  };
  const handleLineChartChange = (chartFilter) => {
    setLineChartFilter(chartFilter);
  };

  const handleDoughnutFilterChange = (filter) => {
    setDoughnutFilter(filter);
  };

  const handleDoughnutChartFilterChange = (chartFilter) => {
    setDoughnutChartFilter(chartFilter);
  };

  const handleDoughnutHover = (index) => {
    if (!!index) {
      setBarChartSelectedMood(index);
    } else {
      setBarChartSelectedMood(null);
    }
  };

  return (
    <div className="chart">
      <LineChartCheckins
        title="Mood of the Teams"
        data={lineChartData}
        filterOptions={checkinsDateFilters}
        chartFilterMultiselect={false}
        onFilterChange={handleFilterChange}
        handleChartChange={handleLineChartChange}
        colors={typeaheadColors?.map((color) => {
          return { segment: color?.segment, color: color?.color?.color };
        })}
      />
      <div className="teams-chart">
        <h4 className="select-team-title">Select Teams</h4>
        <div className="search-wrapper">
          <TypeAhead
            placeholder="Add Team"
            slug={(option) => option?.name}
            options={teamsState || []}
            icon={<AddIcon />}
            multiple
            handleSearch={(searchTerm) => handleTeamSearch(searchTerm)}
            handleSelect={handleTypeAheadSelect}
            onRemove={handleRemoveTeam}
            selectionColors={typeaheadColors.map((color) => color.color)}
            preselected={selectedTeams}
          />
        </div>
        <hr />
        <DoughnutChartCheckins
          data={doughnutChartData}
          filterOptions={checkinsDateFilters}
          chartType="teams"
          barChartData={barChartData}
          onChartHover={handleDoughnutHover}
          onFilterChange={handleDoughnutFilterChange}
          onChartFilterChange={handleDoughnutChartFilterChange}
          avgData={doughnutAvgData}
        />
      </div>
    </div>
  );
};

export default TeamsCheckIn;
