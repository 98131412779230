import React, { useState, useEffect } from "react";
import ClientQuestion from "./ClientQuestion";
import { Button } from "../../ui";
import "./styles.scss";
import { AddIcon } from "../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { addAnswers } from "../../../store";
import { useThunk } from "../../../hooks/useThunk";
import { useNavigate } from "react-router-dom";
import { setActiveViewData } from "../../../store/pageViews/pageViewsSlice";

const ClientSurvey = () => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [survey, setSurvey] = useState([]);

  const surveyData = useSelector((state) => state.pageViews)?.data;

  const [doAddAnswers] = useThunk(addAnswers);

  useEffect(() => {
    if (!!surveyData) {
      setSurvey(surveyData?.questions);
    }
  }, [surveyData]);

  const handleAnswer = (questionId, answers) => {
    setSurvey((prevSurvey) =>
      prevSurvey?.map((question) => {
        if (question.id === questionId)
          return {
            ...question,
            answers: [{ userId: localUserData?.id, answer: answers }],
          };
        return question;
      })
    );
  };

  const handleSendSurvey = () => {
    const payload = {
      id: surveyData?.id,
      questions: survey,
    };
    doAddAnswers(payload);
    dispatch(setActiveViewData(null));
    navigate("/user-surveys");
  };
  return (
    <>
      <div className="client-survey-actions">
        <Button handleClick={handleSendSurvey} disabled={survey?.length === 0}>
          <AddIcon />
          <span>Send</span>
        </Button>
      </div>
      <div className="question-creator">
        {survey?.length > 0 ? (
          survey?.map((question) => (
            <React.Fragment key={`question-${question?.id}`}>
              <p>test</p>
              <ClientQuestion question={question} handleAnswer={handleAnswer} />
            </React.Fragment>
          ))
        ) : (
          <p>No surveys</p>
        )}
      </div>
    </>
  );
};

export default ClientSurvey;
