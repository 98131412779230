import { useEffect, useState, useRef } from "react";
import SurveysReview from "./SurveysReview";
import { fetchUsersGoals, fetchSurveys } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import "./styles.scss";
import GoalsReview from "./GoalsReview";

const GoalsAndSurveys = ({ isExpanded, selectedUser }) => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  const [doFetchGoals] = useThunk(fetchUsersGoals);
  const [doFetchSurveys] = useThunk(fetchSurveys);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
      doFetchGoals(!!selectedUser ? selectedUser?.id : localUserData?.id);
      doFetchSurveys({
        user: !!selectedUser ? selectedUser?.id : localUserData?.id,
      });
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view">
        <GoalsReview
          localUserData={!!selectedUser ? selectedUser : localUserData}
        />
        <hr />
        <SurveysReview
          localUserData={!!selectedUser ? selectedUser : localUserData}
        />
      </div>
    </div>
  );
};

export default GoalsAndSurveys;
