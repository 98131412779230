import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import { EditImageIcon, CalendarIcon } from "../../../icons";
import { Button, Input } from "..";
import UserIcon from "../UserIcon";
import { useThunk } from "../../../hooks/useThunk";
import { fetchTeams, updateUser } from "../../../store";

import "./UserInfo.scss";
import moment from "moment";
import { useSelector } from "react-redux";

const UserInfo = ({ activeUser, handleClose }) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));

  const [user, setUser] = useState(activeUser || null);
  const [selectedFile, setSelectedFile] = useState();
  const [image, setImage] = useState(null);

  const [doUpdateUser] = useThunk(updateUser);
  const [doFetchTeams] = useThunk(fetchTeams);

  const teamsState = useSelector((state) => state?.teams?.teams)?.optionData;

  const createImage = () => {
    const formData = new FormData();
    formData.append("resource", selectedFile);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/resources/create`,
      requestOptions
    ).then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());
      setImage(data.data);
      setUser({ ...user, image: data.data?.path });
    });
  };

  useEffect(() => {
    doFetchTeams({});
  }, []);

  useEffect(() => {
    if (image) {
      setUser({ ...user, image: image?.data });
    }
  }, [image]);

  useEffect(() => {
    if (selectedFile) {
      createImage();
    }
  }, [selectedFile]);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleDatePicker = (date) => {
    setUser({ ...user, birthday: moment(date).format("DD.MM.yyyy") });
  };

  const saveUser = () => {
    const payload = {
      id: user?.id,
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      active: 1,
      birthday: user?.birthday || "",
      phone: user?.phone || "",
      image: user?.image || null,
    };

    if (typeof user?.image !== "string") {
      payload.image = image?.id;
    }
    doUpdateUser(payload);
    localStorage.setItem("authUserData", JSON.stringify(user));
    handleClose();
  };

  return (
    <div className="user-info-wrapper">
      <div className="user-image">
        <div className="photo-holder">
          <UserIcon
            userImage={image?.path || user?.image || user?.photoURL}
            userName={user?.first_name || user?.name}
            iconStyle={{
              minWidth: "250px",
              minHeight: "280px",
              borderRadius: "6px",
              fontSize: "100px",
            }}
          />

          {localUserData?.id === user?.id && (
            <div className="user-icon-select">
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={changeHandler}
              />
              <EditImageIcon />
            </div>
          )}
        </div>
      </div>
      <div className="user-info-form">
        <div className="form-row">
          <div className="input-group">
            <label>Name</label>
            <Input
              placeholder="Name"
              name="first_name"
              disabled={localUserData?.id !== user?.id}
              value={user?.first_name || user?.name}
              handleChange={handleChange}
            />
          </div>
          <div className="input-group">
            <label>Surname</label>
            <Input
              placeholder="Surname"
              name="last_name"
              disabled={localUserData?.id !== user?.id}
              value={user?.last_name}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div className="input-group">
          <label>Phone</label>
          <Input
            placeholder="Phone"
            name="phone"
            disabled={localUserData?.id !== user?.id}
            value={user?.phone || ""}
            handleChange={handleChange}
          />
        </div>
        <div className="input-group">
          <div className="date-picker-holder">
            <label>Birthday</label>
            <DatePicker
              onChange={handleDatePicker}
              dateFormat="dd.MM.yyyy"
              dateFormatCalendar={"MMM yyyy"}
              placeholderText="Enter Birthday"
              showMonthDropdown
              readOnly={localUserData?.id !== user?.id}
              showYearDropdown
              value={user?.birthday}
              className={`${
                localUserData?.id !== user?.id ? "disable-date-picker" : ""
              }`}
            />
            <CalendarIcon />
          </div>
        </div>
        <div className="input-group">
          <label>Email</label>
          <Input
            placeholder="Email"
            disabled={true}
            value={user?.email}
            handleChange={handleChange}
          />
        </div>
        <div className="input-group">
          <label>Team</label>
          <Input
            placeholder="Team"
            disabled={true}
            value={teamsState?.find((team) => team?.id === user?.team)?.name}
            handleChange={handleChange}
          />
        </div>
        <div className="buttons-wrapper">
          <Button variant="secondary" handleClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={localUserData?.id !== user?.id}
            handleClick={(event) => saveUser(event, user)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UserInfo;
