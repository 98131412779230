import { SingleArrow } from "../../../icons";
import "./styles.scss";

const TableList = ({
  title,
  rows,
  columns,
  listKey = "",
  noDataString = "No data",
  onClick = () => {},
}) => {
  return (
    <div className="table-list-wrapper">
      <h3 className="table-list-title">{title}</h3>
      {rows?.length > 0 ? (
        <ul className="table-list">
          <li className="table-list-labels">
            {columns?.map((column, index) => (
              <span key={`table-list-label-${index}`}>{column?.label}</span>
            ))}
          </li>
          {rows?.map((row) => (
            <li
              className="table-list-row"
              key={`table-list-row-${listKey}-${row?.id}`}
              onClick={() => onClick(row)}
            >
              {columns?.map((column, index) => (
                <div
                  className="table-list-item"
                  key={`table-list-column-${listKey}-${index}`}
                >
                  {row[column?.slug]}
                </div>
              ))}
            </li>
          ))}
        </ul>
      ) : (
        <small>{noDataString}</small>
      )}
    </div>
  );
};

export default TableList;
