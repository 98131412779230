import { createSlice } from "@reduxjs/toolkit";
import {
  fetchResponsibilities,
  createResponsibility,
  updateResponsibility,
  deleteResponsibility,
} from "./responsibilitiesThunks";

export const responsibilitiesSlice = createSlice({
  name: "responsibilities",
  initialState: {
    responsibilities: [],
    loading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetResponsibilityMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResponsibilities.fulfilled, (state, action) => {
      state.responsibilities = action.payload;
    });
    builder.addCase(createResponsibility.fulfilled, (state, action) => {
      state.responsibilities.push(action.payload);
    });
    builder.addCase(updateResponsibility.fulfilled, (state, action) => {
      const index = state.responsibilities.findIndex(
        (responsibility) => responsibility.id === action.payload.id
      );
      state.responsibilities[index] = action.payload;
    });
    builder.addCase(deleteResponsibility.fulfilled, (state, action) => {
      state.responsibilities = state.responsibilities.filter(
        (responsibility) => responsibility.id !== action.meta.arg
      );
    });
  },
});

export const { resetResponsibilityMessage } = responsibilitiesSlice.actions;

export const responsibilitiesReducer = responsibilitiesSlice.reducer;
