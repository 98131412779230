import { createSlice } from "@reduxjs/toolkit";
import {
  createMood,
  fetchQuestions,
  fetchUserCheckinsHistory,
  fetchUserMoodStatsLine,
  fetchUserMoodStatsDoughnut,
  fetchUsersTeamMoodStatsLine,
  fetchUsersTeamMoodStatsDoughnut,
  fetchTeamsMoodStatsLine,
  fetchTeamsMoodStatsDoughnut,
  fetchCompanyMoodStatsLine,
  fetchCompanyMoodStatsDoughnut,
} from "./checkinsThunk";

const checkinsSlice = createSlice({
  name: "checkins",
  initialState: {
    data: [],
    questions: [],
    mood: null,
    // history: [],
    userHistory: [],
    moodStatistics: {
      userStatsLine: [],
      userStatsDoughnut: [],

      usersTeamStatsLine: [],
      usersTeamStatsDoughnut: [],

      teamsStatsLine: [],
      teamsStatsDoughnut: [],

      companyStatsLine: [],
      companyStatsDoughnut: [],
    },
    message: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetCheckinsMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(createMood.fulfilled, (state, action) => {
      state.mood = action.payload;
      state.message = {
        type: "success",
        title: "Success",
        message: "Mood successfully saved",
      };
    });
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      state.questions = action.payload;
    });
    builder.addCase(fetchUserCheckinsHistory.fulfilled, (state, action) => {
      state.userHistory = action.payload;
    });
    builder.addCase(fetchUserMoodStatsLine.fulfilled, (state, action) => {
      state.moodStatistics.userStatsLine = action.payload;
    });
    builder.addCase(fetchUserMoodStatsDoughnut.fulfilled, (state, action) => {
      state.moodStatistics.userStatsDoughnut = action.payload;
    });
    // builder.addCase(fetchUsersTeamMoodStatsLine.fulfilled, (state, action) => {
    //   state.moodStatistics.usersTeamStatsLine = action.payload;
    // });
    // builder.addCase(
    //   fetchUsersTeamMoodStatsDoughnut.fulfilled,
    //   (state, action) => {
    //     state.moodStatistics.usersTeamStatsDoughnut = action.payload;
    //   }
    // );
    builder.addCase(fetchTeamsMoodStatsLine.fulfilled, (state, action) => {
      state.moodStatistics.teamsStatsLine = action.payload;
    });
    builder.addCase(fetchTeamsMoodStatsDoughnut.fulfilled, (state, action) => {
      state.moodStatistics.teamsStatsDoughnut = action.payload;
    });
    builder.addCase(fetchCompanyMoodStatsLine.fulfilled, (state, action) => {
      state.moodStatistics.companyStatsLine = action.payload;
    });
    builder.addCase(
      fetchCompanyMoodStatsDoughnut.fulfilled,
      (state, action) => {
        state.moodStatistics.companyStatsDoughnut = action.payload;
      }
    );
  },
});
export const { resetCheckinsMessage } = checkinsSlice.actions;
export const checkinsReducer = checkinsSlice.reducer;
