import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchPanelDiscussions = createAsyncThunk(
  "panelDiscussion/fetch",
  async ({ user_id }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/panel-discussions`, {
        params: {
          user_id,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPanelDiscussion = createAsyncThunk(
  "panelDiscussion/fetchById",
  async (id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/panel-discussion/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createPanelDiscussion = createAsyncThunk(
  "panelDiscussion/create",
  async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/panel-discussion`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePanelDiscussion = createAsyncThunk(
  "panelDiscussion/update",
  async ({ id, data }) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/panel-discussion/${id}`,
        {
          ...data,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deletePanelDiscussion = createAsyncThunk(
  "panelDiscussion/delete",
  async (id) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/panel-discussion/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
