import { useState, useEffect } from "react";

import { QuestionIcon, SearchIcon } from "../../../../icons";
import "./styles.scss";

import { fetchUsers, createRequireFeedback } from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import { useSelector } from "react-redux";
import { Form, Input, Modal, TypeAhead } from "../../../ui";

const RequestFeedbackForm = ({
  showRequestFeedbackModal,
  handleModalClose,
}) => {
  let localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const [feedbackData, setFeedbackData] = useState(null);
  const [involveManagerCheckbox, setInvolveManagerCheckbox] = useState(false);

  const usersState = useSelector((state) => state.users)?.data?.userData;
  const [doFetchUsers] = useThunk(fetchUsers);
  const [doCreateRequireFeedback] = useThunk(createRequireFeedback);

  useEffect(() => {
    if (!usersState) {
      doFetchUsers({});
    }
  }, [usersState]);

  const handleSearch = (searchTerm) => {
    doFetchUsers({ searchTerm });
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target ? event.target : event;
    if (type === "checkbox" && name === "involveManager") {
      setInvolveManagerCheckbox(checked);
      setFeedbackData({
        ...feedbackData,
        involveManager: checked ? 1 : 0,
      });
    } else {
      setFeedbackData({ ...feedbackData, [name]: value });
    }
  };

  const handleRequestFeedback = () => {
    const payload = {
      ...feedbackData,
      receiverId: localUserData?.id,
    };
    doCreateRequireFeedback(payload);
    handleModalClose();
  };

  return (
    <Modal
      show={showRequestFeedbackModal}
      handleClose={handleModalClose}
      title="Request Feedback"
      confirmButtonLabel="Request feedback"
      handleConfirm={handleRequestFeedback}
    >
      <Form>
        <div className="form-group bottom-margin">
          <label>Who would you like feedback from?</label>
          <TypeAhead
            name="senderId"
            options={usersState}
            placeholder="Select teammates"
            icon={<SearchIcon />}
            slug={(option) => `${option?.first_name} ${option?.last_name}`}
            handleSearch={handleSearch}
            handleSelect={(e) =>
              handleChange({ name: "senderId", value: e?.id })
            }
          />
        </div>

        <div className="form-group bottom-margin">
          <Input
            type="input"
            label="What would you like feedback about?"
            placeholder="I would like feedback on..."
            name="question"
            value={feedbackData?.question || ""}
            handleChange={handleChange}
          />
        </div>
        {/* <div className="checkbox-with-label">
            <Form.Check
              type="checkbox"
              name="involveManager"
              checked={involveManagerCheckbox}
              onChange={handleChange}
            />
            <label>Share feedback with your manager</label>
          </div> */}
        <div className="feedback-note">
          <div>
            <QuestionIcon />
          </div>
          <p>
            Your request will only be visible to you and peers you're requesting
            feedback from.
          </p>
        </div>
      </Form>
    </Modal>
  );
};

export default RequestFeedbackForm;
