import { createSlice } from "@reduxjs/toolkit";

import {
  fetchConferenceAttendances,
  fetchConferenceAttendance,
  createConferenceAttendance,
  updateConferenceAttendance,
  deleteConferenceAttendance,
} from "./conferenceAttendanceThunks";

const conferenceAttendanceSlice = createSlice({
  name: "conferenceAttendance",
  initialState: {
    data: [],
    singleConferenceAttendance: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetConferenceAttendanceMessage: (state) => {
      state.message = null;
    },
    resetSingleConferenceAttendance: (state) => {
      state.singleConferenceAttendance = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchConferenceAttendances.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchConferenceAttendance.fulfilled, (state, action) => {
      state.singleConferenceAttendance = action.payload;
    });
    builder.addCase(deleteConferenceAttendance.fulfilled, (state, action) => {
      const filteredConferenceAttendances = state?.data?.filter(
        (conferenceAttendance) => conferenceAttendance.id !== action.meta.arg
      );
      state.data = filteredConferenceAttendances;
      state.message = {
        type: "success",
        title: "Success",
        message: "Conference Attendance successfully deleted",
      };
    });
    builder.addCase(createConferenceAttendance.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Conference Attendance successfully created",
      };
    });
    builder.addCase(updateConferenceAttendance.fulfilled, (state, action) => {
      const updatedConferenceAttendances = state?.data?.map(
        (conferenceAttendance) => {
          if (conferenceAttendance.id === action.payload.id) {
            return action.payload;
          }
          return conferenceAttendance;
        }
      );
      state.data = updatedConferenceAttendances;
      state.message = {
        type: "success",
        title: "Success",
        message: "Conference Attendance successfully updated",
      };
    });
  },
});

export const {
  resetConferenceAttendanceMessage,
  resetSingleConferenceAttendance,
} = conferenceAttendanceSlice.actions;

export const conferenceAttendanceReducer = conferenceAttendanceSlice.reducer;
