import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAdvancedDegrees,
  fetchAdvancedDegree,
  createAdvancedDegree,
  updateAdvancedDegree,
  deleteAdvancedDegree,
} from "./advancedDegreeThunks";

const advancedDegreeSlice = createSlice({
  name: "advancedDegree",
  initialState: {
    data: [],
    singleAdvancedDegree: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetAdvancedDegreeMessage: (state) => {
      state.message = null;
    },
    resetSingleAdvancedDegree: (state) => {
      state.singleAdvancedDegree = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAdvancedDegrees.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchAdvancedDegree.fulfilled, (state, action) => {
      state.singleAdvancedDegree = action.payload;
    });
    builder.addCase(deleteAdvancedDegree.fulfilled, (state, action) => {
      const filteredAdvancedDegrees = state?.data?.filter(
        (advancedDegree) => advancedDegree.id !== action.meta.arg
      );
      state.data = filteredAdvancedDegrees;
      state.message = {
        type: "success",
        title: "Success",
        message: "Advanced Degree successfully deleted",
      };
    });
    builder.addCase(createAdvancedDegree.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.message = {
        type: "success",
        title: "Success",
        message: "Advanced Degree successfully created",
      };
    });
    builder.addCase(updateAdvancedDegree.fulfilled, (state, action) => {
      state.data = state.data.map((advancedDegree) =>
        advancedDegree.id === action.payload.id
          ? action.payload
          : advancedDegree
      );
      state.message = {
        type: "success",
        title: "Success",
        message: "Advanced Degree successfully updated",
      };
    });
  },
});

export const { resetAdvancedDegreeMessage, resetSingleAdvancedDegree } =
  advancedDegreeSlice.actions;

export const advancedDegreeReducer = advancedDegreeSlice.reducer;
