import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import "./styles.scss";

const LineChart = ({
  labels = [],
  datasets = [],
  chartOptions = [],
  plugins = [],
  children,
  outsideTitle = "",
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
    Legend
  );

  const data = {
    labels,
    datasets,
  };

  return (
    <div className="line-chart-wrapper">
      {!!outsideTitle && <h4 className="outside-title">{outsideTitle}</h4>}
      <Line data={data} options={chartOptions} plugins={plugins} />
      <div className="chart-labels">{children}</div>
    </div>
  );
};

export default LineChart;
