import { createSlice } from "@reduxjs/toolkit";

import {
  fetchProfessionalOrganizations,
  fetchProfessionalOrganization,
  createProfessionalOrganization,
  updateProfessionalOrganization,
  deleteProfessionalOrganization,
} from "./professionalOrganizationThunks";

const professionalOrganizationSlice = createSlice({
  name: "professionalOrganization",
  initialState: {
    data: [],
    singleProfessionalOrganization: null,
    isLoading: false,
    error: null,
    message: null,
  },
  reducers: {
    resetProfessionalOrganizationMessage: (state) => {
      state.message = null;
    },
    resetSingleProfessionalOrganization: (state) => {
      state.singleProfessionalOrganization = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchProfessionalOrganizations.fulfilled,
      (state, action) => {
        state.data = action.payload;
      }
    );
    builder.addCase(
      fetchProfessionalOrganization.fulfilled,
      (state, action) => {
        state.singleProfessionalOrganization = action.payload;
      }
    );
    builder.addCase(
      deleteProfessionalOrganization.fulfilled,
      (state, action) => {
        const filteredProfessionalOrganizations = state?.data?.filter(
          (professionalOrganization) =>
            professionalOrganization.id !== action.meta.arg
        );
        state.data = filteredProfessionalOrganizations;
        state.message = {
          type: "success",
          title: "Success",
          message: "Professional Organization successfully deleted",
        };
      }
    );
    builder.addCase(
      createProfessionalOrganization.fulfilled,
      (state, action) => {
        state.data = [...state.data, action.payload];
        state.message = {
          type: "success",
          title: "Success",
          message: "Professional Organization successfully created",
        };
      }
    );
    builder.addCase(
      updateProfessionalOrganization.fulfilled,
      (state, action) => {
        const updatedProfessionalOrganizations = state?.data?.map(
          (professionalOrganization) => {
            if (professionalOrganization.id === action.payload.id) {
              return action.payload;
            }
            return professionalOrganization;
          }
        );
        state.data = updatedProfessionalOrganizations;
        state.message = {
          type: "success",
          title: "Success",
          message: "Professional Organization successfully updated",
        };
      }
    );
    builder.addMatcher(
      (action) => {
        return action.type.includes("pending");
      },
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      (action) => {
        return (
          action.type.includes("fulfilled") || action.type.includes("rejected")
        );
      },
      (state) => {
        state.isLoading = false;
      }
    );
  },
});

export const {
  resetProfessionalOrganizationMessage,
  resetSingleProfessionalOrganization,
} = professionalOrganizationSlice.actions;

export const professionalOrganizationReducer =
  professionalOrganizationSlice.reducer;
