import React from "react";
import "./styles.scss";

const Checkbox = ({
  id = "",
  labelSide = "right",
  children,
  style = null,
  wrapperStyle = null,
  className = "",
  checked = false,
  type = "checkbox",
  name = "",
  variant = "primary",
  value,
  disabled = false,
  onChange = () => {},
}) => {
  return (
    <div className={`checkbox-wrapper ${variant}`} style={wrapperStyle}>
      {labelSide === "left" && <label htmlFor={name}>{children}</label>}
      <input
        style={style}
        className={className}
        type={type}
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {labelSide === "right" && <label>{children}</label>}
    </div>
  );
};

export default Checkbox;
