import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchBehaviours = createAsyncThunk(
  "/behaviours/fetch",
  async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/behaviours`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createBehaviour = createAsyncThunk(
  "/behaviours/create",
  async (behaviour) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/behaviour`, behaviour);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateBehaviour = createAsyncThunk(
  "/behaviours/update",
  async (behaviour) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/behaviour/${behaviour.id}`,
        behaviour
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteBehaviour = createAsyncThunk(
  "/behaviours/delete",
  async (behaviourId) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/behaviour/${behaviourId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
