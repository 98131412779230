import { useEffect, useState, useRef } from "react";
import MeetingsReview from "./MeetingsReview";
import ConferenceReview from "./ConferenceReview";
import {
  fetchAdvancedDegrees,
  fetchCertificationPrograms,
  fetchCommitteeInvolvements,
  fetchConferenceAttendances,
  fetchPanelDiscussions,
  fetchProfessionalOrganizations,
  fetchPublicSpeakings,
  fetchUsersHighFive,
  fetchWorkshopSeminars,
} from "../../../../store";
import { useThunk } from "../../../../hooks/useThunk";
import "./styles.scss";

const MeetingsAndConferences = ({ isExpanded, selectedUser }) => {
  const localUserData = JSON.parse(localStorage.getItem("authUserData"));
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  const [doFetchHighFives] = useThunk(fetchUsersHighFive);
  const [doFetchWorkshopSeminars] = useThunk(fetchWorkshopSeminars);
  const [doFetchConferenceAttendance] = useThunk(fetchConferenceAttendances);
  const [doFetchPublicSpeaking] = useThunk(fetchPublicSpeakings);
  const [doFetchPanelDiscussions] = useThunk(fetchPanelDiscussions);
  const [doFetchProfessionalOrganizations] = useThunk(
    fetchProfessionalOrganizations
  );
  const [doFetchCommitteeInvolvement] = useThunk(fetchCommitteeInvolvements);
  const [doFetchAdvancedDegrees] = useThunk(fetchAdvancedDegrees);
  const [doFetchCertificationPrograms] = useThunk(fetchCertificationPrograms);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      const user_id = !!selectedUser ? selectedUser?.id : localUserData?.id;
      setHeight(contentRef.current.scrollHeight);
      doFetchHighFives({ userId: user_id });
      doFetchWorkshopSeminars({ user_id });
      doFetchConferenceAttendance({ user_id });
      doFetchPublicSpeaking({ user_id });
      doFetchPanelDiscussions({ user_id });
      doFetchProfessionalOrganizations({ user_id });
      doFetchCommitteeInvolvement({ user_id });
      doFetchAdvancedDegrees({ user_id });
      doFetchCertificationPrograms({ user_id });
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div
      className="single-view-wrapper"
      style={{ height: height }}
      ref={contentRef}
    >
      <div className="single-view">
        <MeetingsReview />
        <hr />
        <ConferenceReview
          userId={localUserData?.id}
          selectedUser={selectedUser?.id || null}
        />
      </div>
    </div>
  );
};

export default MeetingsAndConferences;
