import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Filter, Status, Table } from "../../../ui";
import { SingleArrow, ClockIcon } from "../../../../icons";
import { convertTextToDate, shortenText } from "../../../../utils/textFormat";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { performanceGoalStatuses } from "../../../../data/statuses";

const GoalsReview = () => {
  const navigate = useNavigate();

  const [goals, setGoals] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const goalsState = useSelector((state) => state?.goals)?.usersGoals;

  useEffect(() => {
    setGoals(goalsState);
  }, [goalsState]);

  useEffect(() => {
    if (selectedFilter !== null) {
      setGoals(goalsState?.filter((goal) => goal?.status === selectedFilter));
      return;
    }
    setGoals(goalsState);
  }, [selectedFilter]);

  const goalFinder = (goal) => {
    let currentStatus = performanceGoalStatuses?.find(
      (status) => status?.value === goal?.status
    );
    return (
      <Status
        status={{ color: currentStatus?.color, label: currentStatus?.label }}
      />
    );
  };

  const handleShowAllClick = () => {
    navigate("/your-career/home");
  };

  const highlightWithRanges = performanceGoalStatuses?.map((status) => {
    let goalsByStatuse = goalsState.filter(
      (goal) => goal?.status === status?.value
    );
    return {
      [`highlighted-dates ${status.label.toLowerCase()}`]: goalsByStatuse?.map(
        (goal) => convertTextToDate(goal?.settings?.endDate)
      ),
    };
  });

  const handleFilterChange = (e) => {
    setSelectedFilter(parseInt(e.target.value));
  };

  const handleResetFilter = () => {
    setSelectedFilter(null);
  };

  const goalsTableData = {
    rows: goals?.map((goal) => {
      return {
        goalList: (
          <div className="goal-list">
            <p className="goal-title">{shortenText(goal?.name, 60)}</p>
            <p className="goal-description">
              {shortenText(goal?.description, 60)}
            </p>
          </div>
        ),
        deadline: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <ClockIcon />
            {goal?.settings?.endDate}
          </div>
        ),
        progress: goalFinder(goal),
        actions: (
          <div className="actions">
            {/* TODO: ubaciti onClick funkciju kada bude moglo da se pristupi goal-u direktno preko linka */}
            <span className="vertical-line"></span>
            <SingleArrow />
          </div>
        ),
      };
    }),
    columns: [
      {
        id: 1,
        label: "Goal list",
        slug: "goalList",
      },
      {
        id: 2,
        label: "Progress",
        slug: "progress",
      },
      {
        id: 3,
        label: "Deadline",
        slug: "deadline",
      },
      {
        id: 4,
        label: "",
        slug: "actions",
      },
    ],
  };

  return (
    <>
      <div className="goals">
        <div className="goals-table">
          <div className="title-line">
            <h3 className="title">Goals</h3>
            <div className="filter-wrapper">
              <Filter
                filters={performanceGoalStatuses}
                checked={selectedFilter}
                onRadioChange={handleFilterChange}
                onReset={handleResetFilter}
              />
              <Button
                variant="control show-all"
                handleClick={handleShowAllClick}
              >
                Show All <SingleArrow />
              </Button>
            </div>
          </div>
          <Table rows={goalsTableData.rows} columns={goalsTableData.columns} />
        </div>
        <div className="datepicker-wrapper">
          <label>Due date</label>
          <div className="date-picker-holder">
            <DatePicker
              disabledKeyboardNavigation
              inline
              readOnly
              showMonthDropdown
              showYearDropdown
              highlightDates={highlightWithRanges}
              dayClassName={() => "custom-day"}
              weekDayClassName={() => "custom-day"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalsReview;
