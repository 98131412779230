import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, Input, Switcher } from "../../../../ui";
import { CalendarLightIcon } from "../../../../../icons";
import {
  convertTextToDate,
  formatShortDate,
} from "../../../../../utils/textFormat";
import {
  createAdvancedDegree,
  updateAdvancedDegree,
} from "../../../../../store";
import { useThunk } from "../../../../../hooks/useThunk";
import { useDispatch } from "react-redux";
import { setToastMessage } from "../../../../../store/toastMessages/toastMessagesSlice";

const AdvancedDegrees = ({ userId, editData }) => {
  const [doCreateAdvancedDegree] = useThunk(createAdvancedDegree);
  const [doUpdateAdvancedDegree] = useThunk(updateAdvancedDegree);
  const defaultPlaceholder = "Type here...";
  const dispatch = useDispatch();

  const [degree, setDegree] = useState({});
  const [isOngoing, setIsOngoing] = useState(false);

  useEffect(() => {
    if (editData) {
      setDegree({
        title: editData?.title,
        institution: editData?.institution,
        field: editData?.field,
        startDate: String(editData?.start).split(" ")[0],
        endDate: editData?.end ? String(editData?.end).split(" ")[0] : null,
        courses: editData?.courses,
      });
      setIsOngoing(editData?.ongoing);
    }
  }, [editData]);

  useEffect(() => {
    setDegree((prevDegree) => ({ ...prevDegree, ongoing: isOngoing }));
    if (isOngoing) {
      setDegree((prevDegree) => ({ ...prevDegree, endDate: null }));
    }
  }, [isOngoing]);

  const handleChange = (name, value) => {
    setDegree({ ...degree, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!degree.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add Degree Program / Type.",
          type: "error",
        })
      );
      return;
    }

    if (!degree.startDate) {
      dispatch(
        setToastMessage({
          title: "Start date error",
          message: "Please add start date.",
          type: "error",
        })
      );
      return;
    }

    if (!isOngoing && !degree.endDate) {
      dispatch(
        setToastMessage({
          title: "End date error",
          message: "Please add end date.",
          type: "error",
        })
      );
      return;
    }

    if (!degree.institution) {
      dispatch(
        setToastMessage({
          title: "Institution error",
          message: "Please add Institution.",
          type: "error",
        })
      );
      return;
    }

    if (!degree.courses) {
      dispatch(
        setToastMessage({
          title: "Courses error",
          message: "Please add Courses.",
          type: "error",
        })
      );
      return;
    }

    doCreateAdvancedDegree({
      title: degree?.title,
      institution: degree?.institution,
      field: degree?.field,
      user_id: userId,
      ongoing: isOngoing,
      start: degree?.startDate + " 00:00:00",
      end: degree?.endDate ? degree?.endDate + " 00:00:00" : null,
      courses: degree?.courses,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!degree.title) {
      dispatch(
        setToastMessage({
          title: "Title error",
          message: "Please add Degree Program / Type.",
          type: "error",
        })
      );
      return;
    }

    if (!isOngoing && !degree.endDate) {
      dispatch(
        setToastMessage({
          title: "End date error",
          message: "Please add end date.",
          type: "error",
        })
      );
      return;
    }

    doUpdateAdvancedDegree({
      id: editData?.id,
      data: {
        title: degree?.title,
        institution: degree?.institution,
        field: degree?.field,
        user_id: userId,
        ongoing: isOngoing,
        start: degree?.startDate + " 00:00:00",
        end: degree?.endDate ? degree?.endDate + " 00:00:00" : null,
        courses: degree?.courses,
      },
    });
  };

  return (
    <div className="advanced-degrees">
      <Form>
        <Input
          name="title"
          label="Degree Program / Type"
          placeholder={defaultPlaceholder}
          value={degree?.title}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          name="institution"
          label="Institution"
          placeholder={defaultPlaceholder}
          value={degree?.institution}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <Input
          name="field"
          label="Field"
          placeholder={defaultPlaceholder}
          value={degree?.field}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="form-group">
          <div className="form-row justify-between">
            <label>Duration</label>
            <div className="form-row siwtcher-group">
              <label className="label-normal">Ongoing</label>
              <Switcher
                checked={isOngoing}
                handleChange={() => setIsOngoing(!isOngoing)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label className="label-normal">Starting date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(degree?.startDate)}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) =>
                    handleChange("startDate", formatShortDate(e))
                  }
                />
                <CalendarLightIcon />
              </div>
            </div>
            <div className="form-group">
              <label className="label-normal">Ending date</label>
              <div className="date-picker-holder">
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={convertTextToDate(degree?.endDate)}
                  disabled={isOngoing}
                  showMonthDropdown
                  showYearDropdown
                  onChange={(e) => handleChange("endDate", formatShortDate(e))}
                />
                <CalendarLightIcon />
              </div>
            </div>
          </div>
        </div>
        <Input
          type="textarea"
          name="courses"
          label="Courses"
          placeholder={defaultPlaceholder}
          value={degree?.courses}
          handleChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <div className="save-button-container">
          {editData ? (
            <Button handleClick={handleUpdate}>Update</Button>
          ) : (
            <Button handleClick={handleSubmit}>Save</Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AdvancedDegrees;
