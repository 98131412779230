import React from "react";
import "./styles.scss";

const Form = ({ children, className = "", onSubmit = () => {} }) => {
  return (
    <form className={`form-wrapper ${className}`} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default Form;
